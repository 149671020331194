import { getApiRequest} from '../getApiRequest';

export function getSportsResultUrl(data) { 
	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: 'Sports/getSportsResultUrl',
		method: 'post',
		data: data,
	})
		.then(function(response) {
			if (response.data.status == '200' && response.data.message == 'ok') {
				return response.data.data;
			} else {
				return false;
			}
		})
		.catch(function(error) {
			console.log(error);
			return false;
		});
}
export function sportsSearchReq(data) {
	
	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: 'Sports/SportsSearchReq',
		method: 'post',
		data: {searchId:data},
	})
		.then(function(response) {
			console.log(response)
			
			if (response.data.status == '200' && response.data.message == 'OK') {
				return response.data.data.Data
			} else {
				return false;
			}
		})
		.catch(function(error) {
			console.log(error);
			return false;
		});
}



export function getSportsResult(data,currency,currentSearchID,signal) {

	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: 'Sports/getSportsResult',
		method: 'post',
		signal: signal,
		data: {searchId: data, currency:currency,},
	})
		.then(function(response) {
			if (response.data.status == '200' && response.data.message == 'ok') {
				return response.data.data;
			} else {
				return false;
			}
		})
		.catch(function(error) {
			console.log(error);
			return false;
		});
}
export function getsportsFullResults(data,currency) {
	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: 'Sports/getAllSportsResult',
		method: 'post',
		data: {searchId: data,currency: currency},
	})
		.then(function(response) {
			if (response.data.status == '200' && response.data.message == 'ok') {
				return response.data.data;
			} else {
				return false;
			}
		})
		.catch(function(error) {
			console.log(error);
			return false;
		});
}