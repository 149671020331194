import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useState, useEffect } from "react";
/* import { GetFlightDeal } from '../../services/Deals' */
import Moment from "moment";
import { GetSearchId } from "../../services/common";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetFlightDeal } from "../../services/Deals";
import {
  flightSearchRequest,
  setFlightSearchParams, 
  setFlightSearchUrl,
} from "../../services/flight/searchServices";
import { setFlightSearchData } from "../../redux/flight/flightSearch";
import { getDeals } from "../../redux/dealsSlice";
import { getSeoData } from "./../../services/common";

export default function FlightDeals() {
  const [tripType, setTripType] = useState(1);
  let [redirectUrl, setRedirectUrl] = useState(false);
  let [departDate, setDepartDate] = useState();
  let [arrivalDate, setArrivalDate] = useState();
  const [legParams, setLegParams] = useState([]);
  const [seoData, setSeoData] = useState();

  const deals = useSelector((state) => state.deals.data);
  const lang = useSelector((state) => state.culture.language);
  const culture = useSelector((state) => state.culture.currentCulture);
  const currency = useSelector(
    (state) => state.currencyDetails.currentCurrency
  );
  const currencyCountry = useSelector(
    (state) => state.currencyDetails.currencyCountry
  );

  const filteredFlightDeal = deals?.filter(function (data) {
    return data.DealGroup == 1;
  });


  // useEffect(() => {
  //   (async () => {
  //     const seoData = await getSeoData();
  //     setSeoData(seoData);
  //   })();
  // }, []);

  const flightSearchAction = async (deal) => {

    let searchType = deal.SearchType;
    let sourceCode = deal.AirJourney[0].Source.Code;
    let destinationCode = deal.AirJourney[0].Destination.Code;
    let departureLabel = deal.AirJourney[0].Source.Name.en;
    let arrivalLabel = deal.AirJourney[0].Destination.Name.en;

    let departureLabelArabic = deal.AirJourney[0].Source.Name.ar;
    let arrivalLabelArabic = deal.AirJourney[0].Destination.Name.ar;

    let sourceCity = departureLabel.split("-")[0];
    let destinationCity = arrivalLabel.split("-")[0];

    let sourceCityArabic = departureLabelArabic.split("-")[0];
    let destinationCityArabic = arrivalLabelArabic.split("-")[0];

    let departAllAirport;
    if (deal.AirJourney[0].Source.IsAllAirport) {
      departAllAirport = "Y";
    } else {
      departAllAirport = "N";
    }

    let arrAllAirport;
    if (deal.AirJourney[0].Destination.IsAllAirport) {
      arrAllAirport = "Y";
    } else {
      arrAllAirport = "N";
    }

    let departDate;
    let arrivalDate;
    let type = " ";

    if (searchType == "OneWay") {
      
      type = 0;
      let responseFromDate = deal.AirJourney[0].Date;
      departDate = Moment(responseFromDate, "DD/MM/YYYY").format("DDMMMYYYY");
      //setDepartDate(Moment(responseFromDate,'DD/MM/YYYY').format("DDMMMYYYY"))
    } else if (searchType == "RoundTrip") {
    
      type = 1;
      let responseFromDate = deal.AirJourney[0].Date;
      departDate = Moment(responseFromDate, "DD/MM/YYYY").format("DDMMMYYYY");
      let responseToDate = deal.AirJourney[1].Date;
      arrivalDate = Moment(responseToDate, "DD/MM/YYYY").format("DDMMMYYYY");
      //setArrivalDate(Moment(responseToDate,'DD/MM/YYYY').format("DDMMMYYYY"))
    }


    let IsPreferredAirline = "all_flight";
    let preffAirlineLabel = "all_flight";
    let preffAirlineLabelArabic = "all_flight";
   
      if(deal.PrefferdAirline.Code != null && deal.PrefferdAirline.Name != null  ){
      IsPreferredAirline = deal.PrefferdAirline.Code;
      preffAirlineLabel = deal.PrefferdAirline.Name.en;
      preffAirlineLabelArabic = deal.PrefferdAirline.Name.ar;
      }
   

    const searchParms = {
      deprtCode: sourceCode,
      arrivCode: destinationCode,
      departDate: departDate,
      returnDate: arrivalDate,
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
      preffAirlineLabel: preffAirlineLabel,
      preffAirlineLabelArabic: preffAirlineLabelArabic, 
      isNonStop: false,
      tripType: type,
      travelClass: "Economy",
      airline: IsPreferredAirline,
      deptAllAirport: departAllAirport,
      returnAllAirport: arrAllAirport,
      departLabel: departureLabel,
      arrivalLabel: arrivalLabel,
      departCity: sourceCity,
      arrivalCity: destinationCity,
      arrivalCityArabic: destinationCityArabic,
      departCityArabic: sourceCityArabic,
      arrivalLabelArabic: arrivalLabelArabic,
      departLabelArabic: departureLabelArabic,
    };

    let flightSearchData = await setFlightSearchParams(searchParms);

    
    let reqObj = {
      data: {
        from: sourceCode,
        to: destinationCode,
        fromDate: departDate,
        toDate: arrivalDate,
        adults: 1,
        childs: 0,
        infants: 0,
        travelClass: "Economy",
        nonStop: false,
        preferedAirline: IsPreferredAirline,
        searchType: type,
        itenary: legParams,
        deptAllAirport: departAllAirport,
        returnAllAirport: arrAllAirport,
      },
      searchId: flightSearchData.data.searchId,
    };

    let searchResponse = await flightSearchRequest(reqObj,currency,currencyCountry);
    if (searchResponse) {
      //dispatch(setFlightSearchData(flightSearchData));

      let url = setFlightSearchUrl(flightSearchData.data, type, culture);

      //setRedirectUrl(url);
      window.location.href = url;
    }
  };

  return (
   
    <section className="homedealslist">
      <div className="container">
        {/* filteredFlightDeal?.length > 0 */ true && (
          <Tabs
            defaultActiveKey="flight"
            id="uncontrolled-tab-example"
            className=""
          >
            <Tab eventKey="flight" title={lang.home_top_flight_deals}>
              <div className="homedealslistlink">
                <ul>
                  {filteredFlightDeal?.map((deals, index) => {
                    let sourceResponse = culture == 'en' ? deals.AirJourney[0].Source.Name.en : deals.AirJourney[0].Source.Name.ar;
                    let sourceCity = sourceResponse.split("-")[0];
                    let destinationResponse =
                    culture == 'en' ? deals.AirJourney[0].Destination.Name.en : deals.AirJourney[0].Destination.Name.ar;
                    let destinationCity = destinationResponse.split("-")[0];
                    return (
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={() => flightSearchAction(deals)}
                        >
                          {sourceCity}{" "}{lang.to}{" "}{destinationCity}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Tab>
            {seoData && (
              <Tab eventKey="SEO" title="SEO">
                <div className="homedealslistlink">
                  <ul>
                    {seoData.AirlineDetailsList_En?.map((data) => (
                      <li>
                        <a href="#">{data.airline_name}</a>
                      </li>
                    ))}
                  </ul>
                  <a href="en/AllAirlines">View All</a>
                </div>
              </Tab>
            )}
          </Tabs>
        )}
      </div>
    </section>
  );
}
