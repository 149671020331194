import React, { useEffect, useState } from 'react';
import { cookieManagementFindSpecific, flightSearchRequest, setFlightSearchParams, setFlightSearchUrl } from './../../../services/flight/searchServices';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { setFlightSearchData } from '../../../redux/flight/flightSearch';
const RecentSearch = () => {
    const [recentSearchData, setRecentSearchData] = useState([]);
    const culture = useSelector((state) => state.culture.currentCulture);
    const [redirectUrl, setRedirectUrl] = useState("");
    const Navigate = useNavigate();
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.culture.language);
    const [loader, setLoader] = useState(null)
    const currency = useSelector(
        (state) => state.currencyDetails.currentCurrency
    );
    const currencyCountry = useSelector(
        (state) => state.currencyDetails.currencyCountry
    );
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await cookieManagementFindSpecific({ category: "recentSearchHistory" });
                if (response?.data?.[0]) {
                    let sortedArray = sortByDateTime(response)
                    setRecentSearchData(sortedArray.data.reverse());

                } else {
                    console.error("Invalid data format received");
                }
            } catch (error) {
                console.error("Error fetching recent search data:", error);
            }
        };
        fetchData();
    }, []);

    function sortByDateTime(jsonData) {
        let data = jsonData.data;

        // Parse the 'CookieValue' into JSON objects
        data.forEach(item => {
            item.CookieValue = JSON.parse(item.CookieValue);
        });

        // Sort the data array based on the dateTime property
        data.sort(function (a, b) {
            return new Date(a.CookieValue.dateTime) - new Date(b.CookieValue.dateTime);
        });

        // Return sorted JSON data
        return jsonData;
    }


    const selectRecentSearch = async (searchItem, index) => {
        setLoader(index)
        const { searchParms } = searchItem

        let flightSearchData = await setFlightSearchParams({ ...searchParms, url: setFlightSearchUrl(searchParms, searchItem.tripType, culture) });
        searchItem.data.searchId = flightSearchData.data.searchId
        searchItem.reqObj.searchId = flightSearchData.data.searchId

        let searchResponse = await flightSearchRequest(searchItem.reqObj, currency, currencyCountry);
        if (searchResponse) {
            dispatch(setFlightSearchData(searchItem))
            let url = setFlightSearchUrl(searchItem.data, searchItem.tripType, culture);
            setRedirectUrl(url);
        }
        setLoader(null)
    }


    if (redirectUrl) {
        Navigate(redirectUrl);
    }

    if (recentSearchData.length === 0) {
        return null;
    }


    return (
        <div className="search_history_common" id="flight">
            <div className="recent_text">{lang.mob_recent_search}</div>
            <div className="search_list">
                <ul>

                    {recentSearchData?.map((searchItem, index) => {
                        if (index >= 5) {
                            return false
                        }
                        const cookieValue = searchItem.CookieValue
                        const { arrivalCity, arrivalCityArabic, deprtCode, arrivCode, departCity, departCityArabic, departDate, returnDate } = cookieValue.data
                        let legArray = []
                        if (cookieValue.tripType == 2) {
                            legArray = cookieValue.data.legParams.filter(flight => flight.departLabel !== "");
                        }
                        return (

                            <React.Fragment key={index}>

                                <li key={index} onClick={() => selectRecentSearch(cookieValue, index)}>
                                    {cookieValue.tripType != 2 &&
                                        <>
                                            <p className="rcnt_place">
                                                <span>{culture == "en" ? departCity : departCityArabic}</span> &lt;&gt; <span>{culture == "en" ? arrivalCity : arrivalCityArabic}</span>
                                            </p>
                                            <p>
                                                {index === loader ? (
                                                    <div className="loader-sm" id="BtnOnewayRoundtripLoader">
                                                        <div className="bounce1"></div>
                                                        <div className="bounce2"></div>
                                                        <div className="bounce3"></div>
                                                    </div>
                                                ) : (
                                                    <span>
                                                        {moment(departDate).format('DD/MM/YYYY')} - {moment(returnDate).format('DD/MM/YYYY')}
                                                    </span>
                                                )}


                                            </p>

                                        </>
                                    }
                                    {cookieValue.tripType == 2 && (
                                        <>
                                            <p className="rcnt_place">
                                                <span>{culture == "en" ? legArray[0].departCity : legArray[0].departCityArabic}</span> &lt;&gt; <span>{culture == "en" ? legArray[legArray.length - 1].arrivalCity : legArray[legArray.length - 1].arrivalCityArabic}</span>
                                            </p>
                                            <p>
                                                {index === loader ? (
                                                    <div className="loader-sm" id="BtnOnewayRoundtripLoader">
                                                        <div className="bounce1"></div>
                                                        <div className="bounce2"></div>
                                                        <div className="bounce3"></div>
                                                    </div>
                                                ) : (
                                                    <span>
                                                        {moment(cookieValue.data.legParams[0].departDate).format('DD/MM/YYYY')}
                                                    </span>
                                                )}


                                            </p>
                                        </>
                                    )}
                                </li>
                            </React.Fragment>
                        )
                    })}
                </ul>
            </div>
        </div >
    );
};

export default RecentSearch;