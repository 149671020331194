import React from 'react'
import { useSelector } from 'react-redux'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'


const MobileViewBottomSheet = ({open,setOpen,children,maxHeightProp=0.8}) => {
    
    let blocking = useSelector((state) => !state.alert.value);

    const onDismiss = ()=>{
        setOpen(false)
    }
    return (
        <BottomSheet
            open={open}
            onDismiss={onDismiss}
            blocking={blocking}
            defaultSnap={({ snapPoints, lastSnap }) =>
                lastSnap ?? Math.min(...snapPoints)
            }
            snapPoints={({minHeight, maxHeight }) => [
                // maxHeight - maxHeight / 2,
                maxHeight * maxHeightProp,
            ]}
            scrollLocking={false}
            
        >{children}</BottomSheet>
    )
}

export default MobileViewBottomSheet