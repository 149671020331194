import React from "react";
import { useSelector } from "react-redux";

function InstantMarkupDiscount({
  instantMarkup,
  setInstantMarkup,
  instantDiscount,
  setInstantDiscount,
  userData,
}) {
  const lang = useSelector((state) => state.culture.language);
  return (
    <div className="fieldbox_row mb-0">
      {Object.values(userData?.Permissions).includes("Show Instant Markup") && (
        <div className="auto_complete instant_discount">
          <input
            placeholder={lang.instant_markup}
            value={instantMarkup}
            onChange={(evt) => {
             
              setInstantMarkup(evt.target.value.replace(
                /^(?!\d*\.?\d*$).*/,
                ""
              ));
            }}
          />
        </div>
      )}

      {Object.values(userData?.Permissions).includes(
        "Show Instant Discount"
      ) && (
        <div className="auto_complete instant_discount">
          <input
            value={instantDiscount}
            onChange={(evt) => {
              setInstantDiscount(evt.target.value.replace(
                /^(?!\d*\.?\d*$).*/,
                ""
              ));
            }}
            placeholder={lang.instant_discount}
          />
        </div>
      )}
    </div>
  );
}

export default InstantMarkupDiscount;
