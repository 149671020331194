import React, { useState, useEffect, useRef } from "react";
import produce from "immer";
import PassengerBox from "./passengerBox";
import TravelClassSelector from "./travelClassSelector";
import Autocomplete from "react-autocomplete";
import { getAirportList } from "../../../services/flight/common";
import moment from "moment/moment";
import DatePicker,  { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import ar from "date-fns/locale/ar";
import InstantMarkupDiscount from "./instantMarkupDiscount";
import { Prev } from "react-bootstrap/esm/PageItem";
import MobileViewBottomSheet from "../../common/MobileViewBottomSheet";
import { enGB } from "date-fns/locale";

import { addDays } from "date-fns";
import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { cookieManagemntReq } from "../../../services/flight/searchServices";

function MultiCity({
  legParams,
  setLegParams,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infantCount,
  setInfantCount,
  preffAirlineLabel,
  setPrefAirlineCode,
  setPreffAirlineLabel,
  airlineList,
  submitHandler,
  noOfLegs,
  setNoOfLegs,
  isNonStop,
  setIsNonStop,
  isLoading,
  setIsLoading,
  setSelectedApiList,
  selectedApiList,
  selectedApiHandler,
  userData,
  instantMarkup,
  setInstantMarkup,
  instantDiscount,
  setInstantDiscount,
  width,
  travelClass,
  setTravelClass,
  recentSearchData,
  setAirportDataForCookie,
  airportDataForCookie,
  setRecentSearchData,
}) {

   //setting for date to start from sunday in arabic
   const customArLocale = {
    ...ar,
    options: {
      // Modify options as needed:
      weekStartsOn: 0, // Set Sunday as the first day of the week
      firstWeekContainsDate: 7, // Consider the first week to contain January 7th
      // Add any other options you want to configure
    },
  };
  
  //common variables
  const maxNoOfLegs = process.env.REACT_APP_MULTI_CITY_LEGS;
  let airportArr = [];

  for (let i = 0; i < process.env.REACT_APP_MULTI_CITY_LEGS; i++) {
    airportArr.push({});
  }
  const lang = useSelector((state) => state.culture.language);
  const culture = useSelector((state) => state.culture.currentCulture);

  const weekDayFormatArr = [
    "الأحد","الاثنين","الثلاثاء","الأربعاء","الخميس","الجمعة","السبت"
  ]
  const weekDayFormatShortArr = [
    "ح","ن","ث","ر","خ","ج","س"
  ]

  //common states

  const [deptAirpotList, setDeptAirpotList] = useState(airportArr);
  const [arrivAirportList, setArrivAirportList] = useState(airportArr);
  const [isMobileViewBottomSheetOpen, setMobileViewBottomSheetOpen] = useState({
    isOpen: false,
    type: "",
    index: "",
  });

  // for development delete after use

  const [viewwidth, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = viewwidth <= 768;

  //end
  let arrivalRef = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  let deptRef = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  let deptCal = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  //legs handler

  const legNoHandler = (action) => {
    if (action == "plus" && noOfLegs.length < maxNoOfLegs) {
      let currentLeg = noOfLegs.length + 1;

      setNoOfLegs((prvState) => {
        return produce(prvState, (draft) => {
          draft.push("leg" + currentLeg);
        });
      });

      setLegParams((prvState) => {
        return produce(prvState, (draft) => {
          draft[currentLeg - 1].departLabel = draft[currentLeg - 2].arrivLabel;
          draft[currentLeg - 1].departLabelArabic =
            draft[currentLeg - 2].arrivLabelArabic;
          draft[currentLeg - 1].departCode = draft[currentLeg - 2].arrivCode;
          draft[currentLeg - 1].departCity = draft[currentLeg - 2].arrivalCity;
          draft[currentLeg - 1].departCityArabic =
            draft[currentLeg - 2].arrivalCityArabic;
          draft[currentLeg - 1].departIsAllAirport =
            draft[currentLeg - 2].arrivIsAllAirport;
        });
      });
    } else if (action == "minus" && noOfLegs.length - 1 > 1) {
      setNoOfLegs((prvState) => {
        return produce(prvState, (draft) => {
          draft.splice(noOfLegs.length - 1, 1);
        });
      });
    }
  };

  ///Autocomplete handlers
  const requestAirportListHandler = (value, type, legNo) => {
    let airportList = [];

    if (type == "depart") {
      setLegParams((prvState) => {
        return produce(prvState, (draft) => {
          if (culture == "en") {
            draft[legNo].departLabel = value;
          } else {
            draft[legNo].departLabelArabic = value;
          }
        });
      });
    } else {
      setLegParams((prvState) => {
        return produce(prvState, (draft) => {
          if (culture == "en") {
            // draft[legNo].departLabel = value;
            draft[legNo].arrivLabel = value;
          } else {
            draft[legNo].arrivLabelArabic = value;
          }
          // draft[legNo].arrivLabel = value;
        });
      });
    }

    const fetchAirportList = async () => {
      let airportListData = await getAirportList(value);
      // if (culture == "en") {
      if (airportListData["hits"]["hits"]) {
        airportListData.hits.hits.forEach((item, index) => {
          let {
            city,
            name,
            countryname,
            arabiccity,
            arabicname,
            arabiccountryname,
            code,
            isallairport,
          } = item._source;
          airportList.push({
            code: code,
            label: city + "-" + name + "-" + countryname + "(" + code + ")",
            arabiclabel:
              arabiccity +
              "-" +
              arabicname +
              "-" +
              arabiccountryname +
              "(" +
              code +
              ")",
            arabiccity: arabiccity,
            city: city,
            isallairport: isallairport,
          });
        });
      }

      if (type == "depart") {
        setDeptAirpotList((prvState) => {
          return produce(prvState, (draft) => {
            draft[legNo][value] = airportList;
          });
        });
      } else {
        setArrivAirportList((prvState) => {
          return produce(prvState, (draft) => {
            draft[legNo][value] = airportList;
          });
        });
      }
    };
    if(value != ""){
      fetchAirportList();
    }else{
      if (type == "depart") {
        setDeptAirpotList((prvState) => {
          return produce(prvState, (draft) => {
            draft[legNo] = {};
          });
        });
      } else {
        setArrivAirportList((prvState) => {
          return produce(prvState, (draft) => {
            draft[legNo] = {};
          });
        });
      }
    }
  };

  const selectAirportHandler = (e, item, type, legNo) => {
    if (type == "depart") {
      setLegParams((prvState) => {
        return produce(prvState, (draft) => {
          draft[legNo].departLabel = item.label;
          draft[legNo].departLabelArabic = item.arabiclabel;
          draft[legNo].departCode = item.code;
          draft[legNo].departCity = item.city;
          draft[legNo].departCityArabic = item.arabiccity;
          draft[legNo].departIsAllAirport = item.isallairport ? "Y" : "N";
        });
      });
      isMobile
        ? setMobileViewBottomSheetOpen({
          isOpen: false,
          type: "return",
          index: legNo,
        })
        : arrivalRef.current[legNo].current.focus();
    } else {
      setLegParams((prvState) => {
        return produce(prvState, (draft) => {
          draft[legNo].arrivLabel = item.label;
          draft[legNo].arrivLabelArabic = item.arabiclabel;
          draft[legNo].arrivCode = item.code;
          draft[legNo].arrivalCity = item.city;
          draft[legNo].arrivalCityArabic = item.arabiccity;
          draft[legNo].arrivIsAllAirport = item.isallairport ? "Y" : "N";
          if (noOfLegs.length > legNo + 1) {
            draft[legNo + 1].departLabel = item.label;
            draft[legNo + 1].departLabelArabic = item.arabiclabel;
            draft[legNo + 1].departCode = item.code;
            draft[legNo + 1].departCity = item.city;
            draft[legNo + 1].departCityArabic = item.arabiccity;
            draft[legNo + 1].departIsAllAirport = item.isallairport ? "Y" : "N";
          }
        });
      });

      isMobile
        ? setMobileViewBottomSheetOpen({ isOpen: false, type: "", index: "" })
        : deptCal.current[legNo].current.setOpen(true);
    }

    let check = airportDataForCookie.some((air) => {
      return air.code == item.code;
    });

    if (!check) {
      setAirportDataForCookie((prevState) => {
        return produce(prevState, (draft) => {
          draft.push(item);
        });
      });
    }
  };

  ///Datepicker handlers
  const FromDateInput = React.forwardRef(({ value, onClick }, ref) => {
    let dateSeletedCulture;

    // if (culture == "ar") {
    //   moment.locale("ar");
    //   dateSeletedCulture = moment(new Date(value)).format("DD/MM/YYYYY");
    // } else {
    dateSeletedCulture = moment(new Date(value)).format("DD/MM/YYYY");
    // }
    moment.locale("en");
    let dateSelected = moment(new Date(value)).format("MMM DD YYYY");

    return (
      <div
        className="fieldWrap icon_calendar  no-select-color"
        onClick={onClick}
        ref={ref}
      >
        <input
          readOnly
          spellCheck="false"
          autoComplete="off"
          type="text"
          id=" "
          placeholder="Departed on"
          onFocus={ (event) => {
            // Deselect text on focus
            event.preventDefault();
           
          }}
          onMouseDown={(event) => {
            // Prevent automatic selection on mouse down
            event.preventDefault();
          }}
          name="lname"
          value={dateSeletedCulture}
        ></input>
        <input type="hidden" name="lname" value={dateSelected}></input>
      </div>
    );
  });

  const dateSelectionHandler = (date, type, legNo) => {
    setLegParams((prvState) => {
      return produce(prvState, (draft) => {
        draft[legNo].departDate = isMobile ? date.selection.startDate : date;
        for (let i = legNo + 1; i < legParams.length; i++) {
          draft[i].departDate = isMobile ?  addDays(new Date(date.selection.startDate), 1) :  addDays(new Date(date), 1);
        }
      });
    });

    if (legNo < noOfLegs.length - 1) {
      !isMobile && deptRef.current[legNo + 1].current.focus();
    }
    isMobile &&
      setMobileViewBottomSheetOpen({ isOpen: false, type: "", index: "" });
  };

  ///passengerbox props
  const passengerProps = {
    adultCount,
    childCount,
    infantCount,
    setAdultCount,
    setChildCount,
    setInfantCount,
    width,
    travelClass,
    setTravelClass,
  };

  ///Pref airline Handlers

  const prefAirlineReqHandler = (value) => {
    setPreffAirlineLabel(value);
    if (value == "") {
      setPrefAirlineCode(value);
    }
    setPrefAirlineCode(value);
  };

  const prefAirlineHandler = (e, item) => {
    let label = item.Name + " (" + item.Code + ")";
    setPreffAirlineLabel(label);
    setPrefAirlineCode(item.Code);
  };

  const airlineListFilterHandler = (item) => {
    let listItemCode = item.Code.toLowerCase();
    let listItemName = item.Name.toLowerCase();
    let value = preffAirlineLabel.toLowerCase();
    if (listItemName.includes(value) || listItemCode.includes(value)) {
      return item;
    }
  };

  ///clear button handler
  const clearButtonHandler = (type, index) => {
    if (type === "from") {
      setLegParams((prvState) => {
        return produce(prvState, (draft) => {
          draft[index].departLabel = "";
          draft[index].departLabelArabic = "";
          draft[index].departCode = "";
          draft[index].departCity = "";
          draft[index].departCityArabic = "";
          draft[index].departIsAllAirport = "";
        });
      });
    }
    if (type === "to") {
      setLegParams((prvState) => {
        return produce(prvState, (draft) => {
          draft[index].arrivLabel = "";
          draft[index].arrivLabelArabic = "";
          draft[index].arrivCode = "";
          draft[index].arrivalCity = "";
          draft[index].arrivalCityArabic = "";
          draft[index].arrivIsAllAirport = "";
        });
      });
    }
    if (type == "pref") {
      setPreffAirlineLabel("");
      setPrefAirlineCode("");
    }
  };

  const cookieRemoveHandler = () => {
    setRecentSearchData([]);
    cookieManagemntReq(null, "remove");
  };

  return (
    <form>
      <div className="search_main multyc">
        <div className="search_lft">
          {noOfLegs.map((legNo, index) => {
            return (
              <div className="fieldbox_row">
                <div className="mob_frm_to">
                  <div className="field_clm_01_multy field-mr-15">
                    <div className="fieldWrap icon_from DisplayinRmv autocomplete  no-select-color">
                      <label className="mob_from_label">{lang.from}</label>
                      {isMobile ? (
                        <input
                          type="text"
                          id=" "
                          value={
                            culture == "en"
                              ? legParams[index].departLabel
                              : legParams[index].departLabelArabic
                          }
                          placeholder={lang.search_pannel_from_airport}
                          name="lname"
                          onClick={() => {
                            setMobileViewBottomSheetOpen({
                              isOpen: true,
                              type: "departure",
                              index,
                            });
                          }}
                          readOnly
                        ></input>
                      ) : (
                        <Autocomplete
                          ref={deptRef.current[index]}
                          autoHighlight={false}
                          wrapperProps={{ className: "auto_complete" }}
                          wrapperStyle={{ display: "inherit" }}
                          getItemValue={(item) => item.code}
                          inputProps={{
                            placeholder: lang.search_pannel_from_airport,
                          }}
                          onChange={(e) =>
                            requestAirportListHandler(
                              e.target.value,
                              "depart",
                              index
                            )
                          }
                          items={
                            deptAirpotList[index][
                              legParams[index][
                              culture == "en"
                                ? "departLabel"
                                : "departLabelArabic"
                              ]
                            ] !== undefined
                              ? deptAirpotList[index][
                              legParams[index][
                              culture == "en"
                                ? "departLabel"
                                : "departLabelArabic"
                              ]
                              ]
                              : []
                          }
                          renderItem={(item, isHighlighted) => (
                            <div className="react-autocomplete">
                              <div
                                className={
                                  isHighlighted
                                    ? " react-autocomplete-inner active "
                                    : "react-autocomplete-inner"
                                }
                              >
                                {culture == "en"
                                  ? item.label
                                  : item.arabiclabel}
                              </div>
                            </div>
                          )}
                          value={
                            culture == "en"
                              ? legParams[index].departLabel
                              : legParams[index].departLabelArabic
                          }
                          onSelect={(e, item) =>
                            selectAirportHandler(e, item, "depart", index)
                          }
                        />
                      )}
                      {isMobileViewBottomSheetOpen.isOpen &&
                        isMobileViewBottomSheetOpen.type == "departure" &&
                        isMobileViewBottomSheetOpen.index == index && (
                          <MobileViewBottomSheet
                            open={isMobileViewBottomSheetOpen.isOpen}
                            setOpen={setMobileViewBottomSheetOpen}
                            maxHeightProp={0.9}
                          >
                            <div className="mob_autocomplete_box mob_passenger_block">
                              <button
                                type="button"
                                className="close"
                                onClick={() =>
                                  setMobileViewBottomSheetOpen({
                                    isOpen: false,
                                    type: "",
                                  })
                                }
                              />
                              <h2>{lang.mob_dept_airport_select}</h2>
                              <Autocomplete
                              autoHighlight={false}
                                ref={deptRef.current[index]}
                                wrapperProps={{ className: "auto_complete" }}
                                wrapperStyle={{ display: "inherit" }}
                                getItemValue={(item) => item.code}
                                menuStyle={{ top: 0 }}
                                inputProps={{
                                  placeholder: lang.search_pannel_from_airport,
                                }}
                                open={deptAirpotList[index][
                                  legParams[index][
                                  culture == "en"
                                    ? "departLabel"
                                    : "departLabelArabic"
                                  ]
                                ] !== undefined && deptAirpotList[index][
                                  legParams[index][
                                  culture == "en"
                                    ? "departLabel"
                                    : "departLabelArabic"
                                  ]
                                ].length > 0 }
                                onChange={(e) =>
                                  requestAirportListHandler(
                                    e.target.value,
                                    "depart",
                                    index
                                  )
                                }
                                items={
                                  deptAirpotList[index][
                                    legParams[index][
                                    culture == "en"
                                      ? "departLabel"
                                      : "departLabelArabic"
                                    ]
                                  ] !== undefined
                                    ? deptAirpotList[index][
                                    legParams[index][
                                    culture == "en"
                                      ? "departLabel"
                                      : "departLabelArabic"
                                    ]
                                    ]
                                    : []
                                }
                                renderItem={(item, isHighlighted) => (
                                  <div className="react-autocomplete">
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {culture == "en"
                                        ? item.label
                                        : item.arabiclabel}
                                    </div>
                                  </div>
                                )}
                                value={
                                  culture == "en"
                                    ? legParams[index]?.departLabel
                                    : legParams[index]?.departLabelArabic
                                }
                                onSelect={(e, item) =>
                                  selectAirportHandler(e, item, "depart", index)
                                }
                              />
                              {(legParams[index].departLabel ||
                                legParams[index].departLabelArabic) && (
                                  <a
                                    className="mob_auto_close"
                                    id="close_from_city"
                                    onClick={() => {
                                      clearButtonHandler("from", index);
                                    }}
                                  ></a>
                                )}
                              {recentSearchData.length > 0 &&
                                legParams[index]?.departLabel == "" && (
                                  <div>
                                    <div className="mob_auto_hd">
                                      <h3>{lang.mob_recent_search}</h3>
                                      <span onClick={cookieRemoveHandler}>
                                        {lang.clear}
                                      </span>
                                    </div>

                                    <div className="mob_auto_list">
                                      <ul>
                                        {recentSearchData?.map((item) => {
                                          return (
                                            <li
                                              onClick={() => {
                                                selectAirportHandler(
                                                  item.label,
                                                  item,
                                                  "depart",
                                                  index
                                                );
                                              }}
                                            >
                                              {" "}
                                              {culture == "en" ?item.label : item.arabiclabel}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </MobileViewBottomSheet>
                        )}
                      {(legParams[index].departLabel ||
                        legParams[index].departLabelArabic) && (
                          <a
                            className="clear_btn"
                            id="close_from_city"
                            onClick={() => {
                              clearButtonHandler("from", index);
                            }}
                          ></a>
                        )}
                    </div>
                  </div>
                  {/* <div className="swich_mob"></div> */}
                  <div className="field_clm_01_multy field-mr-15 mob_to">
                    <div className="fieldWrap icon_to DisplayinRmv autocomplete  no-select-color">
                      <label className="mob_from_label">{lang.to}</label>
                      {isMobile ? (
                        <input
                          type="text"
                          id=" "
                          value={
                            culture == "en"
                              ? legParams[index].arrivLabel
                              : legParams[index].arrivLabelArabic
                          }
                          placeholder={lang.search_pannel_to_airport}
                          name="lname"
                          onClick={() => {
                            setMobileViewBottomSheetOpen({
                              isOpen: true,
                              type: "return",
                              index,
                            });
                          }}
                          readOnly
                        ></input>
                      ) : (
                        <Autocomplete
                        autoHighlight={false}
                          wrapperStyle={{ display: "inherit" }}
                          wrapperProps={{ className: "auto_complete" }}
                          ref={arrivalRef.current[index]}
                          getItemValue={(item) => item.code}
                          inputProps={{
                            placeholder: lang.search_pannel_to_airport,
                          }}
                          onChange={(e) =>
                            requestAirportListHandler(
                              e.target.value,
                              "arrival",
                              index
                            )
                          }
                          items={
                            arrivAirportList[index][
                              legParams[index][
                              culture === "en"
                                ? "arrivLabel"
                                : "arrivLabelArabic"
                              ]
                            ] !== undefined
                              ? arrivAirportList[index][
                              legParams[index][
                              culture === "en"
                                ? "arrivLabel"
                                : "arrivLabelArabic"
                              ]
                              ]
                              : []
                          }
                          renderItem={(item, isHighlighted) => (
                            <div className="react-autocomplete">
                              <div
                                className={
                                  isHighlighted
                                    ? " react-autocomplete-inner active "
                                    : "react-autocomplete-inner"
                                }
                              >
                                {culture == "en"
                                  ? item.label
                                  : item.arabiclabel}
                              </div>
                            </div>
                          )}
                          value={
                            culture == "en"
                              ? legParams[index]?.arrivLabel
                              : legParams[index]?.arrivLabelArabic
                          }
                          onSelect={(e, item) =>
                            selectAirportHandler(e, item, "arrival", index)
                          }
                        />
                      )}
                      {isMobileViewBottomSheetOpen.isOpen &&
                        isMobileViewBottomSheetOpen.type == "return" &&
                        isMobileViewBottomSheetOpen.index == index && (
                          <MobileViewBottomSheet
                            open={isMobileViewBottomSheetOpen.isOpen}
                            setOpen={setMobileViewBottomSheetOpen}
                            maxHeightProp={0.9}
                          >
                            <div className="mob_autocomplete_box mob_passenger_block">
                              <button
                                type="button"
                                className="close"
                                onClick={() =>
                                  setMobileViewBottomSheetOpen({
                                    isOpen: false,
                                    type: "",
                                  })
                                }
                              />
                              <h2>{lang.mob_arrv_airport_select}</h2>
                              <Autocomplete
                              autoHighlight={false}
                                wrapperStyle={{ display: "inherit" }}
                                wrapperProps={{ className: "auto_complete" }}
                                ref={arrivalRef.current[index]}
                                getItemValue={(item) => item.code}
                                menuStyle={{ top: 0 }}
                                inputProps={{
                                  placeholder: lang.search_pannel_to_airport,
                                }}
                                open={arrivAirportList[index][
                                  legParams[index][
                                  culture === "en"
                                    ? "arrivLabel"
                                    : "arrivLabelArabic"
                                  ]
                                ] !== undefined && arrivAirportList[index][
                                  legParams[index][
                                  culture === "en"
                                    ? "arrivLabel"
                                    : "arrivLabelArabic"
                                  ]
                                ].length > 0 }
                                onChange={(e) =>
                                  requestAirportListHandler(
                                    e.target.value,
                                    "arrival",
                                    index
                                  )
                                }
                                items={
                                  arrivAirportList[index][
                                    legParams[index][
                                    culture === "en"
                                      ? "arrivLabel"
                                      : "arrivLabelArabic"
                                    ]
                                  ] !== undefined
                                    ? arrivAirportList[index][
                                    legParams[index][
                                    culture === "en"
                                      ? "arrivLabel"
                                      : "arrivLabelArabic"
                                    ]
                                    ]
                                    : []
                                }
                                renderItem={(item, isHighlighted) => (
                                  <div className="react-autocomplete">
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {culture == "en"
                                        ? item.label
                                        : item.arabiclabel}
                                    </div>
                                  </div>
                                )}
                                value={
                                  culture == "en"
                                    ? legParams[index]?.arrivLabel
                                    : legParams[index]?.arrivLabelArabic
                                }
                                onSelect={(e, item) =>
                                  selectAirportHandler(
                                    e,
                                    item,
                                    "arrival",
                                    index
                                  )
                                }
                              />
                              {(legParams[index]?.arrivLabel ||
                                legParams[index]?.arrivLabelArabic) && (
                                  <a
                                    className="mob_auto_close"
                                    id="close_from_city"
                                    onClick={() => {
                                      clearButtonHandler("to", index);
                                    }}
                                  ></a>
                                )}
                              {recentSearchData.length > 0 &&
                                legParams[index]?.arrivLabel == "" && (
                                  <div>
                                    <div className="mob_auto_hd">
                                      <h3>{lang.mob_recent_search}</h3>
                                      <span onClick={cookieRemoveHandler}>
                                        {lang.clear}
                                      </span>
                                    </div>

                                    <div className="mob_auto_list">
                                      <ul>
                                        {recentSearchData?.map((item) => {
                                          return (
                                            <li
                                              onClick={() =>
                                                selectAirportHandler(
                                                  item.label,
                                                  item,
                                                  "arrival",
                                                  index
                                                )
                                              }
                                            >
                                              {" "}
                                            {culture == "en" ?item.label : item.arabiclabel}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </MobileViewBottomSheet>
                        )}
                      {(legParams[index]?.arrivLabel ||
                        legParams[index]?.arrivLabelArabic) && (
                          <a
                            className="clear_btn"
                            id="close_from_city"
                            onClick={() => {
                              clearButtonHandler("to", index);
                            }}
                          ></a>
                        )}
                    </div>
                  </div>
                </div>

                <div className="field_clm_02_multy field-mr-15 multyclndr">
                  <div className="fieldWrap icon_calendar">
                    <label className="mob_clndr_label">
                      {lang.depart_date}
                    </label>
                    {isMobile ? (
                      <div
                        className="fieldWrap icon_calendar  no-select-color"
                        onClick={() =>
                          setMobileViewBottomSheetOpen({
                            isOpen: true,
                            type: "departingDate",
                            index,
                          })
                        }
                      >
                        <input
                          readOnly
                          spellCheck="false"
                          autoComplete="off"
                          type="text"
                          id=" "
                          placeholder="Departed on"
                          name="lname"
                          value={moment(
                            new Date(legParams[index].departDate)
                          ).format("DD/MM/YYYY")}
                        ></input>
                        <input
                          type="hidden"
                          name="lname"
                          value={moment(
                            new Date(legParams[index].departDate)
                          ).format("DD/MM/YYYY")}
                        ></input>
                      </div>
                    ) : (
                      <DatePicker
                        minDate={
                          index == 0
                            ? new Date()
                            : legParams[index - 1].departDate
                        }
                        maxDate={new Date(new Date().getTime() + (310 * 24 * 60 * 60 * 1000))}
                        ref={deptCal.current[index]}
                        selected={legParams[index].departDate}
                        customInput={<FromDateInput /* ref={ref} */ />}
                        onChange={(date) =>
                          dateSelectionHandler(date, "depart", index)
                        }
                        locale={culture == "en" ? "" : customArLocale}
                        monthsShown={2}
                        formatWeekDay={(nameOfDay, index) => {
                          let weeDayIndex = weekDayFormatArr.indexOf(nameOfDay)
                         return culture == "en" ? nameOfDay.substr(0, 3) : weekDayFormatShortArr[weeDayIndex]}}
                        focusSelectedMonth={true}
                      />
                    )}
                    {isMobileViewBottomSheetOpen.isOpen &&
                      isMobileViewBottomSheetOpen.type == "departingDate" &&
                      isMobileViewBottomSheetOpen.index == index && (
                        <MobileViewBottomSheet
                          open={isMobileViewBottomSheetOpen.isOpen}
                          setOpen={setMobileViewBottomSheetOpen}
                          maxHeightProp={0.9}
                        >
                          
                            <div className="mob_autocomplete_box mob_passenger_block">
                              <button
                                type="button"
                                className="close"
                                onClick={() =>
                                  setMobileViewBottomSheetOpen({
                                    isOpen: false,
                                    type: "",
                                  })
                                }
                              />
                              <h2>{lang.select_depart_date}</h2>
                              <div
                            className="mob_calender"
                            style={{ height: 568, width: 320 }}
                          >
                              
                              <DateRange
                                ranges={[
                                  {
                                    startDate:
                                      legParams[index].departDate || new Date(),
                                    endDate:
                                      legParams[index].departDate || new Date(),
                                    key: "selection",
                                  },
                                ]}
                                onChange={(value) =>
                                  dateSelectionHandler(value, "depart", index)
                                }
                                direction="vertical"
                                scroll={{ enabled: true }}
                                minDate={
                                  index == 0
                                    ? new Date()
                                    : legParams[index - 1].departDate
                                }
                                maxDate={addDays(new Date(), 310)}
                                showDateDisplay={false}
                                months={2}
                                verticalHeight={800}
                                locale={culture == "en" ? enGB : customArLocale}
                              />
                            </div>
                          </div>
                        </MobileViewBottomSheet>
                      )}
                  </div>
                </div>

                <div
                  className={
                    "multy_plus" +
                    (index == noOfLegs.length - 1 ? "" : " d-none")
                  }
                >
                  {noOfLegs.length != 2 && (
                    <button
                      type="button"
                      className="button_dark  muti_minus_btn"
                      onClick={() => legNoHandler("minus")}
                    >
                      {" "}
                    </button>
                  )}
                  {noOfLegs.length != maxNoOfLegs && (
                    <button
                      type="button"
                      className="button_dark  muti_plus_btn"
                      onClick={() => legNoHandler("plus")}
                    >
                      {" "}
                    </button>
                  )}
                </div>
              </div>
            );
          })}

          <div className="fieldbox_row">
            <div className="field_clm_01 field-mr-15 mr-md-0 cabin_prferd_mobhide">
              <div className="fieldWrap icon_economyclass icon_downarrow">
                <select
                  className="cabinSelect"
                  name="SeatingClass"
                  id="travelClass"
                  onChange={(e) => setTravelClass(e.target.value)}
                  value={travelClass}
                >
                  <option value="Economy">
                    {lang.search_pannel_economy_class}
                  </option>
                  <option value="PremiumEconomy">
                    {lang.search_pannel_premium_economy}
                  </option>
                  <option value="BusinessClass">
                    {lang.search_pannel_business_class}
                  </option>
                  <option value="FirstClass">
                    {lang.search_pannel_first_class}
                  </option>
                </select>
              </div>
            </div>
            <div className="field_clm_01 field-mr-15 mr-md-0 cabin_prferd_mobhide">
              <div className="fieldWrap icon_preferd DisplayinRmv autocomplete">
                <Autocomplete
                autoHighlight={false}
                  getItemValue={(item) => item.Name}
                  wrapperStyle={{ display: "inherit" }}
                  wrapperProps={{ className: "auto_complete" }}
                  items={airlineList}
                  inputProps={{ placeholder: lang.search_pannel_airline_name }}
                  renderItem={(item, isHighlighted) => (
                    <div className="react-autocomplete">
                      <div
                        className={
                          isHighlighted
                            ? " react-autocomplete-inner active "
                            : "react-autocomplete-inner"
                        }
                      >
                        {item.Name + " (" + item.Code + ")"}
                      </div>
                    </div>
                  )}
                  onChange={(e) => prefAirlineReqHandler(e.target.value)}
                  value={preffAirlineLabel}
                  shouldItemRender={(item) => airlineListFilterHandler(item)}
                  onSelect={(e, item) => {
                    prefAirlineHandler(e, item);
                  }}
                />
                {preffAirlineLabel && (
                  <a
                    className="clear_btn"
                    id="close_from_city"
                    onClick={() => {
                      clearButtonHandler("pref");
                    }}
                  ></a>
                )}
              </div>
            </div>

            <PassengerBox {...passengerProps} />

            <div className="field_clm_01 field-mr-15">
              <div className="hm_nonstop pl-lg-0 pl-2 pb-lg-0 pb-2">
                <div className="hmcol_4 hmStops">
                  <input
                    checked={isNonStop}
                    onClick={() => {
                      setIsNonStop(!isNonStop);
                    }}
                    type="checkbox"
                    id="IsDirect2"
                    name="IsDirect2"
                  />
                  <label htmlFor="IsDirect2">
                    {lang.search_pannel_non_stop}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {
            /* userData?.IsTravelAgent */ userData?.Permissions &&
            (Object.values(userData?.Permissions).includes(
              "Show Instant Discount"
            ) ||
              Object.values(userData?.Permissions).includes(
                "Show Instant Markup"
              )) && (
              <InstantMarkupDiscount
                instantMarkup={instantMarkup}
                setInstantMarkup={setInstantMarkup}
                instantDiscount={instantDiscount}
                setInstantDiscount={setInstantDiscount}
                userData={userData}
              />
            )
          }
        </div>

        <div className="search_rht">
          {isLoading ? (
            <div className="loader-sm" id="BtnOnewayRoundtripLoader">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          ) : (
            <button
              type="button"
              className="button_theme searchBtn"
              onClick={submitHandler}
            >
              <i className="icon_search"></i> <span>{lang.search}</span>
            </button>
          )}
        </div>
      </div>
      {userData?.ShowAllowedApi && (
        <div className="b2bapilist">
          {userData &&
            Object.keys(userData?.APIs[1]).length > 0 &&
            Object.entries(userData?.APIs[1]).map((api, index) => {
              return (
                <div className="hmcol_4 hmStops">
                  <input
                    type="checkbox"
                    id={"api1" + index}
                    name="IsDirect2"
                    checked={selectedApiList.includes(api[0])}
                    onChange={() => {
                      selectedApiHandler(api[0]);
                    }}
                    value="true"
                  ></input>
                  <label for={"api1" + index}>
                    {" "}
                    <span> {api[1]}</span>{" "}
                  </label>
                </div>
              );
            })}
        </div>
      )}
    </form>
  );
}
export default MultiCity;
