import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApiRequest } from '../../services/getApiRequest';

const getSportsSearchParams = createAsyncThunk('api/getSportsSearchParams', (navigate) => {
	let ApiRequest = getApiRequest();
	let searchId = null;
	if (window.location.href.split('/')[4] && window.location.href.split('/')[4].split('?')[0] == 'SportsResult') {
		searchId = window.location.search.split('?SearchId=')[1];
	} else {
		searchId = window.location.search.split('?SearchId=')[1].split('_')[0];
	}
	;
	return ApiRequest({
		url: 'Sports/getSportsSearchParams',
		method: 'post',
		data: {
			searchId: searchId,
		},
	})
		.then(function (response) {
			if (response.data.status == '200' && response.data.message == 'ok') {
				return response.data.data;
			} else {
				navigate(process.env.REACT_APP_BASE_URL)
				return false;
			}
		})
		.catch(function (error) {
			console.log(error);
			navigate(process.env.REACT_APP_BASE_URL)

			return false;
		});
});

const sportsSearch = createSlice({
	name: 'sportsSearch',
	initialState: {
		sportsSearch: [],
		error: '',
		loading: true,
	},
	reducers: {
		setSportsSearchData: (state, action) => {
			state.sportsSearch = action.payload;
			state.loading = false;
		},
	},
	extraReducers: {
		[getSportsSearchParams.fulfilled]: (state, action) => {
			state.loading = false;
			state.sportsSearch = action.payload;
		},
		[getSportsSearchParams.pending]: state => {
			state.loading = true;
		},
		[getSportsSearchParams.rejected]: state => {
			state.loading = false;
			state.error = 'sportsSearch Error';
		},
	},
});
export const { setSportsSearchData } = sportsSearch.actions;
export { getSportsSearchParams };
export default sportsSearch.reducer;
