import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSelectedAncillaries } from "../../services/common";

const getSelectedAncillaries = createAsyncThunk("Flight/fetchSelectedAncillaries", async (searchId) => {

    let selectedAncillaries = await fetchSelectedAncillaries(searchId)
    if (selectedAncillaries.data) {
        return selectedAncillaries.data.data
    } else {
        return [];
    }
})

const getSelectedAncillaryDetails = createSlice({
    name: 'selectedAncillaries',
    initialState: {
        data: {
            MLP: [],
            STP: [],
            BGP: []
        },
        loading: false
    },
    reducers: {
        setAncillaryDetails: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: {
        [getSelectedAncillaries.fulfilled]: (state, action) => {
            if (action.payload != "Not found") {
                state.data = action.payload;
                state.loading = false
            }
        },
        [getSelectedAncillaries.pending]: state => {
            state.loading = true;
        },
        [getSelectedAncillaries.rejected]: state => {
            state.loading = false;
            state.error = 'Req Error';
        },
    },

});

export const { setAncillaryDetails } = getSelectedAncillaryDetails.actions;
export { getSelectedAncillaries };
export default getSelectedAncillaryDetails.reducer;