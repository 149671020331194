import { getApiRequest } from "../getApiRequest";

export function setFlightElkLog(contextLevel, contextAction,contextInfo ) {
  let requestObj = {
    context: {
      contextInfo: contextInfo,
      contextAction: contextAction,
      contextLevel: contextLevel,
      contextHeader: "Flight",
    },
    searchId: window.location.search.split('?SearchId=')[1],
  };
  getElkLogging(requestObj);
}
export function setSportsElkLog(contextLevel, contextAction,contextInfo ) {
  let requestObj = {
    context: {
      contextInfo: contextInfo,
      contextAction: contextAction,
      contextLevel: contextLevel,
      contextHeader: "Sports",
    },
    searchId: window.location.search.split('?SearchId=')[1],
  };
  getElkLogging(requestObj);
}
function getElkLogging(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "elk/setlog",
    method: "post",
    data: data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
