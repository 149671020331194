import React from "react";
import { useSelector } from 'react-redux';


export default function FooterCall() {
  const lang = useSelector(state => state.culture.language)

  return (
    <section className=" ">
      <div className='footer_clm_1'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 col-md-12  ftr_looking'>
              <h3>{lang.home_looking_for_help}</h3>
              <p>{lang.home_our_team_is_available}</p>
            </div>
            <div className='col-lg-3 col-md-4 ftr_tel ftr_tel_icon'>
              <p>{lang.home_telephone}: </p>
              <h3>+965 22206222</h3>
            </div>
            <div className='col-lg-3 col-md-4 ftr_tel ftr_hotline_icon'>
              <p>{lang.home_hot_line}: </p>
              <h3>+965 22206222</h3>
            </div>
            <div className='col-lg-3 col-md-4 ftr_tel ftr_mail_icon'>
              <p>{lang.home_or_you_can_email_us_at}</p>
              <h3>info@tathkarah.com</h3>
            </div>
          </div>
        </div>
      </div>
    </section>


  )
}