import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCulture } from '../../services/common';
/* import langEN from "../../assets/json/language_en.json"
import langAR from "../../assets/json/language_ar.json" */




// const getCultureInfo = createAsyncThunk('/api/getCulture', async () => {
// 	let cultureResult = await getCulture();
// 	if (cultureResult) {
// 		return cultureResult;
// 	}else{
// 		return process.env.REACT_APP_CULTURE
// 	}
// });
const url_segment = process.env.REACT_APP_BASE_URL.replace("/", "");
let current_url = "";
if (url_segment != "")
  current_url = window.location.href.replace(url_segment, "");
else current_url = window.location.href;

let lang;
if (current_url.split("/")[3] === "en") {
	lang = "en";
} else if (current_url.split("/")[3] === "ar") {
	lang = "ar";
} else {
	lang = process.env.REACT_APP_CULTURE;
}

const cultureSlice = createSlice({
	name: 'culture',
	initialState: {
		currentCulture: lang,
		error: '',
		language: {},
		loading: false,
	},
	reducers: {
		setCultures: (state, action) => {

			state.currentCulture = action.payload.culture;
			state.language = action.payload.lang;
			state.loading = false;
		},
	},
	// extraReducers: {
	// 	[getCultureInfo.fulfilled]: (state, action) => {
	// 		state.currentCulture = action.payload;
	// 		state.loading = false;
	// 		state.language = action.payload == "en" ? langEN : langAR ;
	// 	},
	// 	[getCultureInfo.pending]: state => {
	// 		state.loading = true;
	// 	},
	// 	[getCultureInfo.rejected]: state => {
	// 		state.loading = false;
	// 		state.error = 'Resource Key Error';
	// 	},
	// },
});

// export { getCultureInfo };

export const { setCultures } = cultureSlice.actions;

export default cultureSlice.reducer;
