import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { forgotPassword } from "../../services/common";
import { useSelector } from "react-redux";
import Popup from "react-popup";

function ForgotModal(props) {
  const { forgotModalOpen, setForgotModalOpen, isForgotPasswordClicked,culture } =
    props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loader, setLoader] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const lang = useSelector((state) => state.culture.language);

  const forgotCloseHandler = () => {
    setForgotModalOpen(false);
    reset();
  };

  useEffect(() => {
    reset();
  }, [isForgotPasswordClicked]);

  const submitHandler = async (data) => {
    const obj = { ...data, isAdmin: false,culture };
    setLoader(true);
    const resp = await forgotPassword(obj);
    // eslint-disable-next-line eqeqeq
    if (resp.data.Data == "Success") {
      setForgotModalOpen(false);
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.password_mail_send,
        buttons: {
          right: [
            {
              text: lang.ok,
              action: function () {
                setLoader(false);
                Popup.close();
                setForgotModalOpen(false);
                reset();
              },
            },
          ],
        },
      });
    } else {
      setForgotModalOpen(false);
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.user_doesnt_exist,
        buttons: {
          right: [
            {
              text: lang.ok,
              action: function () {
                setLoader(false);
                setForgotModalOpen(true);
                Popup.close();
                reset();
              },
            },
          ],
        },
      });
    }
  };

  return (
    <ReactModal
      isOpen={forgotModalOpen}
      style={customStyles}
      onRequestClose={() => setForgotModalOpen(false)}
    >
      <div className="popup_wrap">
        <div className="modal-dialog popup_wrap">
          <div className="modal-content">
            {" "}
            <div className="modal-body">
              <button
                onClick={forgotCloseHandler}
                type="button"
                className="close loginClose"
                data-dismiss="modal"
                aria-label="Close"
              >
                {" "}
              </button>
              <div className="log_cont_wrap">
                <div className="d-flex justify-content-between">
                  <h4>{lang.forgot_password}</h4>
                </div>
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div className="form-group">
                    {/*<label htmlFor="exampleInputmail">
                      {lang.email_address}
                    </label>*/}
                    <input
                      type="mailbox"
                      className="form-control"
                      id="exampleInputmail"
                      placeholder={lang.email_address}
                      {...register("Email", {
                        required: {
                          value: true,
                          message: lang.email_required,
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: lang.invalid_email,
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={"Email"}
                      render={({ message }) => (
                        <span style={{ color: "red" }} className="error">
                          {message}
                        </span>
                      )}
                    />{" "}
                  </div>
                  {!loader ? (
                    <button type="submit" className="btn popup_btn">
                      {lang.submit}
                    </button>
                  ) : (
                    <div className="loader-sm" id="BtnOnewayRoundtripLoader">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default ForgotModal;
