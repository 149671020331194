import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { setSubcribe } from "../../services/common";
import Popup from "react-popup";

function Subscribe() {
  const lang = useSelector((state) => state.culture.language);
  const culture = useSelector((state) => state.culture.currentCulture);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loader, setLoader] = useState();

  const onSubmit = async (data) => {
    
    let obj = {
      Email: data.Email,
      Culture: culture,
    };

    setLoader(true);
    let resp = await setSubcribe(obj);



    if (resp) {
      if (resp.data.Data.message == "Success") {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.thank_you_for_subcribing,
          buttons: {
            right: [
              {
                text: lang.ok,
                action: function () {
                  reset();
                  Popup.close();
                  setLoader(false);
                },
              },
            ],
          },
          onClose: function () {
            reset();
            Popup.close();
            setLoader(false);
          },
        });
      } else {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.already_sub,
          buttons: {
            right: [
              {
                text: lang.ok,
                action: function () {
                  reset();
                  Popup.close();
                  setLoader(false);
                },
              },
            ],
          },
          onClose: function () {
            reset();
            Popup.close();
            setLoader(false);
          },
        });
      }
    }
  };

  return (
    <div className="hm_subscribe_box">
      <h3>{lang.sign_up_enjoy_benifits}</h3>
      <p>{lang.get_cashback_coupons}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="email_subscription d-flex">
          <input
            type="text"
            name="sub_email"
            placeholder=" "
            {...register("Email", {
              required: {
                value: true,
                message: lang.email_required,
              },
              pattern: {
                // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: lang.invalid_email,
              },
            })}
          />
          {!loader ? (
            <button type="submit">{lang.home_subscribe}</button>
          ) : (
            <div className="loader-sm" id="BtnOnewayRoundtripLoader">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          )}
        </div>
        <ErrorMessage
          errors={errors}
          name={"Email"}
          render={({ message }) => (
            <span style={{ color: "red" }} className="error">
              {message}
            </span>
          )}
        />
      </form>
    </div>
  );
}

export default Subscribe;
