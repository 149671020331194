import { getApiRequest } from "../getApiRequest";

///currency logo

import AED from "../../assets/images/CurrencyIcons/AED.jpg";
import AUD from "../../assets/images/CurrencyIcons/AUD.jpg";
import BHD from "../../assets/images/CurrencyIcons/BHD.jpg";
import EGP from "../../assets/images/CurrencyIcons/EGP.jpg";
import EUR from "../../assets/images/CurrencyIcons/EUR.jpg";
import GBP from "../../assets/images/CurrencyIcons/GBP.jpg";
import GEL from "../../assets/images/CurrencyIcons/GEL.jpg";
import INR from "../../assets/images/CurrencyIcons/INR.jpg";
import IQD from "../../assets/images/CurrencyIcons/IQD.jpg";
import JOD from "../../assets/images/CurrencyIcons/JOD.jpg";
import KWD from "../../assets/images/CurrencyIcons/KWD.jpg";
import OMR from "../../assets/images/CurrencyIcons/OMR.jpg";
import QAR from "../../assets/images/CurrencyIcons/QAR.jpg";
import SAR from "../../assets/images/CurrencyIcons/SAR.jpg";
import USD from "../../assets/images/CurrencyIcons/USD.jpg";

// Airline logo

import FL_CY from "../../assets/images/AirlineIcons/CY.png";
import FL_XT from "../../assets/images/AirlineIcons/XT.png";
import FL_AI from "../../assets/images/AirlineIcons/EY.png";
import FL_EY from "../../assets/images/AirlineIcons/EY.png";
import FL_AC from "../../assets/images/AirlineIcons/AC.png";
import FL_LH from "../../assets/images/AirlineIcons/LH.png";
import FL_AA from "../../assets/images/AirlineIcons/AA.png";
import FL_UA from "../../assets/images/AirlineIcons/UA.png";
import FL_MH from "../../assets/images/AirlineIcons/MH.png";
import FL_PG from "../../assets/images/AirlineIcons/PG.png";
import FL_AS from "../../assets/images/AirlineIcons/AS.png";
import FL_B6 from "../../assets/images/AirlineIcons/B6.png";
import FL_KL from "../../assets/images/AirlineIcons/DL.png";
import FL_DL from "../../assets/images/AirlineIcons/DL.png";
import FL_BA from "../../assets/images/AirlineIcons/BA.png";
import FL_AF from "../../assets/images/AirlineIcons/AF.png";
import FL_S7 from "../../assets/images/AirlineIcons/S7.png";
import FL_CP from "../../assets/images/AirlineIcons/CP.png";
import FL_AY from "../../assets/images/AirlineIcons/AY.png";
import FL_IB from "../../assets/images/AirlineIcons/IB.png";
import FL_GF from "../../assets/images/AirlineIcons/GF.png";
import FL_EK from "../../assets/images/AirlineIcons/EK.png";
import FL_G9 from "../../assets/images/AirlineIcons/G9.png";
import FL_KU from "../../assets/images/AirlineIcons/KU.png";
import FL_FZ from "../../assets/images/AirlineIcons/FZ.png";
import FL_RJ from "../../assets/images/AirlineIcons/RJ.png";
import FL_TK from "../../assets/images/AirlineIcons/TK.png";
import FL_QR from "../../assets/images/AirlineIcons/QR.png";
import FL_MS from "../../assets/images/AirlineIcons/MS.png";
import FL_J9 from "../../assets/images/AirlineIcons/J9.png";
import FL_SU from "../../assets/images/AirlineIcons/SU.png";
import FL_SV from "../../assets/images/AirlineIcons/sv.png";
import FL_UK from "../../assets/images/AirlineIcons/UK.png";
import FL_WY from "../../assets/images/AirlineIcons/WY.png";
import FL_H1 from "../../assets/images/AirlineIcons/H1.png";
import FL_UL from "../../assets/images/AirlineIcons/UL.png";
import FL_PC from "../../assets/images/AirlineIcons/PC.png";
import FL_ME from "../../assets/images/AirlineIcons/ME.png";
import FL_ET from "../../assets/images/AirlineIcons/ET.png";
import FL_BG from "../../assets/images/AirlineIcons/BG.png";

import tapKnet from "../../assets/images/PaymentGateways/k_net.svg";
import tapMada from "../../assets/images/PaymentGateways/tap_mada.svg";
import visaCredit from "../../assets/images/PaymentGateways/mastercredit_visa.svg";
import masterCredit from "../../assets/images/PaymentGateways/oth_nbk_mastercredit.svg";

export const getPaymentGateWayLogo = {
  2: tapKnet,
  31: tapMada,
  30: tapKnet,
  33: visaCredit,
  35: masterCredit,
};

export const getAirportList = (part, culture) => {
  let c; //English
  if (culture == "ar") {
    c = 1;
  } else {
    c = 0;
  }
  return fetch(
    process.env.REACT_APP_ELASTIC_URL +
      "Data/GetAirport?value=" +
      part +
      "&culture=" +
      c +
      "&size=10"
  )
    .then((res) => res.json())
    .then(
      (jsonResponse) => {
        if (jsonResponse.hits !== null && jsonResponse.hits.hits.length > 0) {
          return jsonResponse;
        } else {
          return false;
        }
      },
      (error) => {
        return false;
      }
    );
};

export function getAirlineList() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/Common/GetAirline",
    method: "get",
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log("get airlines error");
    });
}

export function getBookingClassKey(key) {
  switch (key) {
    case "Economy":
      return "Y";
      break;
    case "PremiumEconomy":
      return "W";
      break;
    case "BusinessClass":
      return "C";
      break;
    case "FirstClass":
      return "F";
      break;
    default:
      return "F";
      break;
  }
}

export function getBookingClass(key) {
  switch (key) {
    case "Y":
      return "Economy";
      break;
    case "W":
      return "PremiumEconomy";
      break;
    case "C":
      return "BusinessClass";
      break;
    case "F":
      return "FirstClass";
      break;
    default:
      return "F";
      break;
  }
}

export function getPaxype(key) {
  key = parseInt(key);
  switch (key) {
    case 0:
      return "Adult";
      break;
    case 1:
      return "Child";
      break;
    case 2:
      return "Infant";
      break;
    default:
      break;
  }
}
export function getPaxkey(key,lang) {
  
  key = parseInt(key);
  switch (key) {
    case "Adult":
      return lang.Adult;
      break;
    case "Child":
      return lang.Child;
      break;
    case "Infant":
      return lang.Infant;
      break;
    default:
      break;
  }
}

export const getAirlineImage = {
  CY: FL_CY,
  XT: FL_XT,
  EY: FL_AI,
  EY: FL_EY,
  AC: FL_AC,
  LH: FL_LH,
  AA: FL_AA,
  UA: FL_UA,
  MH: FL_MH,
  PG: FL_PG,
  AS: FL_AS,
  B6: FL_B6,
  DL: FL_KL,
  DL: FL_DL,
  BA: FL_BA,
  AF: FL_AF,
  S7: FL_S7,
  CP: FL_CP,
  AY: FL_AY,
  IB: FL_IB,
  GF: FL_GF,
  EK: FL_EK,
  G9: FL_G9,
  KU: FL_KU,
  FZ: FL_FZ,
  RJ: FL_RJ,
  TK: FL_TK,
  QR: FL_QR,
  MS: FL_MS,
  J9: FL_J9,
  SU: FL_SU,
  SV: FL_SV,
  UK: FL_UK,
  WY: FL_WY,
  H1: FL_H1,
  UL: FL_UL,
  PC: FL_PC,
  ME: FL_ME,
  ET: FL_ET,
  BG: FL_BG,
};

export function getCurrencyImages(key) {
  switch (key) {
    case "AED":
      return AED;
      break;
    case "AUD":
      return AUD;
      break;
    case "BHD":
      return BHD;
      break;
    case "EGP":
      return EGP;
      break;
    case "EUR":
      return EUR;
      break;
    case "GBP":
      return GBP;
      break;
    case "GEL":
      return GEL;
      break;
    case "INR":
      return INR;
      break;
    case "IQD":
      return IQD;
      break;
    case "JOD":
      return JOD;
      break;
    case "KWD":
      return KWD;
      break;
    case "OMR":
      return OMR;
      break;
    case "QAR":
      return QAR;
      break;
    case "SAR":
      return SAR;
      break;
    case "USD":
      return USD;
      break;
    default:
      return "";
      break;
  }
}

export function getTravelClassResourceKey(key) {
  switch (key) {
    case "Economy":
      return "flight_economy";
    case "PremiumEconomy":
      return "flight_premium_economy";
    case "BusinessClass" :
      return "flight_business_class";
    case "FirstClass":
      return "flight_first_class";
    case "Business Class":
      return "flight_business_class";
    default:
      return "";
  }
}
export function convertPassengerTypeString(pax) {
  switch (pax) {
    case 0:
      return "Adult";
    case 1:
      return "Child";
    case 2:
      return "Infant";
    default:
      break;
  }
}
export function replaceWithStringResources(originalString, resourceKeys) {
  if(!originalString){
    return originalString
  }
    
  // Define the mapping of substrings to be replaced with resource keys
  const replacementMap = {
      'Adult': resourceKeys.Adult,
      'Child': resourceKeys.Child,
      'Infant': resourceKeys.Infant,
      'Check in baggage': resourceKeys.checkin_baggage,
      'Cabin Baggage': resourceKeys.cabin_baggage,
      'Check-in Baggage' : resourceKeys.checkin_baggage
  };

  // Replace substrings with corresponding resource keys
  let replacedString = originalString;
  for (const key in replacementMap) {
      if (replacementMap.hasOwnProperty(key)) {
          replacedString = replacedString.replace(new RegExp(key, 'g'), replacementMap[key]);
      }
  }

  return replacedString;
}