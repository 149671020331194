import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    value: false,
  },
  reducers: {
    isOpen: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { isOpen } = alertSlice.actions;

export default alertSlice.reducer;
