import React, { useEffect, useState } from 'react'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { getSpecialOffers } from './../../services/common';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import noImage from "../../assets/images/no_image_offer.jpg"


const SpecialOffer = () => {
  const [specialOfferData, setSpecialOfferData] = useState([])
  const culture = useSelector(state => state.culture.currentCulture)
  const lang = useSelector(state => state.culture.language)
  const currentCurrency = useSelector(state => state.currencyDetails.currentCurrency)

  const options = {
    /* margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: false,
    navText: ["Prev", "Next"],
    smartSpeed: 1000, */
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: (specialOfferData.length > 4 ? 4 : specialOfferData.length),
      }
    },
  };

  useEffect(() => {

    let obj = {
      "offerId": 0,
      "type": null,
      "currency": currentCurrency,
      'Culture': culture
    }

    const asyncFunc = async () => {
      let response = await getSpecialOffers(obj)
      if (response) {
        setSpecialOfferData(response.SpecialOffer)
      }
    }
    asyncFunc()

  }, [])


  return (
    <>


      {specialOfferData?.length >= 1 &&
        <div className="container hmspOffers">
          <div className="HMLatestFDHead">
            <h4>{lang.homepage_special_offers}</h4>
          </div>
          <OwlCarousel className='owl-theme' loop margin={30} {...options}>


            {specialOfferData.map((value) => {
              return (
                <Link to={`${process.env.REACT_APP_BASE_URL}${culture}/SpecialOfferDetails?Id=${value.OfferID}`}>
                  <div className="packageList"> <img src={value.SpecialOfferImage[0].MainImageUrl} alt="offerImage" width={374} height={292} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = noImage;
                  }} />
                    <div className="packagerate">
                      <div className="package_place">
                        <h3>{value.OfferTitle}</h3>
                      </div>
                      <div className="package_Book">
                        <button type="button" className="PackgeBook_btn">{lang.book_now}</button>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </OwlCarousel>
        </div>
      }
    </>
  )
}

export default SpecialOffer