import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import SignInModal from "./signInModal";
import { getCurrencyImages } from "../../services/flight/common";
import { currencyActions } from "../../redux/commonSlice";
import { setCultures } from "../../redux/common/cultureSlice";
import { useDispatch, useSelector } from "react-redux";
import { outsideContext } from "../../Path";

import {
  assembleCountrys,
  getUserWalletDetails,
  redirectToAdmin,
  setCultureSession,
  setUserCurrency,
} from "./../../services/common";
import { getUserData } from "../../redux/loginSlice";
import { loginDetailsRemove } from "../../services/common";
import { getLoginDetails } from "../../redux/loginSlice";
import { disableBodyScroll } from "./common";
import wallet from "../../assets/images/wallet.svg";

function Header({
  useOutsideAlerter,
  currencyMenuOpen,
  setCurrencyMenuOpen,
  setProfileOpen,
  profileOpen,
}) {
  const Navigate = useNavigate();

  //modal control  state
  const [isSigninModalOpen, setIsSigninModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [forgotModalOpen, setForgotModalOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [showWallet, setShowWallet] = useState(false);
  const [walletLoader, setWalletLoader] = useState(false)
 const [outstandingBalance, setOutstandingBalance] = useState(0)
 const [countryMenuOpen, setCountryMenuOpen] = useState(false)

  const [searchParams] = useSearchParams();
  const culture = useSelector((state) => state.culture.currentCulture);
  const lang = useSelector((state) => state.culture.language);
  sessionStorage.setItem("culture", culture);
  const loginDetails = useSelector((state) => state.loginDetail.loginDetail);
  let currency = useSelector((state) => state.currencyDetails.currentCurrency);
  const currencyRef = useRef(null);
  const countryRef = useRef(null);
  const profileRef = useRef(null);
  const walletRef = useRef(null);

  useOutsideAlerter(currencyRef, profileRef, null, null, "currencyAndProfile");
  useOutsideAlerter(walletRef, null, "wallet", setShowWallet);
  useOutsideAlerter(countryRef, null, "country", setCountryMenuOpen);

  //currency
  const currencyArr = useSelector((state) => state.currencyDetails.data);
  const profile = useSelector((state) => state.loginDetail.loginDetail);
  
  const countryList = useSelector((state) => state.currencyDetails.displayCountryList);
  

  const userData = useSelector((state) => state.loginDetail.userData);

  const currentCurrency = useSelector(
    (state) => state.currencyDetails.currentCurrency
  );
  
  const currentCountry = useSelector(
    (state) => state.currencyDetails.currencyCountry
  );
  
  const currentCountryName = useSelector(
    (state) => state.currencyDetails.currencyCountryName
  );

  const currencySearchStatus = useSelector(
    (state) => state.currencyDetails.loading
  );
  const dispatch = useDispatch();
  //mob burger menu control state
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const { mobMenuOpen, setMobMenuOpen } = useContext(outsideContext);

  const modalHandler = () => {
    setSideMenuOpen(false);
    setMobMenuOpen(false);

    if (registerModalOpen == false && forgotModalOpen == false) {
      setIsSigninModalOpen(true);
    }
  };

  const toggleCulture = async () => {
    let changeCulture;
    let url = window.location.href;
    let processUrl = process.env.REACT_APP_BASE_URL_HOME;

    if (processUrl == url) {
      if (culture == "en") {
        changeCulture = "ar";
        url = url.replace(processUrl, processUrl + "ar");
      } else {
        changeCulture = "en";
        url = url.replace(processUrl, processUrl + "en");
      }
    } else {
      if (culture == "en") {
        changeCulture = "ar";
        url = url.replace(processUrl + "en", processUrl + "ar");
      } else {
        changeCulture = "en";
        url = url.replace(processUrl + "ar", processUrl + "en");
      }
    }

    const cultureResponse = await setCultureSession({ lang: changeCulture });
    
    // to set culture in express session
    dispatch(setCultures(cultureResponse.data));

    window.location.replace(url);
  };

  useEffect(() => {
    if (isSigninModalOpen || registerModalOpen || forgotModalOpen) {
      disableBodyScroll(true);
    } else {
      disableBodyScroll(false);
    }
  }, [isSigninModalOpen, registerModalOpen, forgotModalOpen]);

  const signOutHandler = async () => {
    let resp = await loginDetailsRemove();
    if (resp) {
      window.location.replace(process.env.REACT_APP_BASE_URL  + culture);
    }
    // dispatch(getLoginDetails());
    // dispatch(getUserData());

    // Navigate(process.env.REACT_APP_BASE_URL);
  };
  const sideMenuHandler = () => {
    setSideMenuOpen(false);
    setMobMenuOpen(false);
  };
  const changeCurrency = async (currencyCode,countryCode=currentCountry,countryName=currentCountryName) => {

    if(loginDetails?.Role == "Admin" && userData?.AgencyCurrency) {
      currencyCode = userData?.AgencyCurrency
    }
    
    let responce = await setUserCurrency(currencyCode, countryCode, countryName, "header");

    /* if(countryCode != null){
      const countryResponse = await assembleCountrys(true);

      let currencyCountry = countryResponse.Data.find((country)=>{
        return country.Currency == currencyCode
      })
    
    } */

    if (responce) {
      dispatch(currencyActions.setCurrency({currencyCode,countryCode,countryName}));
    }
  };
  const options = ["one", "two", "three"];
  const defaultOption = options[0];
  const url_segment = process.env.REACT_APP_BASE_URL.replace("/", "");

  let current_url = "";
  if (url_segment != "") {
    current_url = window.location.href.replace(url_segment, "");
  } else {
    current_url = window.location.href;
  }

  useEffect(() => {
    if (searchParams.get("Currency") == "true") {
      setCurrencyMenuOpen(true);
    }
  }, []);

  useEffect(() => {
    if (
      current_url == process.env.REACT_APP_BASE_URL_HOME ||
      current_url == window.location.href + "en" ||
      current_url == window.location.href + "ar"
    ) {
      setRedirectUrl(true);
    } else {
      setRedirectUrl(false);
    }
  }, [window.location.href]);


/*   useEffect(() => {
    dispatch(getUserData());
  }, [profile]); */

  // useEffect(() => {
  //   const asyncFunc = async () => {
  //     let response = await getUserWalletDetails();
  //     if (response.data.data.Data) {
  //       let displayText =
  //         response.data.data.Data[0].Currency +
  //         " " +
  //         parseFloat(response.data.data.Data[0].AvilableAgencyBalance).toFixed(3);
  //       setWalletBalance(displayText);
  //     } else {
  //       return false;
  //     }
  //   };
  //   asyncFunc();
  // }, [userData,showWallet]);


  const walletDisplayFunc = async () => {

    setWalletLoader(true)

    if (showWallet) {
      setShowWallet(false)
      return false
    }
    setShowWallet(true)
    let response = await getUserWalletDetails();
    if (response.data.data.Data) {
      let displayText =
        response.data.data.Data[0].Currency +
        " " +
        parseFloat(response.data.data.Data[0].AvilableAgencyBalance).toFixed(3);
      setWalletLoader(false)
      setWalletBalance(response.data.data.Data[0]);
      setOutstandingBalance(parseFloat(response.data.data.Data[0].OutstandingAgencyBalance).toFixed(3))
    } else {
      setWalletLoader(false)
      return false;
    }
  };

  let urlPath = "";
  let urlSubPath = "";
  if (current_url.split("/")[4] && current_url.split("/")[4])
    urlPath = current_url.split(/[/?]/)[4];

  if (current_url.split(/[/?]/)[5]) urlSubPath = current_url.split(/[/?]/)[5];
  // alert(urlPath);

  return (
    <header className="header">
      <SignInModal
        forgotModalOpen={forgotModalOpen}
        registerModalOpen={registerModalOpen}
        setForgotModalOpen={setForgotModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        isSigninModalOpen={isSigninModalOpen}
        setIsSigninModalOpen={setIsSigninModalOpen}
      />

      <div className={`container ${sideMenuOpen ? " show_menu" : ""}`}>
        <div className="d-flex">
          <div
            className="logo_new"
            onClick={() =>
              redirectUrl
                ? window.location.reload()
                : Navigate(process.env.REACT_APP_BASE_URL + culture)
            }
          >
            {" "}
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = logo;
              }}
              style={userData?.AgencyLogo && { height: "55px" }}
              src={userData?.AgencyLogo || logo}
              alt="logo"
            />{" "}
          </div>
          {/* Menu */}
          <nav className="main_nav ">
            <ul className="menu_right header_dropdown">
              <li
                onClick={() => {
                  toggleCulture();
                }}
              >
                <a href="javascript:void(0)">
                  {culture == "en" ? "العربية" : "English"}
                </a>{" "}
              </li>
              {(![
                "SportsDetails",
                "Sports",
                "FlightPax",
                "SportsTicket",
                "FlightTicket",
                "DirectPayment",
                "SpecialOfferDetails",
                "WegoBrandedFare",
                "MetaSearch"
              ].includes(urlPath)  && !currencySearchStatus)
              &&
                !["PassengerDetails", "ReviewDetails", "Payment","FlightReview"].includes(
                  urlSubPath
                ) && (
                  <li
                    ref={countryRef}
                    className={`nav-item dropdown ${loginDetails?.Role == "Admin" && "B2b_currency"} country_menu`}
                    onClick={(e) => {
                      e.preventDefault();
                     /*  if (loginDetails?.Role != "Admin") {
                        setCountryMenuOpen(!countryMenuOpen);
                      } else {
                        return
                      } */
                      setCountryMenuOpen(!countryMenuOpen);
                    }}
                  >
                    <a
                      className="nav-link dropdown-toggle currency_menu country_menu "
                      href="javascript:void(0)"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={"/CountryFlags/" +
                        currentCountry+
                        ".png"}
                        width="20px"
                        alt="countryFlag"
                      />{" "}
                      {currentCountryName[culture]}
                    </a>
                    {countryMenuOpen ? (
                      <div
                        style={
                          countryMenuOpen
                            ? { display: "block" }
                            : { display: "none" }
                        }
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="navbarDropdown"
                      >
                        {countryList.map((country, index) => (
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              changeCurrency(country.Currency,country.Code,country.Name);
                            }}
                          >
                            <span className="country_flag">
                              <img
                                src={"/CountryFlags/" + country.Code +
                                ".png"}
                                width="20px"
                              />
                            </span>
                            <span className="transformCaptialize">
                              {country.Name[culture]}{" "}
                            </span>
                          </a>
                        ))}
                      </div>
                    ) : null}
                  </li>
                )}
              {(![
                "SportsDetails",
                "Sports",
                "FlightPax",
                "SportsTicket",
                "FlightTicket",
                "DirectPayment",
                "SpecialOfferDetails",
                "WegoBrandedFare",
                "MetaSearch"
              ].includes(urlPath ) && !currencySearchStatus) 
              &&
                !["PassengerDetails", "ReviewDetails", "Payment",'FlightReview'].includes(
                  urlSubPath
                ) && (
                  <li
                    ref={currencyRef}
                    className={`nav-item dropdown ${loginDetails?.Role == "Admin" && "B2b_currency"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (loginDetails?.Role != "Admin") {
                        setCurrencyMenuOpen(!currencyMenuOpen);
                      } else {
                        return
                      }
                      

                    }}
                  >
                    <a
                      className="nav-link dropdown-toggle currency_menu "
                      href="javascript:void(0)"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={getCurrencyImages(currentCurrency)}
                        width="20px"
                      />{" "}
                      {currentCurrency}
                    </a>
                    {currencyMenuOpen ? (
                      <div
                        style={
                          currencyMenuOpen
                            ? { display: "block" }
                            : { display: "none" }
                        }
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="navbarDropdown"
                      >
                        {currencyArr.map((currency, index) => (
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              changeCurrency(currency.Code);
                            }}
                          >
                            <span className="country_flag">
                              <img
                                src={getCurrencyImages(currency.Code)}
                                width="20px"
                              />
                            </span>
                            <span className="transformCaptialize">
                              {currency.Code} - { culture == "en"? currency.CurrencyName.en :currency.CurrencyName.ar }{" "}
                            </span>
                          </a>
                        ))}
                      </div>
                    ) : null}
                  </li>
                )}

              {profile && (profile.Name != null || undefined) ? (
                <li
                  ref={profileRef}
                  className="nav-item dropdown"
                  onClick={() => setProfileOpen(!profileOpen)}
                >
                  <a
                    className={`nav-link ${profile?.Role != "Admin" && "dropdown-toggle"
                      } currency_menu `}
                    href="javascript:void(0)"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {profile?.AgencyName?.toUpperCase() || profile?.Name?.toUpperCase()}

                  </a>
                  {profile?.Role != "Admin" && (
                    <div
                      style={
                        profileOpen ? { display: "block" } : { display: "none" }
                      }
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="navbarDropdown"
                    >
                      <>
                        <Link
                          onClick={sideMenuHandler}
                          to={
                            process.env.REACT_APP_BASE_URL +
                            culture +
                            "/Mybooking/ManageBooking"
                          }
                          className="dropdown-item"
                        >
                          <span>{lang.my_booking}</span>
                        </Link>
                        <Link
                          onClick={sideMenuHandler}
                          to={
                            process.env.REACT_APP_BASE_URL +
                            culture +
                            "/MyProfile"
                          }
                          className="dropdown-item"
                        >
                          <span>{lang.my_profile}</span>
                        </Link>
                      </>
                    </div>
                  )}
                </li>
              ) : (
                ""
              )}
              {(profile && profile.Name != null) || undefined ? (
                ""
              ) : (
                <li className="">
                  <Link
                    onClick={sideMenuHandler}
                    to={
                      process.env.REACT_APP_BASE_URL +
                      culture +
                      "/MybookingForm"
                    }
                  >
                    <span href="javascript:void(0)">
                      <i />
                      {lang.header_mybooking}{" "}
                    </span>
                  </Link>{" "}
                </li>
              )}
              {profile?.Role == "Admin" && (
                <li /* onClick={handleAdminRedirect} */>
                  <a href={`/api/redirectToAdminPanel?lang=${culture}`} target="_blank" rel="noreferrer">
                    {lang.settings}
                  </a>{" "}
                </li>
              )}
              {userData?.IsTravelAgent && (
                <>
                  <li
                    onClick={() => walletDisplayFunc()}

                  >
                    <a href="javascript:void(0)">
                      <img style={{ borderRadius: "0px" }} src={wallet} ref={walletRef}></img>
                    </a>{" "}
                    <div
                      style={
                        showWallet ? { display: "block" } : { display: "none" }
                      }
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="navbarDropdown"
                    >
                     {walletLoader ?
                          <div className="loader-sm" id="BtnOnewayRoundtripLoader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                          </div> : 
                          <>
                          {walletBalance.IsCreditUser && <li className="walletdropdwn" >

                          <span href="javascript:void(0)">
                            <i />
                            {lang.wallet_credit_balance} : <span>{currency} </span>{" "}<span> {parseFloat(walletBalance?.CreditBalance).toFixed(3)}</span>
                          </span>
                        </li>}
                          <li className="walletdropdwn" >

                          <span href="javascript:void(0)">
                            <i />
                            {lang.deposit_balance} : <span>{currency} </span>{" "}<span> {parseFloat(walletBalance?.DebitBalance).toFixed(3)}</span>
                          </span>
                        </li>
                          {walletBalance?.OutstandingAgencyBalance > 0 && <li className="walletdropdwn" >

                          <span href="javascript:void(0)">
                            <i />
                            {lang.wallet_outstanding} : <span>{currency} </span><span>-{parseFloat(walletBalance?.OutstandingAgencyBalance).toFixed(3)}</span>
                          </span>
                        </li>}
                        </>
                        }
                    </div>
                  </li>
                </>
              )}
              {profile && (profile.Name != null || undefined) ? (
                <li onClick={signOutHandler}>
                  <a className="user_name" href="javascript:void(0)">
                    <i />
                    {lang.sign_out}
                  </a>{" "}
                </li>
              ) : (
                <li onClick={modalHandler}>
                  <a className="user_name" href="javascript:void(0)">
                    <i />
                    {lang.header_sign_in}
                  </a>{" "}
                </li>
              )}
            </ul>
          </nav>
        </div>
        <div className="overlay" onClick={sideMenuHandler} />
        {/* Menu End */}
        {/* Mobile Menu */}
        <div
          className="mob_btn"
          onClick={() => {
            setSideMenuOpen(true);
            setMobMenuOpen(true);
          }}
        >
          {" "}
          <span /> <span /> <span />{" "}
        </div>
        {/* Mobile Menu End */}
        <div className="cf" />
      </div>
    </header>
  );
}
export default Header;
