import React from "react";
// import "./inner_banner.css";

import fb from "../../assets/images/fb.svg";

function InnerBanner() {
  try {
    
  } catch (error) {
    
  }
  return (
    <div className="inner_banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-8"></div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </div>
  );
}

export default InnerBanner;
