/* eslint-disable eqeqeq */
import React, { useState } from "react";
import ReactModal from "react-modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Popup from "react-popup";
import {
  checkUserExist,
  doCreateUser,
  getTravellerInfo,
  userLogin,
} from "../../services/common";
import { setLoginData } from "../../redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { getMobCode, getMobCountryCode } from "../../services/common";

function RegisterModal(props) {

  const culture = useSelector((state) => state.culture.currentCulture);
  const { setRegisterModalOpen, registerModalOpen, setIsSigninModalOpen } =
    props;

  const countryList = useSelector((state) => state.countryDetails.countries);

  // let mobileCodeList = getMobCode(countryList);
  let countryMob = getMobCountryCode(countryList,culture);
  

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const registerCloseHandler = () => {
    reset();
    setRegisterModalOpen(false);
  };

  const loginHandler = () => {
    setRegisterModalOpen(false);
    setIsSigninModalOpen(true);
  };

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBILE_CODE);
  const [btnLoading, setBtnLoading] = useState(false);
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const lang = useSelector((state) => state.culture.language);

  const SubmitHandler = async (data) => {
    setBtnLoading(true);

    let userInfo = {
      username: data.Email,
      isMobile: false,
    };
    const userExist = await checkUserExist(userInfo);

    if (userExist.CanRegisterAsNewUser) {
      let info = {
        username: data.MobCode + data.PhoneNumber,
        mobileCode: data.MobCode,
        phoneNumber: data.PhoneNumber,
        isMobile: true,
      };
      let userMobExist = await checkUserExist(info);
      if (userMobExist.message != "UserExist") {
        let UserInfo = {
          Title: "",
          FirstName: data.FirstName,
          MiddleName: "",
          LastName: data.LastName,
          Email: data.Email,
          Password: data.Password,
          MobileCode: data.MobCode,
          MobileNumber: data.PhoneNumber,
          SocialToken: "",
          LoginType: 0,
          CoutryOfResidence: "",
          DateOfBirth: "",
        };

        const userCreate = await doCreateUser(UserInfo);

        if (!userCreate) {
          Popup.create({
            title: lang.tathkarah_title,
            content: "User Registration Failed",
            buttons: {
              right: [
                {
                  text: "ok",
                  action: function () {
                    setBtnLoading(false);
                    Popup.close();
                    setBtnLoading(false);
                  },
                },
              ],
            },
          });
        }

        if (userCreate.Message == "Successfully Registered") {
          let info = {
            Email: data.Email,
            Password: data.Password,
          };

          const login = await userLogin(info);

          if (login.IsAuthorized == "True") {
           
            const myProfile = await getTravellerInfo();
          }

          // dispatch(setLoginData(login));
          setRegisterModalOpen(false);

          Popup.create({
            title: lang.tathkarah_title,
            content: lang.user_sucessfully_registered,
            onClose:()=>{
              window.location.reload();
            },
            buttons: {
              right: [
                {
                  text: "ok",
                  action: function () {
                    window.location.reload();
                    setBtnLoading(false);
                    setRegisterModalOpen(false);
                    Popup.close();
                    setBtnLoading(false);
                  },
                },
              ],
            },
          });
        } else if (userCreate.Message == "UserExists") {
          Popup.create({
            title: lang.tathkarah_title,
            content: lang.user_already_exist,
            buttons: {
              right: [
                {
                  text: "ok",
                  action: function () {
                    setBtnLoading(false);
                    setRegisterModalOpen(false);
                    Popup.close();
                    setBtnLoading(false);
                  },
                },
              ],
            },
          });
        } else {
          // alert(" Registration is invalid");
          Popup.create({
            title: lang.tathkarah_title,
            content: lang.register_is_invalid,
            buttons: {
              right: [
                {
                  text: "ok",
                  action: function () {
                    setBtnLoading(false);
                    setRegisterModalOpen(false);
                    Popup.close();
                    setBtnLoading(false);
                  },
                },
              ],
            },
          });
        }
      }
    } else {
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.user_already_exist,
        buttons: {
          right: [
            {
              text: "ok",
              action: function () {
                setBtnLoading(false);
                setRegisterModalOpen(false);
                Popup.close();
                setBtnLoading(false);
              },
            },
          ],
        },
      });
    }

    reset();
  };

  return (
    <ReactModal
      isOpen={registerModalOpen}
      style={customStyles}
      onRequestClose={() => {
        setRegisterModalOpen(false);
      }}
    >
      <div className="popup_wrap">
        <div className="modal-dialog popup_wrap">
          <div className="modal-content">
            {" "}
            {/* Modal body */}
            <div className="modal-body">
              <button
                onClick={registerCloseHandler}
                type="button"
                className="close loginClose"
                data-dismiss="modal"
                aria-label="Close"
              />
              <div className="log_cont_wrap">
                <div className="d-flex justify-content-between align-items-center">
                  <h4>{lang.register}</h4>
                  <a
                    href="javascript:(void);"
                    className="new_acc"
                    data-toggle="modal"
                    data-target="#loginPopup"
                    data-dismiss="modal"
                    onClick={loginHandler}
                  >
                    {lang.login}
                  </a>{" "}
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group">
                    {/*<label htmlFor="exampleInputName">{lang.first_name}</label>*/}
                    <input
                      type="name"
                      className="form-control"
                      id="exampleInputName"
                      placeholder={lang.first_name}
                      {...register("FirstName", {
                        required: {
                          value: true,
                          message: lang.First_Name_is_required,
                        },
                        minLength: {
                          value: 2,
                          message: lang.atleast_two_character,
                        },
                        maxLength: {
                          value: 40,
                          message: lang.No_more_character,
                        },
                        pattern: {
                          value: /^[A-Za-z]+$/i,
                          message: lang.enter_valid_input,
                        },
                        validate : (value) =>
                        (firstName.length + lastName.length) <= 40 ||
                        lang.No_more_character,
                        onChange: (e) => {
                          let value = e.target.value;

                          e.target.value = value.replace(/[^A-Za-z]/gi, "");
                          setFirstName(e.target.value)
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={"FirstName"}
                      render={({ message }) => (
                        <span style={{ color: "red" }} className="error">
                          {message}
                        </span>
                      )}
                    />{" "}
                  </div>
                  <div className="form-group">
                    {/*<label htmlFor="exampleInputLastName">
                      {lang.last_name}
                    </label>*/}
                    <input
                      type="name"
                      className="form-control"
                      id="exampleInputLastName"
                      placeholder={lang.last_name}
                      {...register("LastName", {
                        required: {
                          value: true,
                          message: lang.last_name_required,
                        },
                        minLength: {
                          value: 2,
                          message: lang.atleast_two_character,
                        },
                        maxLength: {
                          value: 40,
                          message: lang.No_more_character,
                        },
                        pattern: {
                          value: /^[A-Za-z]+$/i,
                          message: lang.enter_valid_input,
                        },
                        validate : (value) =>
                        (firstName.length + lastName.length) <= 40 ||
                        lang.No_more_character,
                        onChange: (e) => {
                          let value = e.target.value;

                          e.target.value = value.replace(/[^A-Za-z]/gi, "");
                          setLastName(e.target.value)
                        },
                      })}
                     
                    />
                    <ErrorMessage
                      errors={errors}
                      name={"LastName"}
                      render={({ message }) => (
                        <span style={{ color: "red" }} className="error">
                          {message}
                        </span>
                      )}
                    />{" "}
                  </div>
                  <div className="form-group">
                    {/*<label htmlFor="exampleInputmail">
                      {lang.email_address}
                    </label>*/}
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputmail"
                      placeholder={lang.email_address}
                      {...register("Email", {
                        required: {
                          value: true,
                          message: lang.email_required,
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: lang.invalid_email,
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={"Email"}
                      render={({ message }) => (
                        <span style={{ color: "red" }} className="error">
                          {message}
                        </span>
                      )}
                    />{" "}
                  </div>
                  <div className="w-100">
                    {/*<label htmlFor="exampleInputEmail1">
                      {lang.phone_number}
                    </label>*/}
                    <div className="row">
                      <div className="form-group form_phone">
                        <div className=" d-flex">
                          <div className="hmcode hasDownArrow position-relative">
                            <input
                              className="form-control selectinput"
                              value={mobCode}
                            ></input>
                            <select
                              className="form-control"
                              value={mobCode}
                              {...register("MobCode", {
                                required: {
                                  value: true,
                                  message: lang.mobile_code_is_required,
                                },
                                onChange: (e) => {
                                  setMobCode(e.target.value);
                                },
                                value: mobCode,
                              })}
                            >
                              {countryMob?.map((code, itemNo) => {
                                return (
                                  <option key={itemNo} value={code.MobileCode}>
                                    {code.MobileCode}
                                    &nbsp;
                                    {code.Name[culture]}
                                  </option>
                                );
                              })}
                            </select>
                            <ErrorMessage
                              errors={errors}
                              name={"MobCode"}
                              render={({ message }) => (
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {message}
                                </span>
                              )}
                            />{" "}
                          </div>

                          <div className="hmPhonenbr">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={lang.phone_number}
                              {...register("PhoneNumber", {
                                required: {
                                  value: true,
                                  message: lang.phone_number_required,
                                },
                                minLength: {
                                  value: 5,
                                  message: lang.please_enter_atleast_5_number,
                                },
                                maxLength: {
                                  value: 15,
                                  message:
                                    lang.please_enter_no_more_than_15_Numbers,
                                },
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: lang.please_enter_valid_number,
                                },
                                onChange: (evt) => {
                                  evt.target.value = evt.target.value.replace(
                                    /[^0-9]+$/,
                                    ""
                                  );
                                },
                              })}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={"PhoneNumber"}
                              render={({ message }) => (
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {message}
                                </span>
                              )}
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    {/*<label htmlFor="exampleInputPassword1">
                      {lang.password}
                    </label>*/}
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder={lang.password}
                      {...register("Password", {
                        required: {
                          value: true,
                          message: lang.password_is_required,
                        },
                        minLength: {
                          value: 8,
                          message:
                            lang.password_should_contain_Alphanumeric_special_character_and_minimum_8_characters,
                        },
                        pattern: {
                          value:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                          message:
                            lang.password_should_contain_Alphanumeric_special_character_and_minimum_8_characters,
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={"Password"}
                      render={({ message }) => (
                        <span style={{ color: "red" }} className="error">
                          {message}
                        </span>
                      )}
                    />{" "}
                  </div>
                  <div className="form-group">
                    {/*<label htmlFor="exampleInputPassword2">
                      {lang.confirm_password}
                    </label>*/}
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword2"
                      placeholder={lang.confirm_password}
                      {...register("ConPassword", {
                        required: {
                          value: true,
                          message: lang.confirm_password_is_required,
                        },
                        validate: (val) => {
                          if (watch("Password") != val) {
                            return lang.your_passwords_do_not_match;
                          }
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={"ConPassword"}
                      render={({ message }) => (
                        <span style={{ color: "red" }} className="error">
                          {message}
                        </span>
                      )}
                    />{" "}
                  </div>
                  {btnLoading ? (
                    <div className="loader-sm" id="BtnOnewayRoundtripLoader">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn popup_btn"
                      onClick={handleSubmit(SubmitHandler)}
                    >
                      {lang.register}
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default RegisterModal;
