import React from "react";
import QrImg from '../../assets/images/qr-code.png';
import PlayStoreg from '../../assets/images/play-store.png';
import AppStore from '../../assets/images/app-store.png';
import { useSelector } from 'react-redux';
import Subscribe from "../SubscribeToSite/Subscribe";
export default function HomeAppSection() {
  const lang = useSelector(state => state.culture.language)

  return (


    <section className="app_subscrib_main">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 ">
            <div className="app_subscrib_outer">
              <Subscribe />
              <div className="Hm_AppSec">
                <div className="AppBox">
                  <div className="AppBox_Lft">
                    <div className="qr"><img src={QrImg} /></div>
                  </div>
                  <div className="AppBox_Rht">
                    <h3>{lang.home_want_to_save_on_your_bookings}</h3>
                    <p>{lang.find_and_book_cheap_flights}</p>
                    <div className="appicon"> <i> <img src={PlayStoreg} /> </i> <i> <img src={AppStore} /> </i> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  )
}