import React from 'react'
import InnerBanner from '../common/inner_banner';
// import './seo.css';

import seoabtCities1 from "../../assets/images/seoabtCities1.jpg"
import seoabtCities2 from "../../assets/images/seoabtCities2.jpg"
import luluabt from "../../assets/images/luluaboutus.jpg"
import dutchabt from "../../assets/images/Dutch-Palace.jpg"


const SeoPageThree = () => {
  return (
  
  <>
  <InnerBanner></InnerBanner>
  <div className="container">
  <section className="ContentArea seopg_02">
      <div className="row">
      <div className="col-lg-12">
          <div className="seobox common_bdrBtm">
          <h3 className="d-flex"><i className="cheapflIco" /> Cheap Flights from Abha</h3>
          <div className="seo_box3_list">
              <ul className="d-flex align-content-around flex-wrap ">
              <li className="p-2">Abha To Amman </li>
              <li className="p-2">Abha To Cairo </li>
              <li className="p-2">Abha To Jakarta</li>
              <li className="p-2">Abha To Cochin</li>
              <li className="p-2">Abha To Delhi</li>
              <li className="p-2">Abha To Dammam</li>
              <li className="p-2">Abha To Dubai </li>
              <li className="p-2">Abha To Gizan</li>
              <li className="p-2"> Abha To geneva</li>
              <li className="p-2">Abha To Hyderabad</li>
              <li className="p-2">Abha To geneva </li>
              <li className="p-2"> Abha To Hyderabad </li>
              <li className="p-2"> Abha To Sharm-El-Sheikh </li>
              <li className="p-2">Abha To Jeddah</li>
              <li className="p-2">Abha To Karachi </li>
              <li className="p-2">Abha To Kuala Lumpur </li>
              <li className="p-2">Abha To Lahore </li>
              <li className="p-2">Abha To London </li>
              <li className="p-2"> Abha To Madinah</li>
              <li className="p-2">Abha To Manila </li>
            </ul>
            </div>
        </div>
          <div className="seobox common_bdrBtm">
          <h3 className="d-flex"><i className="cheapflIco" /> Cheap Flights to Cochin</h3>
          <div className="seo_box3_list">
              <ul className="d-flex align-content-around flex-wrap ">
              <li className="p-2">Abha To Cochin </li>
              <li className="p-2">Jakarta To Cochin</li>
              <li className="p-2">Delhi To Cochin</li>
              <li className="p-2">Abha To Cochin</li>
              <li className="p-2">Abha To Delhi</li>
              <li className="p-2">Abha To Dammam</li>
              <li className="p-2">Abha To Dubai </li>
              <li className="p-2">Abha To Gizan</li>
              <li className="p-2"> Abha To geneva</li>
              <li className="p-2">Abha To Hyderabad</li>
              <li className="p-2">Abha To geneva </li>
              <li className="p-2"> Abha To Hyderabad </li>
              <li className="p-2"> Abha To Sharm-El-Sheikh </li>
              <li className="p-2">Abha To Jeddah</li>
              <li className="p-2">Abha To Karachi </li>
              <li className="p-2">Abha To Kuala Lumpur </li>
              <li className="p-2">Abha To Lahore </li>
              <li className="p-2">Abha To London </li>
              <li className="p-2"> Abha To Madinah</li>
              <li className="p-2">Abha To Manila </li>
            </ul>
            </div>
        </div>
        </div>
      <div className="col-lg-12">
          <h2>About the Cities</h2>
        </div>
      <div className="col-lg-12">
          <div className="row">
          <div className="col-lg-6">
              <div className="seobox abouCitiRout common_bdrBtm">
              <h5>Abha</h5>
              <img src={seoabtCities1}  /> <strong>ABOUT ABHA</strong>
              <p>Abha is the capital city of the Asir state of Saudi Arabia. It is located in the South-West region of the country. Being surrounded by several mountains, the temperature and climate of the city are relatively moderate. Due to the pleasant weather and being in the vicinity of the mountains, Abha is an excellent destination for tourists. Abha has several parks, exhibition centres, resorts and historical places to visit. Hence, it is one of the most ideal tourist spots in Saudi Arabia. We, at tathkarah.com, help you develop your travel plans, and offer cheap flight tickets to and from Abha. </p>
              <strong>ABHA AIRPORT</strong>
              <p>The airport serving the city of Abha is called as the Abha International Airport. This airport was established in 1977 and has one terminal. If you are planning to book a flight to and from Abha, then make sure you check tathkarah.com for the cheapest air tickets. Many domestic and international flights operate through this airport and we offer some of the most affordable options among the others for travelers and tourists alike. Search, compare and book your cheap air tickets to and from Abha with tathkarah.com.</p>
              <p> <strong>IATA: AHB </strong> </p>
              <p> <strong>ICAO: OEAB</strong> </p>
            </div>
            </div>
          <div className="col-lg-6">
              <div className="seobox abouCitiRout common_bdrBtm">
              <h5>Cochin</h5>
              <img src={seoabtCities2}  /> <strong>ABOUT COCHIN</strong>
              <p>Cochin is an industrial city in the state of Kerala in India. Cochin has become the commercial capital of Kerala due to its high levels of industrialization. Since it is a coastal city, it is also one of the most sought-after tourist locations in South India. Arabs, British, Chinese, Dutch, and Portuguese have made historical contributions towards the trade and cultural development in this city. It is the perfect tourist place for people who love scenic beaches and historical monuments. You can now book cheap air tickets to and from Cochin through tathkarah.com </p>
              <strong>COCHIN AIRPORT</strong>
              <p>The Cochin International Airport is equipped with all the ultra-modern facilities like a miniature golf course, duty-free shops, medical aid, airport guide, WiFi and many more aspects. It operates many domestic and international direct flights. It is about 28 kilometres away from the city centre. The Cochin airport is the world’s first airport to utilize solar power for all its operations. Having a small and easily navigable terminal, you can be rest assured that finding your way around here will be very easy. By booking to and from Cochin flights through tathkarah.com you can take advantage of the easy and convenient interface that the website offers as well as exciting offers from time to time.</p>
              <p> <strong>IATA: COK </strong> </p>
              <p> <strong>ICAO: VOCI</strong> </p>
            </div>
            </div>
        </div>
        </div>
    </div>
      <div className="row">
      <div className="col-lg-12">
          <h2>Highlights of Cochin</h2>
        </div>
      <div className="col-lg-12">
          <div className="seobox common_bdrBtm">
          <h3 className="d-flex"><i className="seoShopingIco" /> <span>Shopping spots in Cochin</span></h3>
          <div className="HtlSeoRepet ">
              <div className="d-flex flex-wrap">
              <div className="seoshpingPlace"> <img src={luluabt }  /> </div>
              <div className="seoShopDtls">
                  <ul className="d-flex align-content-around flex-wrap ">
                  <li className="p-2">Spice Market </li>
                  <li className="p-2">LuLu International Shopping Mall </li>
                  <li className="p-2">Abad Nucleus Mall </li>
                  <li className="p-2">Centre Square Mall</li>
                  <li className="p-2">Oberon Mall</li>
                  <li className="p-2"> Abad Bay Pride Mall</li>
                  <li className="p-2">Kochi Books</li>
                  <li className="p-2"> Idiom Book Sellers</li>
                  <li className="p-2"> Ethnic Passage </li>
                  <li className="p-2">Jayalakshmi Silks</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
          <div className="seobox common_bdrBtm">
          <h3 className="d-flex"><i className="cheapflIco" /> Places to visit in Cochin</h3>
          <div className="HtlSeoRepet">
              <div className="d-flex flex-wrap">
              <div className="seoshpingPlace"> <img src={dutchabt  }  /> </div>
              <div className="seoShopDtls">
                  <ul className="d-flex align-content-around flex-wrap ">
                  <li className="p-2">Mattancherry Palace </li>
                  <li className="p-2">Santa Cruz Cathedral Basilica, Kochi </li>
                  <li className="p-2">St. Francis Church, Kochi </li>
                  <li className="p-2">Mangalavanam Bird Sanctuary</li>
                  <li className="p-2">Indo-Portuguese Museum</li>
                  <li className="p-2"> Vasco Da Gama Square</li>
                  <li className="p-2">Greenix Village</li>
                  <li className="p-2"> Paradesi Synagogue</li>
                  <li className="p-2"> Kerala Folklore Museum </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
      <div className="row">
      <div className="col-lg-12">
          <h2>FAQs for Cochin</h2>
        </div>
      <div className="col-lg-6 ">
          <div className="seobox seofaqbox common_bdrBtm"> <strong>What is the name of the airport in Cochin?</strong>
          <p>The airport in Cochin is officially named as the Cochin international airport.</p>
          <strong>What is the best season to visit Cochin?</strong>
          <p>Cochin is a coastal city. It is an ideal place to visit all through the year. However, the perfect season to visit this city is during the winters with pleasant weather and lesser crowds of tourists</p>
          <strong>What is the traditional food of Cochin?</strong>
          <p>Grilled fish, with preparation in spices, is a must-try delicacy when you’re in Cochin. It is traditionally known as Chuttulli Meen.</p>
        </div>
        </div>
      <div className="col-lg-6">
          <div className="seobox seofaqbox common_bdrBtm"> <strong>What are the weather conditions in Cochin?</strong>
          <p>Cochin’s temperature varies from 21 to 31 degree Celsius throughout the year. Being a coastal city, it has moderate to high humidity level. </p>
          <strong>What are the best restaurants in Cochin?</strong>
          <p>Fort House Restaurant, The Rice Boat, The Asian Kitchen by Tokyo Bay, Ginger House Restaurant and Oceanos are some of the must-visit restaurants in Cochin.</p>
          <strong>What is the cheapest mode of transport from the airport to the city?</strong>
          <p>An ‘Orange Bus’ is available from the airport to the city at an interval of 30 minutes. Taxi service is also available. However, Metro is under construction and will be accessible soon.</p>
        </div>
        </div>
    </div>
      <div className="row">
      <div className="col-lg-12">
          <div className="seobox common_bdrBtm">
          <div className="col-lg-12 p-0">
              <h3 className="d-flex"><i className="cheapflIco" /> Top Airlines</h3>
            </div>
          <div className="col-lg-12 col-md-12 p-0 seo_box3_list">
              <ul className="d-flex align-content-around flex-wrap ">
              <li className="p-2">Aer Lingus</li>
              <li className="p-2"> Air Canada</li>
              <li className="p-2">Air China</li>
              <li className="p-2">China Eastern Airlines </li>
              <li className="p-2"> China Southern </li>
              <li className="p-2">Delta Airlines</li>
              <li className="p-2">Air France</li>
              <li className="p-2"> Alaska Airlines </li>
              <li className="p-2">American Airlines</li>
              <li className="p-2">Biman Bangladesh</li>
              <li className="p-2"> China Eastern Airlines </li>
              <li className="p-2">China Southernk</li>
            </ul>
            </div>
        </div>
        </div>
    </div>
    </section>
</div>
</>	
	
	
  )
}

export default SeoPageThree