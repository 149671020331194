import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { getApiRequest } from '../../services/getApiRequest';
import { loginDetailsFetch } from "../services/common";
import { getUserPermissionData } from './../services/common';

const getLoginDetails = createAsyncThunk(
  "api/Account/FetchLoginDetails",
  async () => {

    let response = await loginDetailsFetch();
    return response.data;
  }
);

const getUserData = createAsyncThunk(
  "api/Account/getUserData",
  async () => {

    let response = await getUserPermissionData();
    
    return response.data;

  }
);

const loginDetail = createSlice({
  name: "loginDetail",
  initialState: {
    loginDetail: {},
    error: "",
    loginLoading: false,
    userLoading: false,
    isLoggedIn: true,
    userData: {}
  },
  reducers: {
    setLoginData: (state, action) => {
      state.loginDetail = action.payload.data;
      state.loginLoading = false;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
      state.userLoading = false;
    },
  },
  extraReducers: {
    [getLoginDetails.fulfilled]: (state, action) => {

      state.loginLoading = false;
      state.loginDetail = action.payload.data;


    },
    [getLoginDetails.pending]: (state) => {
      state.loginLoading = true;

    },
    [getLoginDetails.rejected]: (state) => {
      state.loginLoading = false;
      state.error = "flightSearch Error";

    },
    [getUserData.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.userData = action.payload.data;


    },
    [getUserData.pending]: (state) => {
      // state.userLoading = true;

    },
    [getUserData.rejected]: (state) => {
      state.userLoading = false;
      state.error = "flightSearch Error";

    },
  },
});




export const { setLoginDetails, setLoginData, setUserData } = loginDetail.actions;
export { getLoginDetails, getUserData };
export default loginDetail.reducer;
