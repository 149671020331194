import React from "react"; 
import { useSelector } from "react-redux";
import why01 from '../../assets/images/why_pic01.svg';
import why02 from '../../assets/images/why_pic02.svg';
import why03 from '../../assets/images/why_pic03.svg';

export default function Homewhy(){
  const lang = useSelector(state=>state.culture.language)

    return(
      

<section className="with_regardTo">
        <div className="container">

        <div className="why_head">
           <h4>{lang.home_why_tathkarah}</h4>
        </div>

          <ul>
            <li><img src={why01} />
              <div className="features_line">
                <h4>{lang.home_we_are_with_you}</h4>
                <p>{lang.home_around_the_clock_customer_service}</p>
              </div>
            </li>
            <li><img src={why02} />
              <div className="features_line">
                <h4>{lang.home_lowest_price_guranteed}</h4>
                <p>{lang.home_we_are_always_negotiating}</p>
              </div>
            </li>
            <li><img src={why03} />
              <div className="features_line">
                <h4>{lang.home_safe_and_secure}</h4>
                <p>{lang.home_we_use_the_latest_security_technology}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>


)
}