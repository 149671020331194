import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApiRequest } from '../../services/getApiRequest';


const getCouponCodeData = createAsyncThunk('api/Flight/pax/GetCouponData', (searchid, navigate) => {
    let ApiRequest = getApiRequest()
    return ApiRequest({
        url: "Flight/pax/GetCouponData",
        method: "post",
        data: {
            searchId: window.location.search.split('?SearchId=')[1],
        }
    })

        .then(function (response) {
            if (response.status == '200') {
                if (response.data.data) {
                    return response.data.data;
                }
            } else {
                navigate(process.env.REACT_APP_BASE_URL)
                return false;
            }
        })
        .catch(function (error) {

            console.log(error);
            navigate("/")
            return false;
        });
})


const couponCodeData = createSlice({
    name: 'CouponData',
    initialState: {
        isCouponApplied: false,
        error: "",
        isLoading: true,
        couponCode: ""
    },
    reducers: {
        setCouponCodeData: (state, action) => {
            if (action.payload) {
                state.isCouponApplied = true
                state.couponCode = action.payload
            } else {

                state.isCouponApplied = false
                state.couponCode = action.payload
            }
            state.isLoading = false;
        }
    },
    extraReducers: {
        [getCouponCodeData.fulfilled]: (state, action) => {
            if (action.payload) {
                state.isLoading = false;
                state.couponCode = action.payload.couponCode;
                state.isCouponApplied = action.payload.isCouponApplied;
            }
        },
        [getCouponCodeData.pending]: state => {
            state.isLoading = false;
        },
        [getCouponCodeData.rejected]: state => {
            state.isLoading = false;
            state.error = "Coupon Code fetch error"
        }
    }
})

export const { setCouponCodeData } = couponCodeData.actions
export { getCouponCodeData };
export default couponCodeData.reducer