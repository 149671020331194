import React,{useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { getDeals } from '../redux/dealsSlice';
import {setCultures} from '../redux/common/cultureSlice';

import Banner from './home/banner';
// import './home/banner.css';

import HomeAppSection from './home/home_app_download';
// import './home/home_app_download.css';

import HomePopularDestination from './home/home_populardestination';
// import './home/home_populardestination.css';

import Homewhy from './home/why';
// import './home/why.css';

import FlightDeals from './deals/FlightDeals';
// import './deals/hm_deals.css';

import FooterCall from './home/ftr_call';
// import './home/ftr_call.css';

import FooterApp from './home/footer_app';
// import './home/footer_app.css';
import PopularFlightDestinations from './deals/PopularFlightDestinations';
import { setCultureSession } from '../services/common';
import SpecialOffer from './specialoffer/SpecialOffer';

function Home() {
	const dispatch = useDispatch();
	useEffect(()=>{
//  async function fetch(){
// const cultureResponse = await setCultureSession({ lang: 'en' });
// 		// 
// 		// // to set culture in express session
// 		dispatch(setCultures(cultureResponse.data))
//  }
//  fetch();
window.scroll(0,0)
		dispatch(getDeals());
	},[])
	return (
		<React.Fragment>
			<Banner></Banner>
			<HomeAppSection></HomeAppSection>
			{/* <HomePopularDestination></HomePopularDestination> */}
			<PopularFlightDestinations/>
			<SpecialOffer></SpecialOffer>
			<Homewhy></Homewhy>
			<FlightDeals></FlightDeals>
			<FooterApp></FooterApp>
			<FooterCall></FooterCall>
		</React.Fragment>
	);
}
export default Home;
