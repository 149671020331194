import React from "react";
import QrImg from '../../assets/images/qr-code.png';
import PlayStoreg from '../../assets/images/play-store.png';
import AppStore from '../../assets/images/app-store.png';
import footerapp from '../../assets/images/ftr_app.png';
import { useSelector } from "react-redux";



export default function FooterApp() {
  const lang = useSelector(state => state.culture.language)

  
  return (
    <section className="footer_app_main">
      <div className="container">
        <div className="row">
          <div className="ftr_app_clm_main">
            <div className="ftr_app_clm1">
              <h3>{lang.home_get_the} <br>
              </br>
                {lang.home_tathkarah_app}</h3>
              <p>{lang.home_our_app_has_all_your_hotel_needs}</p>
            </div>
            <div className="ftr_app_clm2">
              <div className="store_brief">
                <div className="store_qr">
                  <ul>
                    <li><img src={QrImg} /></li>
                  </ul>
                </div>
                <div className="store_links">
                  <ul>
                    <li><img src={PlayStoreg} /></li>
                    <li><img src={AppStore} /></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="ftr_app_clm3"> <img src={footerapp} /> </div>
          </div>
        </div>
      </div>
    </section>
  )
}