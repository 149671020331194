import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { assembleCountrys } from '../../services/common';

const getCountryInfo = createAsyncThunk('api/countryEn', async () => {
    const countryResponse = await assembleCountrys();
	if (countryResponse.Data) {
		return countryResponse.Data;
	}
}); 

 const countrySlice = createSlice({
	name: 'countries',
	initialState: {
		countries: [],
		loading: false
	},
	reducers: {
		setCountries: (state, action) => {
			state.countries = action.payload;
			state.loading = false
		},
	},
	extraReducers: {
		[getCountryInfo.fulfilled]: (state, action) => {
			state.loading = false;
			state.countries = action.payload;
		},
		[getCountryInfo.pending]: state => {
			state.loading = true;
		},
		[getCountryInfo.rejected]: state => {
			state.loading = false;
			state.error = 'Resource Key Error';
		},
	},
});

export { getCountryInfo };
export const { setCountries } = countrySlice.actions;
export default countrySlice.reducer;
