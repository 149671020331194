import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApiRequest } from '../../services/getApiRequest';

const getFlightSearchParams = createAsyncThunk('api/getFlightSearchParams', (searchId, navigate) => {
	let ApiRequest = getApiRequest();
	return ApiRequest({
		url: 'Flight/getFlightSearchParams',
		method: 'post',
		data: {
			searchId: window.location.search.split('?SearchId=')[1].split('_')[0],
		},
	})
		.then(function (response) {
			if (response.status == '200' && response.data.message == 'ok') {
				if (response.data.data) {
					return response.data.data;
				} else {
					navigate("/")
					return false;
				}

			} else {
				window.location.replace(process.env.REACT_APP_BASE_URL);
				navigate(process.env.REACT_APP_BASE_URL)
				return false;
			}
		})
		.catch(function (error) {

			window.location.replace(process.env.REACT_APP_BASE_URL);

			console.log(error);
			navigate("/")
			return false;
		});
});

const flightSearch = createSlice({
	name: 'flightSearch',
	initialState: {
		flightSearch: {},
		error: '',
		loading: false,
	},
	reducers: {
		setFlightSearchData: (state, action) => {
			
			state.flightSearch = action.payload.data;
			state.loading = false;
		},
		setFlightSearchDataWego: (state, action) => {

			state.flightSearch = action.payload;
			state.loading = false;
		},
	},
	extraReducers: {
		[getFlightSearchParams.fulfilled]: (state, action) => {

			if (action.payload != undefined) {
				state.loading = false;
				state.flightSearch = action.payload;
			}

		},
		[getFlightSearchParams.pending]: state => {
			state.loading = true;
		},
		[getFlightSearchParams.rejected]: state => {

			state.loading = false;
			state.error = 'flightSearch Error';
		},
	},
});
export const { setFlightSearchData, setFlightSearchDataWego } = flightSearch.actions;
export { getFlightSearchParams };
export default flightSearch.reducer;