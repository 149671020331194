import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApiRequest } from '../services/getApiRequest';

const getTicketData = createAsyncThunk('api/getTicketData', (navigate
) => {
	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: 'Sports/getTicketData',
		method: 'post',
		data: {
			searchId: window.location.search.split('?SearchId=')[1],
		},
	})
		.then(function (response) {

			if (response.status == '200' && response.data.message == 'ok') {
				return response.data.data;
			} else {
				// navigate(process.env.REACT_APP_BASE_URL)

				return false;
			}
		})
		.catch(function (error) {
			console.log(error);
			// navigate(process.env.REACT_APP_BASE_URL)

			return false;
		});
});

const sportsTicket = createSlice({
	name: 'sportsTicket',
	initialState: {
		sportsTicketData: [],
		error: '',
		loading: true,
		myBookingDetails:null,
		ticketdata:"",
		paymentDetails: ""
	},
	reducers: {
		setTicketParams: (state, action) => {
			
			state.sportsTicketData = action.payload;
			state.loading = false;
		},
		setMyBookingDetails:(state,action)=>{
			state.myBookingDetails = action.payload;
		},
		setTicketData: (state, action) => {
			
			state.ticketdata = action.payload;
			state.loading = false;
		},
		setPaymentDetails: (state, action) => {
			
			state.paymentDetails = action.payload;
			state.loading = false;
		}
		
	},
	extraReducers: {
		[getTicketData.fulfilled]: (state, action) => {

			state.loading = false;
			state.ticketdata = action.payload;
		},
		[getTicketData.pending]: state => {
			state.loading = true;
		},
		[getTicketData.rejected]: state => {
			state.loading = false;
			state.error = 'sportsDetails Error';
		},
	},
});
export const { setTicketParams , setMyBookingDetails, setTicketData,setPaymentDetails} = sportsTicket.actions;

export { getTicketData, };
export default sportsTicket.reducer;
