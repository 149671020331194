import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApiRequest } from '../../services/getApiRequest';



const getSelectedSportsData = createAsyncThunk('api/Flight/pax/getSelectedData', (currentSearchId,navigate,flag = false) => {

	 
	let ApiRequest = getApiRequest();
	/* let currentSearchId = window.location.href.split("?searchId=")[1] */


	return ApiRequest({
		url: 'sports/pax/getSelectedSportsData?SearchID='+ currentSearchId,
		method: 'get',
	})
		.then(function(response) {
			if (response.status == '200' && response.data.message == 'ok') {
				
				return response.data.data;
			} else {
				
				navigate(process.env.REACT_APP_BASE_URL)
				return false;
			}
		})
		.catch(function(error) {
			
			console.log(error);
			navigate(process.env.REACT_APP_BASE_URL)
			return false;
		});
});

const sportSelected = createSlice({
	name: 'sportSelected',
	initialState: {
		flightSelected: {},
		fareDetails: {},
		error: '',
		loading: true,
	},
	reducers: {
		setFlightSelectedData: (state, action) => {
            
            state.flightSelected = action.payload.data.selectedFlight
			state.fareDetails = action.payload.data.selectedFlight[0].FareDetails
            state.loading = false;
		},
		updateFareDetails: (state, action) => {
            
			state.fareDetails = action.payload.data.selectedFlight[0].FareDetails
           
		},
	},
	extraReducers: {
		[getSelectedSportsData.fulfilled]: (state, action) => {
			state.loading = false;
			state.flightSelected = action.payload;
			state.fareDetails = action.payload[0].FareDetails
		},
		[getSelectedSportsData.pending]: state => {
			state.loading = true;
		},
		[getSelectedSportsData.rejected]: state => {
			
			state.loading = false;
			state.error = 'flightSelected Error';
		},
	},
});
export const selectedFlightActions = sportSelected.actions;
export { getSelectedSportsData };
export default sportSelected.reducer;