import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApiRequest } from '../../services/getApiRequest';

const getSportsDetails = createAsyncThunk('api/getSportsDetails', (navigate) => {
	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: 'Sports/getSportsDetails',
		method: 'post',
		data: {
			searchId: window.location.search.split('?SearchId=')[1],
		},
	})
		.then(function (response) {
			if (response.data.status == '200' && response.data.message == 'ok') {
				return response.data.data.Data;
			} else {
				navigate(process.env.REACT_APP_BASE_URL)
				return false;
			}
		})
		.catch(function (error) {
			console.log(error);
			navigate(process.env.REACT_APP_BASE_URL)
			return false;
		});
});

const sportsDetails = createSlice({
	name: 'sportsDetails',
	initialState: {
		sportsDetails: [],
		ItemId: '',
		error: '',
		loading: true,
	},
	reducers: {
		setSportsDetailsData: (state, action) => {
			state.sportsDetails = action.payload;
			state.loading = false;
		},
	},
	extraReducers: {
		[getSportsDetails.fulfilled]: (state, action) => {
			state.loading = false;
			state.sportsDetails = action.payload;
		},
		[getSportsDetails.pending]: state => {
			state.loading = true;
		},
		[getSportsDetails.rejected]: state => {
			state.loading = false;
			state.error = 'sportsDetails Error';
		},
	},
});
export const { setSportsDetailsData, setSportsItemId } = sportsDetails.actions;
export { getSportsDetails };
export default sportsDetails.reducer;
