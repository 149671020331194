import React, { useState } from "react";
import { useSelector } from "react-redux";
import Moment from "moment";
import popularpic02 from "../../assets/images/popular_destination_pic_02.jpg";
import blankImage from "../../assets/images/noimage_destination_02.jpg"

import {
  flightSearchRequest,
  setFlightSearchParams,
  setFlightSearchUrl,
} from "../../services/flight/searchServices";

function PopularFlightDestinations() {
  const lang = useSelector((state) => state.culture.language);
  const [tripType, setTripType] = useState(1);
  const [legParams, setLegParams] = useState([]);
  const deals = useSelector((state) => state.deals.data);
  const culture = useSelector((state) => state.culture.currentCulture);
  const currency = useSelector(
    (state) => state.currencyDetails.currentCurrency
  );
  const currencyCountry = useSelector(
    (state) => state.currencyDetails.currencyCountry
  );



  const filteredTopFlightDeal = deals.filter(function (data) {
    return data.DealGroup == 5;
  }).reverse();



  const flightSearchAction = async (deal) => {

    let searchType = deal.SearchType;
    let sourceCode = deal.AirJourney[0].Source.Code;
    let destinationCode = deal.AirJourney[0].Destination.Code;

    let departureLabel = deal.AirJourney[0].Source.Name.en;
    let arrivalLabel = deal.AirJourney[0].Destination.Name.en;
    let departureLabelArabic = deal.AirJourney[0].Source.Name.ar;
    let arrivalLabelArabic = deal.AirJourney[0].Destination.Name.ar;

    let sourceCity = departureLabel.split("-")[0];
    let destinationCity = arrivalLabel.split("-")[0];
    let sourceCityArabic = departureLabelArabic.split("-")[0];
    let destinationCityArabic = arrivalLabelArabic.split("-")[0];

    let departAllAirport;
    if (deal.AirJourney[0].Source.IsAllAirport) {
      departAllAirport = "Y";
    } else {
      departAllAirport = "N";
    }

    let arrAllAirport;
    if (deal.AirJourney[0].Destination.IsAllAirport) {
      arrAllAirport = "Y";
    } else {
      arrAllAirport = "N";
    }

    let departDate;
    let arrivalDate;
    let type;
    if (searchType == "OneWay") {
      type = 0;
      let responseFromDate = deal.AirJourney[0].Date;
      departDate = Moment(responseFromDate, "DD/MM/YYYY").format("DDMMMYYYY");
      //setDepartDate(Moment(responseFromDate,'DD/MM/YYYY').format("DDMMMYYYY"))
    } else if (searchType == "RoundTrip") {
      type = 1;
      let responseFromDate = deal.AirJourney[0].Date;
      departDate = Moment(responseFromDate, "DD/MM/YYYY").format("DDMMMYYYY");
      let responseToDate = deal.AirJourney[1].Date;

      arrivalDate = Moment(responseToDate, "DD/MM/YYYY").format("DDMMMYYYY");
      //setArrivalDate(Moment(responseToDate,'DD/MM/YYYY').format("DDMMMYYYY"))
    }


    let IsPreferredAirline = "all_flight";
    let preffAirlineLabel = "all_flight";
    let preffAirlineLabelArabic = "all_flight";

    if (deal.PrefferdAirline.Code != null && deal.PrefferdAirline.Name != null) {
      IsPreferredAirline = deal.PrefferdAirline.Code;
      preffAirlineLabel = deal.PrefferdAirline.Name.en;
      preffAirlineLabelArabic = deal.PrefferdAirline.Name.ar;
    }


    const searchParms = {
      deprtCode: sourceCode,
      arrivCode: destinationCode,
      departDate: departDate,
      returnDate: arrivalDate,
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
      preffAirlineLabel: preffAirlineLabel,
      preffAirlineLabelArabic: preffAirlineLabelArabic,
      isNonStop: false,
      tripType: type,
      travelClass: "Economy",
      airline: IsPreferredAirline,
      deptAllAirport: departAllAirport,
      returnAllAirport: arrAllAirport,
      departLabel: departureLabel,
      arrivalLabel: arrivalLabel,
      arrivalCityArabic: "",
      departCityArabic: "",
      arrivalLabelArabic: arrivalLabelArabic,
      departLabelArabic: departureLabelArabic,
      departCity: sourceCity,
      departCityArabic: sourceCityArabic,
      arrivalCity: destinationCity,
      arrivalCityArabic: destinationCityArabic,
    };

    let flightSearchData = await setFlightSearchParams(searchParms);

    let reqObj = {
      data: {
        from: sourceCode,
        to: destinationCode,
        fromDate: departDate,
        toDate: arrivalDate,
        adults: 1,
        childs: 0,
        infants: 0,
        travelClass: "Economy",
        nonStop: false,
        preferedAirline: IsPreferredAirline,
        searchType: type,
        itenary: legParams,
      },
      searchId: flightSearchData.data.searchId,
    };

    let searchResponse = await flightSearchRequest(reqObj,currency, currencyCountry);
    if (searchResponse) {
      //dispatch(setFlightSearchData(flightSearchData));

      let url = setFlightSearchUrl(flightSearchData.data, type, culture);
      //setRedirectUrl(url);
      window.location.href = url;
    }
  };


  return (
    <section className="hm_popular_destination">
      <div className="container">
        {filteredTopFlightDeal.length > 0 ? (
          <>
            <div className="HMLatestFDHead">
              <h4>{lang.home_popular_destinations}</h4>
            </div>
            <div className="row">
              <div className="col-lg-6 FdlsPdng">
                <div className="FlSmwrap">
                  <div className="hm_popular_card">
                    {" "}
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        flightSearchAction(filteredTopFlightDeal[0])
                      }
                    >
                      <img
                        src={filteredTopFlightDeal[0]?.DealImage.ImageName}
                        onError={(e) => {
                          e.target.src = popularpic02;
                          e.target.alt = "Fallback Image";
                        }}
                        alt="dealimg"
                      />
                      <div className="hm_popular_destination_Heading">
                        <h3>
                          <span>
                            {
                              culture == 'en' ? filteredTopFlightDeal[0]?.AirJourney[0].Destination.Name.en.split(
                                "-"
                              )[0] : filteredTopFlightDeal[0]?.AirJourney[0].Destination.Name.ar.split(
                                "-"
                              )[0]
                            }
                          </span>
                        </h3>
                      </div>
                      <div className="hm_popular_destination_Btm">
                        <button className="pupulardesti_btn">
                          {lang.search_pannel_flight}
                        </button>
                      </div>
                    </a>
                  </div>
                  <div className="hm_popular_card">
                    {" "}
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        flightSearchAction(filteredTopFlightDeal[1])
                      }
                    >
                      <img
                        src={filteredTopFlightDeal[1]?.DealImage.ImageName || blankImage}
                        onError={(e) => {
                          e.target.src = popularpic02;
                          e.target.alt = "Fallback Image";
                        }}
                        alt="dealimg"
                      />
                      <div className="hm_popular_destination_Heading">
                        <h3>
                          <span>
                            {
                              culture == 'en' ? filteredTopFlightDeal[1]?.AirJourney[0].Destination.Name.en.split(
                                "-"
                              )[0] : filteredTopFlightDeal[1]?.AirJourney[0].Destination.Name.ar.split(
                                "-"
                              )[0]
                            }
                          </span>
                        </h3>
                      </div>
                      
                      {filteredTopFlightDeal[1] &&
                        <div className="hm_popular_destination_Btm">
                          <button className="pupulardesti_btn">
                            {lang.search_pannel_flight}
                          </button>
                        </div>}
                    </a>
                  </div>
                </div>
                <div className="hm_popular_card_Full">
                  {" "}
                  <a
                    href="javascript:void(0)"
                    onClick={() => flightSearchAction(filteredTopFlightDeal[2])}
                  >
                    <img
                      src={filteredTopFlightDeal[2]?.DealImage.ImageName || blankImage}
                      onError={(e) => {
                        e.target.src = popularpic02;
                        e.target.alt = "Fallback Image";
                      }}
                      alt="dealimg"
                    />
                    <div className="hm_popular_destination_Heading">
                      <h3>
                        <span>
                          {
                            culture == 'en' ? filteredTopFlightDeal[2]?.AirJourney[0].Destination.Name.en.split(
                              "-"
                            )[0] : filteredTopFlightDeal[2]?.AirJourney[0].Destination.Name.ar.split(
                              "-"
                            )[0]
                          }
                        </span>
                      </h3>
                    </div>
                    {filteredTopFlightDeal[2] &&
                        <div className="hm_popular_destination_Btm">
                          <button className="pupulardesti_btn">
                            {lang.search_pannel_flight}
                          </button>
                        </div>}
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hm_popular_card_Full">
                  {" "}
                  <a
                    href="javascript:void(0)"
                    onClick={() => flightSearchAction(filteredTopFlightDeal[3])}
                  >
                    <img
                      src={filteredTopFlightDeal[3]?.DealImage.ImageName || blankImage}
                      onError={(e) => {
                        e.target.src = popularpic02;
                        e.target.alt = "Fallback Image";
                      }}
                      alt="dealimg"
                    />
                    <div className="hm_popular_destination_Heading">
                      <h3>
                        <span>
                          {
                            culture == 'en' ? filteredTopFlightDeal[3]?.AirJourney[0].Destination.Name.en.split(
                              "-"
                            )[0] : filteredTopFlightDeal[3]?.AirJourney[0].Destination.Name.ar.split(
                              "-"
                            )[0]
                          }
                        </span>
                      </h3>
                    </div>
                    {filteredTopFlightDeal[3] &&
                        <div className="hm_popular_destination_Btm">
                          <button className="pupulardesti_btn">
                            {lang.search_pannel_flight}
                          </button>
                        </div>}
                  </a>{" "}
                </div>
                <div className="FlSmwrap">
                  <div className="hm_popular_card">
                    {" "}
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        flightSearchAction(filteredTopFlightDeal[4])
                      }
                    >
                      <img
                        src={filteredTopFlightDeal[4]?.DealImage.ImageName || blankImage}
                        onError={(e) => {
                          e.target.src = popularpic02;
                          e.target.alt = "Fallback Image";
                        }}
                        alt="dealimg"
                      />
                      <div className="hm_popular_destination_Heading">
                        <h3>
                          <span>
                            {
                              culture == 'en' ? filteredTopFlightDeal[4]?.AirJourney[0].Destination.Name.en.split(
                                "-"
                              )[0] : filteredTopFlightDeal[4]?.AirJourney[0].Destination.Name.ar.split(
                                "-"
                              )[0]
                            }
                          </span>
                        </h3>
                      </div>
                      {filteredTopFlightDeal[4] &&
                        <div className="hm_popular_destination_Btm">
                          <button className="pupulardesti_btn">
                            {lang.search_pannel_flight}
                          </button>
                        </div>}
                    </a>{" "}
                  </div>
                  <div className="hm_popular_card">
                    {" "}
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        flightSearchAction(filteredTopFlightDeal[5])
                      }
                    >
                      <img
                        src={filteredTopFlightDeal[5]?.DealImage.ImageName || blankImage}
                        onError={(e) => {
                          e.target.src = popularpic02;
                          e.target.alt = "Fallback Image";
                        }}
                      />
                      <div className="hm_popular_destination_Heading">
                        <h3>
                          <span>
                            {
                              culture == 'en' ? filteredTopFlightDeal[5]?.AirJourney[0].Destination.Name.en.split(
                                "-"
                              )[0] : filteredTopFlightDeal[5]?.AirJourney[0].Destination.Name.ar.split(
                                "-"
                              )[0]
                            }
                          </span>
                        </h3>
                      </div>
                      {filteredTopFlightDeal[5] &&
                        <div className="hm_popular_destination_Btm">
                          <button className="pupulardesti_btn">
                            {lang.search_pannel_flight}
                          </button>
                        </div>}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          " "
        )}
      </div>
    </section>
  );
}

export default PopularFlightDestinations;
