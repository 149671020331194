import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
// import './index.css';
import App from "./App";
import Path from "./Path";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Site from "./Site";
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Paths />
//   </React.StrictMode>
// );

// const EnTheme = React.lazy(() => import('./enCss'));
// const ArTheme = React.lazy(() => import('./arCss'));

// const CHOSEN_THEME = localStorage.getItem('culture');
// const root = ReactDOM.createRoot(document.getElementById("root"));
const root = ReactDOMClient.createRoot(document.getElementById("root"));
ReactDOM.render(
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <BrowserRouter>
          <Site />
        </BrowserRouter>
      </Provider>
    </Suspense>
 ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
