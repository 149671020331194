import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-autocomplete";
import { getAirportList } from "../../../services/flight/common";
import moment from "moment";
import PassengerBox from "./passengerBox";
import { useSelector } from "react-redux";
import ar from "date-fns/locale/ar";
import "moment/locale/ar";
import InstantMarkupDiscount from "./instantMarkupDiscount";
import { enGB } from "date-fns/locale";
import "react-spring-bottom-sheet/dist/style.css";
import MobileViewBottomSheet from "../../common/MobileViewBottomSheet";

import { addDays } from "date-fns";

//daetepicker for mobole view
import "react-dates/initialize";

import { DateRange } from "react-date-range";

import "react-dates/lib/css/_datepicker.css";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { cookieManagemntReq } from "../../../services/flight/searchServices";
import produce from "immer";


function RoundTrip({
  tripType,
  airlineList,
  deprtCode,
  setDeprtCode,
  arrivCode,
  setArrivCode,
  departDate,
  setDepartDate,
  returnDate,
  setReturnDate,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infantCount,
  setInfantCount,
  preffAirlineLabel,
  setPreffAirlineLabel,
  prefAirlineCode,
  setPrefAirlineCode,
  isNonStop,
  setIsNonStop,
  travelClass,
  setTravelClass,
  deptAllAirport,
  setDeptAllAirport,
  returnAllAirport,
  setReturnAllAirport,
  submitHandler,
  departCity,
  setDepartCity,
  departCityArabic,
  setDepartCityArabic,
  arrivalCity,
  setArrivalCity,
  arrivalCityArabic,
  setArrivalCityArabic,
  departLabel,
  arrivalLabel,
  setDepartLabel,
  setArrivalLabel,
  departLabelArabic,
  arrivalLabelArabic,
  setDepartLabelArabic,
  setArrivalLabelArabic,
  isLoading,
  setIsLoading,
  userData,
  setSelectedApiList,
  selectedApiList,
  selectedApiHandler,
  instantMarkup,
  setInstantMarkup,
  instantDiscount,
  setInstantDiscount,
  width,
  setDeptAirportDataForCookie,
  setArrivAirportDataForCookie,
  recentSearchData,
  setAirportDataForCookie,
  airportDataForCookie,
  setRecentSearchData,
}) {
  const ref = useRef();
  const departRef = useRef(null);
  const lang = useSelector((state) => state.culture.language);
  const culture = useSelector((state) => state.culture.currentCulture);


  //setting for date to start from sunday in arabic
  const customArLocale = {
    ...ar,
    options: {
      // Modify options as needed:
      weekStartsOn: 0, // Set Sunday as the first day of the week
      firstWeekContainsDate: 7, // Consider the first week to contain January 7th
      // Add any other options you want to configure
    },
  };


  const weekDayFormatArr = [
    "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"
  ]
  const weekDayFormatShortArr = [
    "ح", "ن", "ث", "ر", "خ", "ج", "س"
  ]


  //Autocomplete states
  const [departAirportList, setDepartAirportList] = useState({});
  const [arrivAirportList, setArrivAirportList] = useState({});
  const [deptCal, setDeptCal] = useState();
  const [arrCal, setArrCal] = useState();
  const [arrivalRef, setArrivalRef] = useState();
  const [dummyRef, setDummyRef] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isMobileViewBottomSheetOpen, setMobileViewBottomSheetOpen] = useState({
    isOpen: false,
    type: "",
  });

  const [focusedInput, setFocusedInput] = useState(null);

  // for development delete after use

  const [viewwidth, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = viewwidth <= 768;

  //end



  ///passengerbox props
  const passengerProps = {
    adultCount,
    childCount,
    infantCount,
    setAdultCount,
    setChildCount,
    setInfantCount,
    setTravelClass,
    travelClass,
    width,
  };

  //travelClass props
  const travelClassProps = {
    setPreffAirlineLabel,
    preffAirlineLabel,
    setPrefAirlineCode,
    setTravelClass,
    airlineList,
    setIsNonStop,
    isNonStop,
  };

  const selectionRange = {
    startDate: departDate || new Date(),
    endDate: tripType == 0 ? departDate : returnDate || new Date(),
    key: "selection",
  };

  ///Autocomplete handlers
  const requestAirportListHandler = (value, type) => {
    let airportList = [];

    if (type == "depart") {
      setDepartLabel(value);
      setDepartLabelArabic(value);
    } else {
      setArrivalLabel(value);
      setArrivalLabelArabic(value);
    }

    const fetchAirportList = async () => {

      let airportListData = await getAirportList(value, culture);
      // if (culture == "en") {
      if (airportListData["hits"]["hits"]) {
        airportListData.hits.hits.forEach((item, index) => {
          let {
            city,
            name,
            countryname,
            code,
            isallairport,
            arabiccity,
            arabicname,
            arabiccountryname,
          } = item._source;
          airportList.push({
            code: code,
            label: city + "-" + name + "-" + countryname + "(" + code + ")",
            arabiclabel:
              arabiccity +
              "-" +
              arabicname +
              "-" +
              arabiccountryname +
              "(" +
              code +
              ")",
            city: city,
            arabiccity: arabiccity,
            isallairport: isallairport,
            arabiccity: arabiccity,
            city: city,
            isallairport: isallairport,
          });
        });
      }
      if (type == "depart") {
        setDepartAirportList((prevState) => {
          return { ...prevState, [value]: airportList };
        });
      } else {
        setArrivAirportList((prevState) => {
          return { ...prevState, [value]: airportList };
        });
      }
    };

    if (value != "") {
      fetchAirportList();
    } else {
      if (type == "depart") {
        setDepartAirportList({});
      } else {
        setArrivAirportList({});
      }
    }

  };

  const selectAirportHandler = (e, item, type) => {
    if (type == "switch") {
      if (departCity && arrivalCity) {
        let tempLabel = departLabel;
        let tempLabelAr = departLabelArabic;
        let tempCity = departCity;
        let tempCityAr = departCityArabic;
        let tempCode = deprtCode;
        let tempAllAirport = deptAllAirport;

        setDepartLabel(arrivalLabel);
        setDepartLabelArabic(arrivalLabelArabic);
        setDepartCity(arrivalCity);
        setDepartCityArabic(arrivalCityArabic);
        setDeprtCode(arrivCode);
        setDeptAllAirport(returnAllAirport);

        setArrivalLabel(tempLabel);
        setArrivalLabelArabic(tempLabelAr);
        setArrivalCity(tempCity);
        setArrivalCityArabic(tempCityAr);
        setArrivCode(tempCode);
        setReturnAllAirport(tempAllAirport);
      }
    } else if (type == "depart") {
      setDepartLabel(item.label);
      setDepartLabelArabic(item.arabiclabel);
      setDepartCity(item.city);
      setDepartCityArabic(item.arabiccity);
      setDeprtCode(item.code);
      setDeptAllAirport(item.isallairport ? "Y" : "N");

      /*  !isMobile && */ arrivalRef.focus();

      setMobileViewBottomSheetOpen({ isOpen: false, type: "" });
      setDeptAirportDataForCookie(item);
    } else {
      setArrivalLabel(item.label);
      setArrivalLabelArabic(item.arabiclabel);
      setArrivalCityArabic(item.arabiccity);
      setArrivalCity(item.city);
      setArrivCode(item.code);
      setReturnAllAirport(item.isallairport ? "Y" : "N");
      setArrivAirportDataForCookie(item);
      if (!isMobile) {
        deptCal.setOpen(true);
      }
      setMobileViewBottomSheetOpen({ isOpen: false, type: "" });
    }

    if (type != "switch") {
      let check = airportDataForCookie.some((air) => {
        return air.code == item.code;
      });

      if (!check) {
        setAirportDataForCookie((prevState) => {
          return produce(prevState, (draft) => {
            draft.push(item);
          });
        });
      }
    }
  };

  ///Datepicker handlers
  const FromDateInput = React.forwardRef(({ value, onClick }, ref) => {
    let dateSeletedCulture;
    // if (culture == "ar") {
    //   moment.locale("ar");
    // dateSeletedCulture = moment(new Date(departDate)).format("DD/MM/YYYY");
    // } else {
    dateSeletedCulture = moment(new Date(departDate)).locale("en").format("DD/MM/YYYY");
    // }
    /* moment.locale("en"); */
    let dateSelected = moment(new Date(departDate)).locale("en").format("MMM DD YYYY");

    return (
      <div className="fieldWrap icon_calendar" onClick={onClick} ref={ref}>
        <input
          readOnly
          spellCheck="false"
          autoComplete="off"
          type="text"
          id=" "
          placeholder="test"
          value={dateSeletedCulture}
        ></input>
        <input type="hidden" name="lname" value={dateSelected}></input>
      </div>
    );
  });

  const ToDateInput = React.forwardRef(({ value, onClick }, ref) => {
    let dateSeletedCulture;
    if (returnDate == null) {
      // if (culture == "ar") {
      //   moment.locale("ar");
      //   dateSeletedCulture = moment(new Date(departDate)).format("DD/MM/YYYY");
      // } else {
      dateSeletedCulture = moment(new Date(departDate)).format("DD/MM/YYYY");
      // }
    } else {
      // if (culture == "ar") {
      //   moment.locale("ar");
      //   dateSeletedCulture = moment(new Date(returnDate)).format("DD/MM/YYYY");
      // } else {
      dateSeletedCulture = moment(new Date(returnDate)).format("DD/MM/YYYY");
      // }
    }

    moment.locale("en");
    let dateSelected = moment(new Date(returnDate)).format("MMM DD YYYY");

    return (
      <div className="fieldWrap icon_calendar" onClick={onClick} ref={ref}>
        <input
          readOnly
          className={tripType == 0 ? "disable" : ""}
          spellCheck="false"
          autoComplete="off"
          type="text"
          id=" "
          placeholder="Arriving on"
          value={moment(new Date(returnDate)).format("DD/MM/YYYY")}
        ></input>
        <input
          type="hidden"
          name="lname"
          value={moment(new Date(returnDate)).format("DD/MM/YYYY")}
        ></input>
      </div>
    );
  });

  const dateSelectionHandler = (date, type) => {
    if (isMobile) {
      if (isMobileViewBottomSheetOpen.type == "departingDate") {
        setDepartDate(date.selection.startDate);
        setStartDate(date.selection.endDate);
        console.log(departDate, returnDate);
        if (date.selection.startDate >= returnDate) {
          setReturnDate(addDays(new Date(date.selection.endDate), 7));
          setEndDate(addDays(new Date(date.selection.endDate), 7));
        }



        tripType == 0
          ? setMobileViewBottomSheetOpen({ isOpen: false, type: "" })
          : setMobileViewBottomSheetOpen({ isOpen: true, type: "returnDate" });
      } else {
        setReturnDate(date.selection.endDate);
        setEndDate(date.selection.endDate);
        setMobileViewBottomSheetOpen({ isOpen: false, type: "" });
      }
    } else {
      if (tripType == 0) {
        setDepartDate(date[0]);
        setStartDate(date[0]);
        setReturnDate(date[0]);
        setEndDate(date[0]);
        deptCal.setOpen(false);
      } else {
        setDepartDate(date[0]);
        setStartDate(date[0]);
        if (date[1] == null) {
          setReturnDate(date[0]);
        } else {
          setReturnDate(date[1]);
        }
        setEndDate(date[1]);
      }
    }
  };

  ///Pref airline Handlers

  const prefAirlineReqHandler = (value) => {
    setPreffAirlineLabel(value);
    if (value == "") {
      setPrefAirlineCode("all_flight");
    }
    /* setPrefAirlineCode(value); */
  };

  const prefAirlineHandler = (e, item) => {
    let label = item.Name + " (" + item.Code + ")";
    setPreffAirlineLabel(label);
    setPrefAirlineCode(item.Code);
  };

  const airlineListFilterHandler = (item) => {
    if (preffAirlineLabel != "") {
      let listItemCode = item.Code.toLowerCase();
      let listItemName = item.Name.toLowerCase();
      let value = preffAirlineLabel.toLowerCase();
      if (listItemName.includes(value) || listItemCode.includes(value)) {
        return item;
      } else {
        return false;
      }
    }
  };

  ///clear button handler

  const clearButtonHandler = (type) => {
    if (type == "from") {
      setDepartLabel("");
      setDepartLabelArabic("");
      setDepartCity("");
      setDepartCityArabic("");
      setDeprtCode("");
      setDeptAllAirport("");
      setDepartAirportList({})
    }
    if (type == "to") {
      setArrivalLabel("");
      setArrivalLabelArabic("");
      setArrivalCity("");
      setArrivalCityArabic("");

      setArrivCode("");
      setReturnAllAirport("");
      setArrivAirportList({})
    }
    if (type == "pref") {
      setPreffAirlineLabel("");
      setPrefAirlineCode("all_flight");
    }
  };

  const inputOnBlurHandler = (type) => { };

  const handleDayPickerDateChange = (value) => { };

  const cookieRemoveHandler = () => {
    setRecentSearchData([]);
    cookieManagemntReq(null, "remove");
  };

  console.log("test foe Ios", typeof (culture == "en" ? departLabel : departLabelArabic),)

  return (
    <>
      <form>
        <div className="search_main">
          <div className="search_lft">
            <div className="fieldbox_row  ">
              <div className="mob_frm_to">
                <div className="field_clm_01 field_clm_01_from field-mr-15">
                  <div className="fieldWrap icon_from DisplayinRmv autocomplete no-select-color">
                    <label className="mob_from_label">{lang.from}</label>

                    {isMobile ? (
                      <div
                        onClick={(e) => {
                          setMobileViewBottomSheetOpen({
                            isOpen: true,
                            type: "depature",
                          });
                        }}
                      >
                        <input
                          style={{ userSelect: "none" }}
                          id="dept"
                          autoComplete="off"
                          autoFocus={false}
                          onFocus={(event) => {
                            // Deselect text on focus
                            event.preventDefault();

                          }}
                          onMouseDown={(event) => {
                            // Prevent automatic selection on mouse down
                            event.preventDefault();
                          }}
                          value={
                            culture == "en" ? departLabel : departLabelArabic
                          }
                          placeholder={lang.search_pannel_from_airport}
                          name="lname"
                          readOnly
                        ></input>
                      </div>
                    ) : (
                      <Autocomplete
                        wrapperStyle={{ display: "inherit" }}
                        wrapperProps={{ className: "auto_complete" }}
                        getItemValue={(item) => item.code}
                        autoHighlight={false}
                        inputProps={{
                          placeholder: lang.search_pannel_from_airport,
                          readOnly: deprtCode ? true : false,
                        }}
                        onChange={(e) =>
                          requestAirportListHandler(e.target.value, "depart")
                        }
                        items={
                          departAirportList[departLabel] !== undefined
                            ? departAirportList[departLabel]
                            : []
                        }
                        renderItem={(item, isHighlighted) => (
                          <div className="react-autocomplete">
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {culture == "en" ? item.label : item.arabiclabel}
                            </div>
                          </div>
                        )}
                        value={
                          culture == "en" ? departLabel : departLabelArabic
                        }
                        onSelect={(e, item) => {
                          selectAirportHandler(e, item, "depart");
                        }}
                      />
                    )}
                    {isMobileViewBottomSheetOpen.isOpen &&
                      isMobileViewBottomSheetOpen.type == "depature" && (
                        <MobileViewBottomSheet
                          open={isMobileViewBottomSheetOpen.isOpen}
                          setOpen={setMobileViewBottomSheetOpen}
                          maxHeightProp={0.9}
                        >
                          <div className="mob_autocomplete_box mob_passenger_block">
                            <button
                              type="button"
                              className="close"
                              onClick={() =>
                                setMobileViewBottomSheetOpen({
                                  isOpen: false,
                                  type: "",
                                })
                              }
                            />
                            <h2>{lang.mob_dept_airport_select}</h2>
                            <Autocomplete
                              autoHighlight={false}
                              ref={departRef}
                              wrapperStyle={{ display: "inherit" }}
                              wrapperProps={{ className: "auto_complete", }}
                              menuStyle={{ top: 0 }}
                              getItemValue={(item) => item.code}
                              inputProps={{
                                placeholder: lang.search_pannel_from_airport, autoComplete: "new-password"
                              }}
                              open={departAirportList[departLabel] !== undefined && departAirportList[departLabel].length > 0}
                              onChange={(e) =>
                                requestAirportListHandler(
                                  e.target.value,
                                  "depart"
                                )
                              }
                              items={
                                departAirportList[departLabel] !== undefined
                                  ? departAirportList[departLabel]
                                  : []
                              }
                              renderItem={(item, isHighlighted) => {
                                return (
                                  <div className="react-autocomplete">
                                    <div
                                      className={
                                        isHighlighted
                                          ? " react-autocomplete-inner active "
                                          : "react-autocomplete-inner"
                                      }
                                    >
                                      {culture == "en"
                                        ? item.label
                                        : item.arabiclabel}
                                    </div>
                                  </div>
                                )
                              }}
                              value={
                                culture == "en"
                                  ? departLabel
                                  : departLabelArabic
                              }
                              onSelect={(e, item) =>
                                selectAirportHandler(e, item, "depart")
                              }
                            />
                            {(departLabel || departLabelArabic) && (
                              <a
                                className="mob_auto_close"
                                id="close_from_city"
                                onClick={() => {
                                  clearButtonHandler("from");
                                }}
                                style={{
                                  top: "84px", // Adjust the top position as needed
                                }}
                              ></a>
                            )}
                            {recentSearchData?.length > 0 &&
                              departLabel == "" && (
                                <div>
                                  <div className="mob_auto_hd">
                                    <h3>{lang.mob_recent_search}</h3>
                                    <span onClick={cookieRemoveHandler}>
                                      {lang.clear}
                                    </span>
                                  </div>

                                  <div className="mob_auto_list">
                                    <ul>
                                      {recentSearchData?.map((item) => {
                                        return (
                                          <li
                                            onClick={() => {
                                              selectAirportHandler(
                                                item.label,
                                                item,
                                                "depart"
                                              );
                                            }}
                                          >
                                            {" "}
                                            {culture == "en" ?item.label : item.arabiclabel}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              )}
                          </div>
                        </MobileViewBottomSheet>
                      )}

                    {(departLabel || departLabelArabic) && (
                      <a
                        className="clear_btn"
                        id="close_from_city"
                        onClick={() => {
                          clearButtonHandler("from");
                        }}
                      ></a>
                    )}
                  </div>
                </div>
                <div
                  className="swich_mob"
                  onClick={() => selectAirportHandler(null, null, "switch")}
                ></div>
                <div className="field_clm_01 field_clm_01_from field-mr-15 mr-md-0 mob_to">
                  <div className="fieldWrap icon_to DisplayinRmv autocomplete  no-select-color">
                    <label className="mob_from_label">{lang.to}</label>
                    {isMobile ? (
                      <div
                        onClick={() => {
                          setMobileViewBottomSheetOpen({
                            isOpen: true,
                            type: "arrival",
                          });
                        }}
                      >
                        <input
                          type="text"
                          ref={(c) => setArrivalRef(c)}
                          id=" "
                          value={
                            culture == "en" ? arrivalLabel : arrivalLabelArabic
                          }
                          onFocus={(event) => {
                            // Deselect text on focus
                            event.preventDefault();

                          }}
                          onMouseDown={(event) => {
                            // Prevent automatic selection on mouse down
                            event.preventDefault();
                          }}
                          placeholder={lang.search_pannel_to_airport}
                          name="lname"
                          readOnly
                        ></input>
                      </div>
                    ) : (
                      <Autocomplete
                        wrapperStyle={{ display: "inherit" }}
                        wrapperProps={{ className: "auto_complete" }}
                        autoHighlight={false}
                        ref={(c) => setArrivalRef(c)}
                        getItemValue={(item) => item.code}
                        inputProps={{
                          placeholder: lang.search_pannel_to_airport,
                          readOnly: arrivCode ? true : false,
                        }}
                        onChange={(e) =>
                          requestAirportListHandler(e.target.value, "arrival")
                        }
                        items={
                          arrivAirportList[arrivalLabel] !== undefined
                            ? arrivAirportList[arrivalLabel]
                            : []
                        }
                        renderItem={(item, isHighlighted) => (
                          <div className="react-autocomplete">
                            <div
                              className={
                                isHighlighted
                                  ? " react-autocomplete-inner active "
                                  : "react-autocomplete-inner"
                              }
                            >
                              {culture == "en" ? item.label : item.arabiclabel}
                            </div>
                          </div>
                        )}
                        value={
                          culture == "en" ? arrivalLabel : arrivalLabelArabic
                        }
                        onSelect={(e, item) =>
                          selectAirportHandler(e, item, "arrival")
                        }
                      />
                    )}

                    {isMobileViewBottomSheetOpen.isOpen &&
                      isMobileViewBottomSheetOpen.type == "arrival" && (
                        <MobileViewBottomSheet
                          open={isMobileViewBottomSheetOpen.isOpen}
                          setOpen={setMobileViewBottomSheetOpen}
                          maxHeightProp={0.9}
                        >
                          <div className="mob_autocomplete_box mob_passenger_block">
                            <button
                              type="button"
                              className="close"
                              onClick={() =>
                                setMobileViewBottomSheetOpen({
                                  isOpen: false,
                                  type: "",
                                })
                              }
                            />
                            <h2>{lang.mob_arrv_airport_select}</h2>

                            <Autocomplete
                              autoHighlight={false}
                              wrapperStyle={{ display: "inherit" }}
                              wrapperProps={{ className: "auto_complete" }}
                              menuStyle={{ top: 0 }}
                              ref={(c) => setArrivalRef(c)}
                              getItemValue={(item) => item.code}
                              inputProps={{
                                placeholder: lang.search_pannel_to_airport,
                              }}
                              open={arrivAirportList[arrivalLabel] !== undefined && arrivAirportList[arrivalLabel].length > 0}
                              onChange={(e) =>
                                requestAirportListHandler(
                                  e.target.value,
                                  "arrival"
                                )
                              }
                              items={
                                arrivAirportList[arrivalLabel] !== undefined
                                  ? arrivAirportList[arrivalLabel]
                                  : []
                              }
                              renderItem={(item, isHighlighted) => (
                                <div className="react-autocomplete">
                                  <div
                                    className={
                                      isHighlighted
                                        ? " react-autocomplete-inner active "
                                        : "react-autocomplete-inner"
                                    }
                                  >
                                    {culture == "en"
                                      ? item.label
                                      : item.arabiclabel}
                                  </div>
                                </div>
                              )}
                              value={
                                culture == "en"
                                  ? arrivalLabel
                                  : arrivalLabelArabic
                              }
                              onSelect={(e, item) =>
                                selectAirportHandler(e, item, "arrival")
                              }
                            />
                            {(arrivalLabel || arrivalLabelArabic) && (
                              <a
                                className="mob_auto_close"
                                id="close_from_city"
                                onClick={() => {
                                  clearButtonHandler("to");
                                }}
                              ></a>
                            )}
                            {recentSearchData.length > 0 &&
                              arrivalLabel == "" && (
                                <div>
                                  <div className="mob_auto_hd">
                                    <h3>{lang.mob_recent_search}</h3>
                                    <span onClick={cookieRemoveHandler}>
                                      {lang.clear}
                                    </span>
                                  </div>

                                  <div className="mob_auto_list">
                                    <ul>
                                      {recentSearchData?.map((item) => {
                                        
                                        return (
                                          <li
                                            onClick={() => {
                                              selectAirportHandler(
                                                item.label,
                                                item,
                                                "arrival"
                                              );
                                            }}
                                          >
                                            {" "}
                                            {culture == "en" ?item.label : item.arabiclabel}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              )}
                          </div>
                        </MobileViewBottomSheet>
                      )}

                    {(arrivalLabel || arrivalLabelArabic) && (
                      <a
                        className="clear_btn"
                        id="close_from_city"
                        onClick={() => {
                          clearButtonHandler("to");
                        }}
                      ></a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col_date ">
                <div className="field_clm_02 field-mr-15">
                  <div className="fieldWrap icon_calendar">
                    <label className="mob_clndr_label">
                      {lang.depart_date}
                    </label>
                    {isMobile ? (
                      <FromDateInput
                        ref={ref}
                        onClick={() => {
                          setMobileViewBottomSheetOpen({
                            isOpen: true,
                            type: "departingDate",
                          });
                        }}
                      />
                    ) : (

                      <DatePicker

                        minDate={new Date()}
                        maxDate={new Date(new Date().getTime() + (310 * 24 * 60 * 60 * 1000))}
                        ref={(c) => setDeptCal(c)}
                        selected={departDate}
                        startDate={startDate}
                        endDate={endDate}
                        // withPortal
                        monthsShown={2}
                        customInput={<FromDateInput ref={ref} />}
                        onChange={(date) =>
                          dateSelectionHandler(date, "depart")
                        }
                        locale={culture == "en" ? "" : customArLocale}
                        rtl={true}
                        weekStartsOn={3}
                        placeholderText={departDate}
                        selectsRange
                        disabledKeyboardNavigation
                        formatWeekDay={(nameOfDay, index) => {
                          let weeDayIndex = weekDayFormatArr.indexOf(nameOfDay)
                          return culture == "en" ? nameOfDay.substr(0, 3) : weekDayFormatShortArr[weeDayIndex]
                        }}
                        focusSelectedMonth={true}
                      />
                    )}

                    {isMobileViewBottomSheetOpen.isOpen &&
                      isMobileViewBottomSheetOpen.type == "departingDate" && (
                        <MobileViewBottomSheet
                          open={isMobileViewBottomSheetOpen.isOpen}
                          setOpen={setMobileViewBottomSheetOpen}
                          maxHeightProp={0.9}
                        >
                          <div className="mob_autocomplete_box mob_passenger_block">
                            <button
                              type="button"
                              className="close"
                              onClick={() =>
                                setMobileViewBottomSheetOpen({
                                  isOpen: false,
                                  type: "",
                                })
                              }
                            />
                            <h2>{lang.select_depart_date}</h2>
                            <div
                              className="mob_calender"
                              style={{ height: 568, width: 320 }}
                            >
                              <DateRange
                                ranges={[selectionRange]}
                                onChange={(value) => dateSelectionHandler(value)}
                                direction="vertical"
                                scroll={{ enabled: true }}
                                minDate={new Date()}
                                maxDate={addDays(new Date(), 310)}
                                showDateDisplay={false}
                                months={2}
                                verticalHeight={800}
                                locale={culture == "en" ? enGB : customArLocale}

                              />
                            </div>
                          </div>
                        </MobileViewBottomSheet>
                      )}
                    {isMobileViewBottomSheetOpen.isOpen &&
                      isMobileViewBottomSheetOpen.type == "returnDate" && (
                        <MobileViewBottomSheet
                          open={isMobileViewBottomSheetOpen.isOpen}
                          setOpen={setMobileViewBottomSheetOpen}
                          maxHeightProp={0.9}
                        >
                          <div className="mob_autocomplete_box mob_passenger_block">
                            <button
                              type="button"
                              className="close"
                              onClick={() =>
                                setMobileViewBottomSheetOpen({
                                  isOpen: false,
                                  type: "",
                                })
                              }
                            />
                            <h2>{lang.select_return_date}</h2>
                            <div
                              className="mob_calender"
                              style={{ height: 568, width: 320 }}
                            >

                              <DateRange
                                ranges={[selectionRange]}
                                onChange={(value) => dateSelectionHandler(value)}
                                direction="vertical"
                                scroll={{ enabled: true }}
                                minDate={startDate ?? new Date()}
                                maxDate={addDays(new Date(), 310)}
                                showDateDisplay={false}
                                months={2}
                                locale={culture == "en" ? enGB : customArLocale}
                              />
                            </div>
                          </div>
                        </MobileViewBottomSheet>
                      )}

                    {/* <input type="text" id=" " placeholder="Departing On" name="lname"> */}
                    {/*  </input> */}
                  </div>
                </div>
                <div className="field_clm_02 field-mr-15 mr-md-0">
                  <label className="mob_clndr_rtn_label">
                    {lang.flight_return_date}
                  </label>
                  <ToDateInput
                    ref={ref}
                    onClick={() => {
                      isMobile
                        ? tripType != 0 &&
                        setMobileViewBottomSheetOpen({
                          isOpen: true,
                          type: "returnDate",
                        })
                        : deptCal.setOpen(true);
                    }}
                  />
                  {/* <DatePicker
                  minDate={departDate}
                  selected={returnDate}
                  disabled={tripType == 0 ? true : false}
                  ref={(c) => setArrCal(c)}
                  customInput={<ToDateInput ref={ref} />}
                  monthsShown={2}
                  onChange={(date) => dateSelectionHandler(date, "arrival")}
                  locale={culture == "en" ? "" : ar}

                  disabledKeyboardNavigation
                /> */}
                </div>
              </div>
            </div>
            {/* <TravelClassSelector {...travelClassProps} /> */}
            <div className="fieldbox_row">
              <PassengerBox {...passengerProps} />
              <div className="field_clm_01 field-mr-15 mob_bdr cabin_prferd_mobhide">
                <div className="fieldWrap icon_economyclass icon_downarrow">
                  <select
                    className="cabinSelect"
                    name="SeatingClass"
                    id="travelClass"
                    onChange={(e) => setTravelClass(e.target.value)}
                    value={travelClass}
                  >
                    <option value="Economy">
                      {lang.search_pannel_economy_class}
                    </option>
                    <option value="PremiumEconomy">
                      {lang.search_pannel_premium_economy}
                    </option>
                    <option value="BusinessClass">
                      {lang.search_pannel_business_class}
                    </option>
                    <option value="FirstClass">
                      {lang.search_pannel_first_class}
                    </option>
                  </select>
                </div>
              </div>
              <div className="cabin_prferd_mobhide field_clm_01 field-mr-15 mr-md-0 mob_bdr">
                <div className="fieldWrap icon_preferd DisplayinRmv autocomplete">
                  <Autocomplete
                    getItemValue={(item) => item.Name}
                    wrapperProps={{ className: "auto_complete" }}
                    wrapperStyle={{ display: "inherit" }}
                    autoHighlight={false}
                    items={airlineList}
                    inputProps={{
                      placeholder: lang.search_pannel_airline_name,
                      readOnly: prefAirlineCode != "all_flight" ? true : false,
                    }}
                    renderItem={(item, isHighlighted) => (
                      <div className="react-autocomplete">
                        <div
                          className={
                            isHighlighted
                              ? " react-autocomplete-inner active "
                              : "react-autocomplete-inner"
                          }
                        >
                          {item.Name + " (" + item.Code + ")"}
                        </div>
                      </div>
                    )}
                    onChange={(e) => prefAirlineReqHandler(e.target.value)}
                    value={preffAirlineLabel}
                    shouldItemRender={(item) => airlineListFilterHandler(item)}
                    onSelect={(e, item) => {
                      prefAirlineHandler(e, item);
                    }}
                  />
                  {preffAirlineLabel && (
                    <a
                      className="clear_btn"
                      id="close_from_city"
                      onClick={() => {
                        clearButtonHandler("pref");
                      }}
                    ></a>
                  )}
                </div>
              </div>
              <div className="field_clm_01 field-mr-15">
                <div className="hm_nonstop pl-lg-0 pl-2 pb-lg-0 pb-2">
                  <div className="hmcol_4 hmStops">
                    <input
                      checked={isNonStop}
                      onClick={() => setIsNonStop(!isNonStop)}
                      type="checkbox"
                      id="IsDirect2"
                      name="IsDirect2"
                      defaultValue
                    />
                    <label htmlFor="IsDirect2">
                      {lang.search_pannel_non_stop}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {
              /* userData?.IsTravelAgent &&  */ userData?.Permissions &&
              (Object.values(userData?.Permissions).includes(
                "Show Instant Discount"
              ) ||
                Object.values(userData?.Permissions).includes(
                  "Show Instant Markup"
                )) && (
                <div className="search_main search_main_sports sportinstant">
                  <div className="instant_markup">
                    <InstantMarkupDiscount
                      instantMarkup={instantMarkup}
                      setInstantMarkup={setInstantMarkup}
                      instantDiscount={instantDiscount}
                      setInstantDiscount={setInstantDiscount}
                      userData={userData}
                    />
                  </div>
                </div>
              )
            }
            {userData?.ShowAllowedApi && (
              <div className="b2bapilist">
                {userData &&
                  Object.keys(userData?.APIs[1]).length > 0 &&
                  Object.entries(userData?.APIs[1]).map((api, index) => {
                    return (
                      <div className="hmcol_4 hmStops">
                        <input
                          type="checkbox"
                          id={"api1" + index}
                          name="IsDirect2"
                          checked={selectedApiList.includes(api[0])}
                          onChange={() => {
                            selectedApiHandler(api[0]);
                          }}
                          value="true"
                        ></input>
                        <label for={"api1" + index}>
                          {" "}
                          <span> {api[1]}</span>{" "}
                        </label>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className="search_rht">
            {isLoading ? (
              <div className="loader-sm" id="BtnOnewayRoundtripLoader">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            ) : (
              <button
                type="button"
                className="button_theme searchBtn"
                onClick={submitHandler}
              >
                <i className="icon_search"></i> <span>{lang.search}</span>
              </button>
            )}
          </div>
        </div>
      </form >
    </>
  );
}
export default RoundTrip;
