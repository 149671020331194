import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFlightDeal } from "../services/Deals";

const getDeals = createAsyncThunk("api/getDeals", async () => {
	let dealsResponse = await GetFlightDeal()
	if (dealsResponse.Data && dealsResponse.Data != "204" && dealsResponse.Data.FlightDeal) {
		return dealsResponse.Data.FlightDeal
	} else {
		return [];
	}
})

const dealsSlice = createSlice({
	name: 'deals',
	initialState: {
		data: [],
	},
	reducers: {
		setDeals: (state, action) => {
			state.data = action.payload;
		},
	},
	extraReducers: {
		[getDeals.fulfilled]: (state, action) => {
			state.loading = false;
			state.data = action.payload;
		},
		[getDeals.pending]: state => {
			state.loading = true;
		},
		[getDeals.rejected]: state => {
			state.loading = false;
			state.error = 'Req Error';
		},
	},

});

export const { setDeals } = dealsSlice.actions;
export { getDeals };
export default dealsSlice.reducer;