import { configureStore } from '@reduxjs/toolkit';

//sports
import sportsSearchReducer from '../redux/sports/sportsSearch';
import alertReducer from '../redux/alertSlice';
import flightSearchReducer from "../redux/flight/flightSearch"
// import sportsDetailsReducer from '../../../../extendedjumboreact/FrontEnd/src/redux/sports/sportsDetails';
import sportsDetailsReducer from '../redux/sports/sportsDetails';
import currencyReducer from "../redux/commonSlice"
import selectedFlightReducer from "./flight/flightSelectedSlice"
import loginDetailReducer from "./loginSlice"
import countryReducer from "./common/countrySlice"
import sportsPaymentReducer from "./sports/sportsPayment"
import ticketReducer from "./ticketSlice"
import cultureReducer from "./common/cultureSlice"
import dealsReducer from "./dealsSlice";
import paymentCapturedReducer from "./flight/paymentCapturedSlice"
import couponCodeReducer from "../redux/flight/couponCodeSlice"
import sportSelectedReducer from "./sports/selectedSportsSlice"
import selectedAncillaryReducer from "./flight/getSelectedAncillaryDetails";




export default configureStore({
	reducer: {
		culture : cultureReducer,
		alert : alertReducer,
		sportsSearch: sportsSearchReducer,
		flightSearch: flightSearchReducer,
		// sportsDetails: sportsDetailsReducer,
		sportsDetails: sportsDetailsReducer,
		currencyDetails: currencyReducer,
		selectedFlight:selectedFlightReducer,
		selectedSports:sportSelectedReducer,
		loginDetail:loginDetailReducer,
		countryDetails: countryReducer,
		sportsPayment : sportsPaymentReducer,
		ticketData : ticketReducer,
		deals : dealsReducer,
		paymentCaptured : paymentCapturedReducer,
		couponData : couponCodeReducer,
		selectedAncillaryDetails : selectedAncillaryReducer
	},
});
