import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApiRequest } from "../../services/getApiRequest";


const getPaymentDetailsCaptured = createAsyncThunk('common/getPaymentCapturedData', (key, navigate) => {
  let ApiRequest = getApiRequest()
  return ApiRequest({
    url: "common/getPaymentCapturedData",
    method: "post",
    data: {
      key
    }
  })
    .then(function (response) {
      if (response.status == '200') {
        if (response?.data?.data) {
          return response.data.data;
        }
      } else {
        navigate(process.env.REACT_APP_BASE_URL)
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      navigate("/")
      return false;
    });
})



const paymentCapturedSlice = createSlice({
  name: "paymentCaptured",
  initialState: {
    paymentDetails: {},
    searchId: "",
  },
  reducers: {
    setPaymentDetailsCaptured: (state, action) => {
      state.paymentDetails = action.payload?.data?.data;
      state.searchId = action.payload?.data?.data?.searchId || action.payload?.data?.searchId
    },
  }, extraReducers: {
    [getPaymentDetailsCaptured.fulfilled]: (state, action) => {
      if (action.payload) {
        state.isLoading = false;
        state.paymentDetails = action.payload.data;
        state.searchId = action.payload.searchId;
      }
    },
    [getPaymentDetailsCaptured.pending]: state => {
      state.isLoading = false;
    },
    [getPaymentDetailsCaptured.rejected]: state => {
      state.isLoading = false;
      state.error = "Coupon Code fetch error"
    }
  }
});


export const { setPaymentDetailsCaptured } = paymentCapturedSlice.actions;
export { getPaymentDetailsCaptured };
export default paymentCapturedSlice.reducer;