import React, { useState, useContext, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Popup from "react-popup";
import { outsideContext } from "../../../Path";
import MobileViewBottomSheet from "../../common/MobileViewBottomSheet";
import 'react-spring-bottom-sheet/dist/style.css'

function PassengerBox({
  adultCount,
  childCount,
  infantCount,
  setAdultCount,
  setChildCount,
  setInfantCount,
  setTravelClass,
  travelClass,
  width
}) {
  ///ui control states
  const [passengerBoxOpen, setPassengerBoxOPen] = useState(false);
  const lang = useSelector((state) => state.culture.language);
  const [isMobileViewBottomSheetOpen, setMobileViewBottomSheetOpen] = useState({ isOpen: false, type: "" })

  const paxRef = useRef(null);

  const { useOutsideAlerter } = useContext(outsideContext);

  useOutsideAlerter(paxRef, null, "pax", setPassengerBoxOPen);
  ///Passenger Handlers

  const passengerCountHandler = (type) => {
    if (type == "adultMinus") {
      if (adultCount == infantCount) {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.adult_infant_count_error,
          buttons: {
            right: ["ok"],
          },
        });
      } else if (adultCount == 1) {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.atleast_one_passenger,
          buttons: {
            right: ["ok"],
          },
        });
      } else {
        setAdultCount((prvState) => prvState - 1);
      }
    }
    if (type == "adultPlus") {
      if (adultCount + childCount + infantCount == 9) {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.max_adult_count,
          buttons: {
            right: ["ok"],
          },
        });
      } else {
        setAdultCount((prvState) => prvState + 1);
      }
    }
    if (type == "childMinus") {
      if (childCount > 0) {
        setChildCount((prvState) => prvState - 1);
      }
    }
    if (type == "childPlus") {
      if (adultCount + childCount + infantCount == 9) {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.max_adult_count,
          buttons: {
            right: ["ok"],
          },
        });
      } else {
        setChildCount((prvState) => prvState + 1);
      }
    }
    if (type == "infantMinus") {
      if (infantCount > 0) {
        setInfantCount((prvState) => prvState - 1);
      }
    }
    if (type == "infantPlus") {
      if (infantCount == adultCount) {
        Popup.create({
          title: lang.tathkarah_title,
          content:lang.adult_infant_count_error,
          buttons: {
            right: ["ok"],
          },
        });
      } else if (adultCount + childCount + infantCount == 9) {
        Popup.create({
          title: lang.tathkarah_title,
          content:lang.max_adult_count,
          buttons: {
            right: ["ok"],
          },
        });
      } else if (adultCount + childCount + infantCount < 9)
        setInfantCount((prvState) => prvState + 1);
    }
  };

  //for mobile view
  const [viewwidth, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = viewwidth <= 768;



  // mobile view ends here

  return (
    <div className="field_clm_03 field_clm_03_passnger field-mr-15 mr-md-0 mob_bdr">
      <label className="mob_clndr_label">{lang.psngr_box_travellers}</label>
      <div className="fieldWrap icon_passenger icon_downarrow no-select-color" ref={paxRef}>
        {isMobile ?
        <div onClick={() => {
          setMobileViewBottomSheetOpen({ isOpen: true, type: "passengerBox" })
        }}>
        <input
          
          readOnly
          type="text"
          id=" "
          placeholder={
            parseInt(adultCount) +
            " " +
            lang.psngr_adult_placeholder +

            (parseInt(childCount) > 0
              ? ", " + parseInt(childCount) + " " + lang.psngr_child_placeholder
              : "") +
            (parseInt(infantCount) > 0
              ? ", " + parseInt(infantCount) + " " + lang.psngr_infant_placeholder
              : "") + (width < 767 ? "," + lang[travelClass] : "")
          }
          value={parseInt(adultCount) +
            " " +
            lang.psngr_adult_placeholder +

            (parseInt(childCount) > 0
              ? ", " + parseInt(childCount) + " " + lang.psngr_child_placeholder
              : "") +
            (parseInt(infantCount) > 0
              ? ", " + parseInt(infantCount) + " " + lang.psngr_infant_placeholder
              : "") + (width < 767 ? "," + lang[travelClass] : "")}


          name="lname"
        /* value={`${adultCount} Adult(s) ${childCount > 0 && childCount + ", Child(ren)"} ${infantCount > 0 && infantCount + ", Infant(s)"}`} */
        ></input> 
        </div>
        :
          <input
            onClick={() => {
              setPassengerBoxOPen(!passengerBoxOpen);
            }}
            readOnly
            type="text"
            id=" "
            placeholder={
              parseInt(adultCount) +
              " " +
              lang.psngr_adult_placeholder +

              (parseInt(childCount) > 0
                ? ", " + parseInt(childCount) + " " + lang.psngr_child_placeholder
                : "") +
              (parseInt(infantCount) > 0
                ? ", " + parseInt(infantCount) + " " + lang.psngr_infant_placeholder
                : "") + (width < 767 ? "," + lang[travelClass] : "")
            }
            value={parseInt(adultCount) +
              " " +
              lang.psngr_adult_placeholder +

              (parseInt(childCount) > 0
                ? ", " + parseInt(childCount) + " " + lang.psngr_child_placeholder
                : "") +
              (parseInt(infantCount) > 0
                ? ", " + parseInt(infantCount) + " " + lang.psngr_infant_placeholder
                : "") + (width < 767 ? "," + lang[travelClass] : "")}


            name="lname"
          /* value={`${adultCount} Adult(s) ${childCount > 0 && childCount + ", Child(ren)"} ${infantCount > 0 && infantCount + ", Infant(s)"}`} */
          ></input>}

        {isMobileViewBottomSheetOpen.isOpen && isMobileViewBottomSheetOpen.type == "passengerBox" && (
          <MobileViewBottomSheet
            open={isMobileViewBottomSheetOpen.isOpen}
            setOpen={setMobileViewBottomSheetOpen}
            maxHeightProp ={0.55}
          >
            <div className="mob_passenger_block">
              <button
                type="button"
                className="close"
                onClick={() => setMobileViewBottomSheetOpen({isOpen: false, type: ""})}  
              />
              <div className="trvelrsList">
                <h4>{lang.psngr_box_travellers}</h4>
                <div className="d-flex traveller_rows justify-content-between">
                  <div className="travelersLHalf">
                    <p>
                    <i class="mob_adult_icon"></i><span>{lang.psngr_box_adults}</span>
                      <span>{lang.psngr_box_twelve_plus_years}</span>
                    </p>
                  </div>
                  <div className="travelersRHalf d-flex">
                    <button
                      className="btn text-center  plus-minus-button"
                      type="button"
                      onClick={() => passengerCountHandler("adultMinus")}
                    >
                      -
                    </button>
                    <div className="psnrs_travel">{adultCount}</div>
                    <button
                      className="btn text-center  plus-minus-button"
                      type="button"
                      onClick={() => passengerCountHandler("adultPlus")}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="d-flex traveller_rows justify-content-between">
                  <div className="travelersLHalf">
                    <p>
                    <i class="mob_child_icon"></i><span>{lang.psnger_box_children}</span>
                      <span>{lang.psngr_box_two_to_eleven_years}</span>
                    </p>
                  </div>
                  <div className="travelersRHalf d-flex">
                    <button
                      className="btn text-center  plus-minus-button"
                      type="button"
                      onClick={() => passengerCountHandler("childMinus")}
                    >
                      -
                    </button>
                    <div className="psnrs_travel"> {childCount} </div>
                    <button
                      className="btn text-center  plus-minus-button"
                      type="button"
                      onClick={() => passengerCountHandler("childPlus")}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="d-flex traveller_rows justify-content-between">
                  <div className="travelersLHalf">
                    <p>
                    <i class="mob_infant_icon"></i><span>{lang.psngr_box_infants}</span> <span>{lang.zero_to_two_years}</span>
                    </p>
                  </div>
                  <div className="travelersRHalf d-flex">
                    <button
                      className="btn text-center  plus-minus-button"
                      type="button"
                      onClick={() => passengerCountHandler("infantMinus")}
                    >
                      -
                    </button>
                    <div className="psnrs_travel">{infantCount}</div>
                    <button
                      className="btn text-center  plus-minus-button"
                      type="button"
                      onClick={() => passengerCountHandler("infantPlus")}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div class="mob_classlist"><ul><li class={travelClass == "Economy" ? "active" : ""} onClick={()=>{setTravelClass("Economy")}}> {lang.flight_economy} </li><li class={travelClass == "BusinessClass" ? "active" : ""} onClick={()=>{setTravelClass("BusinessClass")}}> {lang.flight_business}</li><li class={travelClass == "FirstClass" ? "active" : ""} onClick={()=>{setTravelClass("FirstClass")}}> {lang.flight_first_class}</li></ul></div>



              {/* <div className="mob_cabinclass fieldWrap icon_economyclass icon_downarrow">
                <select className="cabinSelect" name=" " id=" " value={travelClass} onChange={(e) => {
                  setTravelClass(e.target.value)
                }}>
                  <option value="Economy">{lang.search_pannel_economy_class}</option>
                  <option value="PremiumEconomy">{lang.search_pannel_premium_economy}</option>
                  <option value="BusinessClass">{lang.search_pannel_business_class}</option>
                  <option value="FirstClass">{lang.search_pannel_first_class}</option>
                </select>
              </div> */}


              <div
                className="psngr_applyBox"
                onClick={() => {setPassengerBoxOPen(false)
                  setMobileViewBottomSheetOpen({ isOpen: false, type: "" })
                }}
              >
                <button type="button" className="btn btn-block">
                  {lang.button_apply}
                </button>
              </div>
            </div>
          </MobileViewBottomSheet>
        )}

        <div className={`homepsngerBox ${passengerBoxOpen ? "" : "d-none"}`}>
          <button
            type="button"
            className="close"
            onClick={() => setPassengerBoxOPen(false)}
          />
          <div className="trvelrsList">
            <h4>{lang.psngr_box_travellers}</h4>
            <div className="d-flex traveller_rows justify-content-between">
              <div className="travelersLHalf">
                <p>
                  {lang.psngr_box_adults}
                  <span>{lang.psngr_box_twelve_plus_years}</span>
                </p>
              </div>
              <div className="travelersRHalf d-flex">
                <button
                  className="btn text-center  plus-minus-button"
                  type="button"
                  onClick={() => passengerCountHandler("adultMinus")}
                >
                  -
                </button>
                <div className="psnrs_travel">{adultCount}</div>
                <button
                  className="btn text-center  plus-minus-button"
                  type="button"
                  onClick={() => passengerCountHandler("adultPlus")}
                >
                  +
                </button>
              </div>
            </div>
            <div className="d-flex traveller_rows justify-content-between">
              <div className="travelersLHalf">
                <p>
                  {lang.psnger_box_children}
                  <span>{lang.psngr_box_two_to_eleven_years}</span>
                </p>
              </div>
              <div className="travelersRHalf d-flex">
                <button
                  className="btn text-center  plus-minus-button"
                  type="button"
                  onClick={() => passengerCountHandler("childMinus")}
                >
                  -
                </button>
                <div className="psnrs_travel"> {childCount} </div>
                <button
                  className="btn text-center  plus-minus-button"
                  type="button"
                  onClick={() => passengerCountHandler("childPlus")}
                >
                  +
                </button>
              </div>
            </div>
            <div className="d-flex traveller_rows justify-content-between">
              <div className="travelersLHalf">
                <p>
                  {lang.psngr_box_infants} <span>{lang.zero_to_two_years}</span>
                </p>
              </div>
              <div className="travelersRHalf d-flex">
                <button
                  className="btn text-center  plus-minus-button"
                  type="button"
                  onClick={() => passengerCountHandler("infantMinus")}
                >
                  -
                </button>
                <div className="psnrs_travel">{infantCount}</div>
                <button
                  className="btn text-center  plus-minus-button"
                  type="button"
                  onClick={() => passengerCountHandler("infantPlus")}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          <div className="mob_cabinclass fieldWrap icon_economyclass icon_downarrow">
            <select className="cabinSelect" name=" " id=" " value={travelClass} onChange={(e) => {
              setTravelClass(e.target.value)
            }}>
              <option value="Economy">{lang.search_pannel_economy_class}</option>
              <option value="PremiumEconomy">{lang.search_pannel_premium_economy}</option>
              <option value="BusinessClass">{lang.search_pannel_business_class}</option>
              <option value="FirstClass">{lang.search_pannel_first_class}</option>
            </select>
          </div>


          <div
            className="psngr_applyBox"
            onClick={() => {setPassengerBoxOPen(false)
                      }}
          >
            <button type="button" className="btn btn-block">
              {lang.button_apply}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassengerBox;
