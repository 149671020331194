import React from 'react';
// import './App.css';
// import './assets/css/base.css';
// import './assets/css/theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/common/header';
import Home from './components/home';
import Footer from './components/common/footer';

function App() {
	return (
		<React.Fragment>
			<Header></Header>
			<Home></Home>
			<Footer></Footer>
		</React.Fragment>
	);
}

export default App;
