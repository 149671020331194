import React from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import FlightSearchpanel from '../searchPanels/flightSearchPanel/flight_search_panel';
import SportsSearchpanel from '../searchPanels/sportsSearchpanel/sports_search_panel';
import { useSelector } from 'react-redux'; 
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

function Banner() {
	const lang = useSelector(state=>state.culture.language)
	const [searchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(0);

	const handleTabClick = (index) => {
		setActiveTab(index);
	  };

	useEffect(()=>{
		let isSports =  searchParams.get('Sports')

		if(isSports == "true"){
			setActiveTab(1)
		}	
	},[])

	return (
		<section className="Bannermain">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 bnrcaption">
						<h1>{lang.banner_img_title}</h1>
						<p>{lang.baner_img_desc}</p>
					</div>
				</div>
			</div>
			<div className="col-lg-12 banner_search_section">
				<div className="container">
					<div className="homesearchpanel">
					<Tabs onSelect={handleTabClick} selectedIndex={activeTab}>
							<div className="hm_tab">
								<TabList>
									{' '}
									<Tab>
										{' '}
										<i className="hm_flight_icon"></i> {lang.search_pannel_flight}
									</Tab>{' '}
									<Tab >
										{' '}
										<i className="hm_sports_icon"></i> {lang.search_pannel_sports}
									</Tab>{' '}
								</TabList>
							</div>
							<TabPanel>
								<FlightSearchpanel></FlightSearchpanel>
							</TabPanel>
							<TabPanel>
								<SportsSearchpanel></SportsSearchpanel>
							</TabPanel>
						</Tabs>
					</div>
				</div>
			</div>
		</section>
	);
}
export default Banner;
