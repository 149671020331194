import React, { useState, useEffect } from 'react'
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { checkUserExist, doCreateUser, facebookLoginApiCall, GoogleLoginAPICall, userLogin } from '../../services/common';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Popup from "react-popup";
import { useDispatch, useSelector } from 'react-redux';
import { setLoginData } from '../../redux/loginSlice';
import { GoogleOAuthProvider } from '@react-oauth/google';
import facebookLogo from "../../assets/images/fb_pax.svg"
import axios from 'axios';



const SocialMediaLogin = ({ setIsSigninModalOpen, setLoader }) => {

    const [user, setUser] = useState();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.culture.language);




    useEffect(() => {
        if (user) {

            const googleLogin = async () => {
                const response = await GoogleLoginAPICall(user)
                if (response) {
                    if (setIsSigninModalOpen) {
                        setIsSigninModalOpen(false)
                    }
                    dispatch(setLoginData(response));
                }
            }
            googleLogin()
        }
    }, [user])

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => setUser(res.data));
        },
    });




    const responseFacebook = async (userDetails) => {
        const FacebookLogin = await facebookLoginApiCall(userDetails)

        if (FacebookLogin.status == "200") {
            if (setIsSigninModalOpen) {
                setIsSigninModalOpen(false)
            }
            dispatch(setLoginData(FacebookLogin));
        } else {
            return false
        }
    }


    return (
        <>

            <div className="modlsocial  align-items-center">
                <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,first_name,last_name"
                    callback={responseFacebook}
                    render={renderProps => (
                        <button onClick={renderProps.onClick} type="button"><i className="fbLogin"></i> </button>
                    )}
                />

               

                <button onClick={() => googleLogin()} type="button">
                    <i className="gmailLogin"></i>
                </button>


            </div>

        </>
    )
}

export default SocialMediaLogin