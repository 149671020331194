import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import RegisterModal from "./RegisterModal";
import ForgotModal from "./ForgotModal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  UserLogin,
  GetTravellerInformations,
  SetUserPermissions,
  CheckGoogleAuthenticatorNeeded,
} from "../../services/common";
import { setLoginData, setUserData } from "../../redux/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import SocialMediaLogin from "./SocialMediaLogin";
import { assembleCountrys } from "./../../services/common";
import Popup from "react-popup";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getMobCountryCode } from "../../services/common";
function SignInModal(props) {
  const [mobileLogin, setMobileLogin] = useState(false);
  const [emailLogin, setEmailLogin] = useState(true);
  const [mobileCode, setMobileCode] = useState();
  const [mobCode, setMobCode] = useState(process.env.REACT_APP_MOBILE_CODE);
  const lang = useSelector((state) => state.culture.language);
  const culture = useSelector((state) => state.culture.currentCulture);
  const [isGoogleAuthenticationRequired, setIsGoogleAuthenticationRequired] = useState(false)
  const [isForgotPasswordClicked, setIsForgotPasswordClicked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    resetField
  } = useForm();
  const {
    isSigninModalOpen,
    setIsSigninModalOpen,
    forgotModalOpen,
    setForgotModalOpen,
    registerModalOpen,
    setRegisterModalOpen,
  } = props;
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const countryList = useSelector((state) => state.countryDetails.countries);

  // let mobileCodeList = getMobCode(countryList);
  let countryMob = getMobCountryCode(countryList, culture);

  const registerHandler = () => {
    setRegisterModalOpen(true);
    setIsSigninModalOpen(false);
  };

  const forgotHandler = () => {
    setIsSigninModalOpen(false);
    setForgotModalOpen(true);
    setIsForgotPasswordClicked(!isForgotPasswordClicked);
  };

  const loginCloseHandler = () => {
    reset();
    setIsSigninModalOpen(false);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const customStylesAR = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      inset: "50% 50% auto 50%",
    },
  };
  const CountryList = useSelector((state) => state.countryDetails.countries);

  useEffect(() => {
  
      if (CountryList.Data) {
        if (CountryList.Data != null) {
          let mobileArr = [];
          let uniCodeArr = [];
          let sortedArr = [];
          CountryList.Data?.forEach((item) => {
            if (item.MobileCode) {
              mobileArr.push(item.MobileCode);
            }
          });
          mobileArr.sort((a, b) => {
            if (a < b) {
              return -1;
            }
          });
          setMobileCode(mobileArr);
        }
      }
  }, []);

  const changeHandler = () => {
    reset();
    setEmailLogin(!emailLogin);
    setMobileLogin(!mobileLogin);
  };

  const onSubmit = async (data) => {
    setLoader(true);
    let info;

    if (emailLogin) {
      info = {
        user: data.Email,
        pass: data.Password,
        googleotp: data.googleCode
      };
    } else {
      info = {
        user: data.MobCode + data.PhoneNumber,
        pass: data.Password,
        EnablePhoneNumberCheck: true,
        googleotp: data.googleCode

      };
    }
    if (!data.googleCode && process.env.REACT_APP_ENABLE_GOOGLE_AUTHENTICATOR == 1) {
      let checkIfGoogleAuthenticatorRequired = await CheckGoogleAuthenticatorNeeded(info)
      if (checkIfGoogleAuthenticatorRequired.data.data) {
        setIsGoogleAuthenticationRequired(true)
        setLoader(false);
        return false
      }
    }


    let loginResp = await UserLogin(info);
    if (!loginResp.data.data.error) {
      window.location.reload();
      // dispatch(setLoginData(loginResp?.data)); 
      // setLoader(false);
      // props.setIsSigninModalOpen(!isSigninModalOpen);
      // reset();
    } else if (loginResp.data.data.error_description === "Invalid") {
      props.setIsSigninModalOpen(false);
      setLoader(false);
      Popup.create({
        title: lang.tathkarah_title,
        content: emailLogin ? lang.invalid_email_or_password : lang.invalid_phone_number_or_password,
        buttons: {
          right: [
            {
              text: lang.ok,
              action: function () {
                Popup.close();
                setLoader(false);
                props.setIsSigninModalOpen(true);
                reset()
              },
            },
          ],
        },
      });
      return false;
    } else if (loginResp.data.data.error_description === "IncorrectPassword") {
      props.setIsSigninModalOpen(!isSigninModalOpen);
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.login_invalid_password,
        buttons: {
          right: [
            {
              text: lang.ok,
              action: function () {
                Popup.close();
                setLoader(false);
                props.setIsSigninModalOpen(true);
                resetField("Password")
              },
            },
          ],
        },
      });
    }
    else if (loginResp.data.data.error_description === "Invalid Google OTP") {
      props.setIsSigninModalOpen(!isSigninModalOpen);
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.invalid_google_otp,
        buttons: {
          right: [
            {
              text: lang.ok,
              action: function () {
                Popup.close();
                setLoader(false);
                props.setIsSigninModalOpen(true);
                resetField("googleCode")
              },
            },
          ],
        },
      });
    }


    if (loginResp.data.data.IsAuthorized === "True") {

      // const myprofile = GetTravellerInformations();
      // console.log(myprofile);
    }
    //B2B starts here
    if (loginResp.data.data.Role === "Admin") {

      const response = await SetUserPermissions();

      dispatch(setUserData(response.Data));
    }
  };

  return (
    <>
      <ReactModal
        isOpen={isSigninModalOpen}
        style={culture === "en" ? customStyles : customStylesAR}
        onRequestClose={() => {
          setIsSigninModalOpen(false);
          reset();

        }}
        ariaHideApp={false}
      >
        <div className="popup_wrap">
          <div className="modal-dialog popup_wrap">
            <div className="modal-content">
              {" "}
              {/* Modal body */}
              <div className="modal-body">
                <button
                  onClick={loginCloseHandler}
                  type="button"
                  className="close loginClose"
                  data-dismiss="modal"
                  aria-label="Close"
                />
                <div className="log_cont_wrap">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>{lang.login}</h4>
                    <a
                      href="javascript:(void);"
                      className="new_acc"
                      data-toggle="modal"
                      data-target="#registerPopup"
                      data-dismiss="modal"
                      onClick={registerHandler}
                    >
                      {lang.create_new_account}
                    </a>{" "}
                  </div>
                  <div className="d-flex justify-content-start popupradio">
                    <div id="hmpopEmal">
                      <div className="radio">
                        <input
                          id="hmemail"
                          name="lom"
                          type="radio"
                          className="mailOrPhone"
                          defaultChecked={emailLogin}
                          defaultValue="Email"
                          onChange={() => {
                            changeHandler();
                          }}
                        />
                        <label htmlFor="hmemail" className="radio-label">
                          {lang.email_address}
                        </label>
                      </div>
                    </div>
                    <div id="hmpopEmal">
                      <div className="radio">
                        <input
                          id="hmmobile"
                          name="lom"
                          type="radio"
                          className="mailOrPhone"
                          defaultChecked={mobileLogin}
                          defaultValue="PhoneNumber"
                          onChange={() => {
                            changeHandler();
                          }}
                        />
                        <label htmlFor="hmmobile" className="radio-label">
                          {lang.phone_number}
                        </label>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                      {" "}
                      {emailLogin && (
                        <>
                          {/*<label htmlFor="exampleInputEmail1">
                            {lang.email_address}
                          </label>*/}
                          <input
                            defaultValue=""
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder={lang.email_address}
                            {...register("Email", {
                              required: {
                                value: true,
                                message: lang.email_required,
                              },
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: lang.invalid_email,
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={"Email"}
                            render={({ message }) => (
                              <span style={{ color: "red" }} className="error">
                                {message}
                              </span>
                            )}
                          />
                        </>
                      )}
                      {mobileLogin && (
                        <>
                          {/*<label htmlFor="exampleInputEmail1">
                            {lang.phone_number}
                          </label>*/}
                          <div className="form-group form_phone">
                            <div className="form-group d-flex">
                              <div className="hmcode hasDownArrow position-relative">
                                <input className="form-control selectinput" value={mobCode} ></input>
                                <select
                                  className="form-control"
                                  {...register("MobCode", {
                                    required: {
                                      value: true,
                                      message: lang.mobile_code_is_required,
                                    },
                                    onChange: (e) => {

                                      setMobCode(e.target.value);
                                    },
                                    value: mobCode,
                                  })}
                                >
                                  {countryMob?.map((code, itemNo) => {
                                    return (
                                      <option key={itemNo} value={code.MobileCode}>
                                        {code.MobileCode}
                                        &nbsp;
                                        {code.Name[culture]}
                                      </option>
                                    );
                                  })}
                                </select>
                                <ErrorMessage
                                  errors={errors}
                                  name={"MobCode"}
                                  render={({ message }) => (
                                    <span
                                      style={{ color: "red" }}
                                      className="error"
                                    >
                                      {message}
                                    </span>
                                  )}
                                />{" "}
                              </div>

                              <div className="hmPhonenbr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={lang.phone_number}
                                  {...register("PhoneNumber", {
                                    required: {
                                      value: true,
                                      message: lang.phone_number_required,
                                    },
                                    minLength: {
                                      value: 5,
                                      message:
                                        lang.please_enter_atleast_5_number,
                                    },
                                    maxLength: {
                                      value: 15,
                                      message:
                                        lang.please_enter_no_more_than_15_Numbers,
                                    },
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message:
                                        lang.please_enter_the_valid_number,
                                    },
                                    onChange: (evt) => {
                                      evt.target.value = evt.target.value.replace(
                                        /[^0-9]+$/,
                                        ""
                                      );
                                    }
                                  })}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={"PhoneNumber"}
                                  render={({ message }) => (
                                    <span
                                      style={{ color: "red" }}
                                      className="error"
                                    >
                                      {message}
                                    </span>
                                  )}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      {/*<label htmlFor="exampleInputPassword1">
                        {lang.password}
                      </label>*/}
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder={lang.password}
                        {...register("Password", {
                          required: {
                            value: true,
                            message: lang.password_is_required,
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={"Password"}
                        render={({ message }) => (
                          <span style={{ color: "red" }} className="error">
                            {message}
                          </span>
                        )}
                      />{" "}
                    </div>
                    {
                      isGoogleAuthenticationRequired &&
                      <div className="form-group">
                        {/*<label htmlFor="exampleInputPassword1">
                        {lang.password}
                      </label>*/}
                        <input
                          type="number"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder={lang.google_otp}
                          {...register("googleCode", {
                            required: {
                              value: true,
                              message: lang.google_otp_required,
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={"googleCode"}
                          render={({ message }) => (
                            <span style={{ color: "red" }} className="error">
                              {message}
                            </span>
                          )}
                        />
                        {" "}
                      </div>
                    }


                    <div className="d-flex justify-content-between pt-2 pb-2 align-items-center w-100">

                      <div className="col-lg-12">
                        <div className="row">


                          <div className="col-lg-7">
                            <div className="model_social_outr">
                              {!loader ? (
                                <button type="submit" className="btn popup_btn">
                                  {lang.header_sign_in}
                                </button>
                              ) : (
                                <div className="loader-sm" id="BtnOnewayRoundtripLoader">
                                  <div className="bounce1"></div>
                                  <div className="bounce2"></div>
                                  <div className="bounce3"></div>
                                </div>
                              )}


                              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                                <SocialMediaLogin
                                  setIsSigninModalOpen={setIsSigninModalOpen}
                                  setLoader={setLoader}
                                />
                              </GoogleOAuthProvider>
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <span className="mdl_frgt">
                              <a
                                onClick={forgotHandler}
                                href="javascript:(void);"
                                className="forg_pass"
                                data-toggle="modal"
                                data-target="#forgot_password"
                                data-dismiss="modal"
                              >
                                {lang.forgot_password}
                              </a>
                            </span>{" "}
                          </div>


                        </div>
                      </div>



                    </div>
                  </form>
                  {/*
            <form action>
              <div className="form-group">
                <input type="text" className="form-control" defaultValue placeholder="Enter Email ID \ Phone Number" />
              </div>
              <div className="form-group">
                <input type="password" className="form-control" defaultValue placeholder="Password" />
              </div>
              <div className="d-flex justify-content-between pt-2 align-items-center">
                <button className="search_btn" type="button">Login</button>
                <span><a onClick={forgotHandler} href="javascript:(void);" className="forg_pass" data-toggle="modal" data-target="#forgot_password" data-dismiss="modal">Forgot Password?</a></span> </div>
              <div className="log-wth-social">
                <p>OR LOGIN WITH</p>
                <div className="soc-bt_wrp"> <a href="#" className="face_book"> <i><img src="images/pax_facebookIco.svg" title="facebook" alt="Facebook" /></i> <span>Login With Facebbok</span> </a> <a href="#" className="goog_log"> <i><img src="images/pax_googleIco.svg" title="Google" alt="Google" /></i> <span>Login With Google</span> </a> </div>
              </div>
            </form>
            */}{" "}
                  {/* Google Login */}
                </div>
              </div>
            </div>
          </div>
        </div>

      </ReactModal>
      <RegisterModal
        registerModalOpen={registerModalOpen}
        setRegisterModalOpen={setRegisterModalOpen}
        isSigninModalOpen={isSigninModalOpen}
        setIsSigninModalOpen={setIsSigninModalOpen}
      />
      <ForgotModal
        forgotModalOpen={forgotModalOpen}
        isForgotPasswordClicked={isForgotPasswordClicked}
        setForgotModalOpen={setForgotModalOpen}
        culture={culture}
      />
    </>
  );
}

export default SignInModal;
