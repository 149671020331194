import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApiRequest } from '../../services/getApiRequest';



const getSelectedFlightData = createAsyncThunk('api/Flight/pax/getSelectedData', (currentSearchId,navigate,flag = false) => {

	
	let ApiRequest = getApiRequest();
	/* let currentSearchId = window.location.href.split("?searchId=")[1] */


	return ApiRequest({
		url: 'Flight/pax/getSelectedData?SearchID='+ currentSearchId,
		method: 'get',
	})
		.then(function(response) {
			if (response.status == '200' && response.data.message == 'ok') {
				
				return response.data.data;
			} else {
				
				navigate(process.env.REACT_APP_BASE_URL)
				return false;
			}
		})
		.catch(function(error) {
			
			console.log(error);
			navigate(process.env.REACT_APP_BASE_URL)
			return false;
		});
});
const getUpdatedFlightData = createAsyncThunk('api/Flight/pax/getUpdatedData', (currentSearchId,navigate) => {


	
	let ApiRequest = getApiRequest();
	/* let currentSearchId = window.location.href.split("?searchId=")[1] */


	return ApiRequest({
		url: 'Flight/pax/getSelectedData?SearchID='+ currentSearchId,
		method: 'get',
	})
		.then(function(response) {
			if (response.status == '200' && response.data.message == 'ok') {
				
				return response.data.data;
			} else {
				
				navigate(process.env.REACT_APP_BASE_URL)
				return false;
			}
		})
		.catch(function(error) {
			
			console.log(error);
			navigate(process.env.REACT_APP_BASE_URL)
			return false;
		});
});

const flightSelected = createSlice({
	name: 'flightSelected',
	initialState: {
		flightSelected: {},
		fareDetails: {},
		error: '',
		loading: true,
	},
	reducers: {
		setFlightSelectedData: (state, action) => {
            
            state.flightSelected = action.payload.data.selectedFlight
			state.fareDetails = action.payload.data.selectedFlight[0].FareDetails
            state.loading = false;
		},
		updateFareDetails: (state, action) => {
            
			state.fareDetails = action.payload.data.selectedFlight[0].FareDetails
           
		},
	},
	extraReducers: {
		[getSelectedFlightData.fulfilled]: (state, action) => {
			
	
			state.loading = false;
			state.flightSelected = action.payload;
			state.fareDetails = action.payload[0].FareDetails
		},
		[getSelectedFlightData.pending]: state => {
			state.loading = true;
		},
		[getSelectedFlightData.rejected]: state => {
			
			state.loading = false;
			state.error = 'flightSelected Error';
		},
		[getUpdatedFlightData.fulfilled]: (state, action) => {
			
		
			state.loading = false;
			state.flightSelected = action.payload;
			state.fareDetails = action.payload[0].FareDetails
		},
		[getUpdatedFlightData.pending]: state => {
		
			state.loading =false;
		},
		[getUpdatedFlightData.rejected]: state => {
			
			state.loading = false;
			state.error = 'flightSelected Error';
		},

	},
});
export const selectedFlightActions = flightSelected.actions;
export { getSelectedFlightData , getUpdatedFlightData};
export default flightSelected.reducer;