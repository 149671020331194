import React from 'react';	
 
function Oneway(){

  
return(


<form>
  <div className='search_main'>
    <div className='search_lft'>
      <div className='fieldbox_row'>
        <div className="field_clm_01 field-mr-15">
          <div className="fieldWrap icon_location">
            <input type="text" id=" " placeholder="Leaving From" name="lname">
            </input>
          </div>
        </div>
        <div className="field_clm_01 field-mr-15">
          <div className="fieldWrap icon_location">
            <input type="text" id=" " placeholder="Leaving From" name="lname">
            </input>
          </div>
        </div>

        <div className='col_date '>
        <div className="field_clm_02 field-mr-15">
          <div className="fieldWrap icon_calendar">
            <input type="text" id=" " placeholder="Departing On" name="lname">
            </input>
          </div>
        </div>
        <div className="field_clm_02 field-mr-15">
          <div className="fieldWrap icon_calendar">
            <input type="text" id=" " placeholder="Arriving On" name="lname">
            </input>
          </div>
        </div>
        </div>


        <div className="field_clm_03 field-mr-15 mr-md-0">
          <div className="fieldWrap icon_passenger icon_downarrow">
            <input type="text" id=" " placeholder="Passengers" name="lname">
            </input>
            <div className="homepsngerBox" style={{ display: "block" }}>
              <button type="button" className="close" />
              <div className="trvelrsList">
                <h4>Travellers</h4>
                <div className="d-flex traveller_rows justify-content-between">
                  <div className="travelersLHalf">
                    <p> Adults <span>12+ Years</span> </p>
                  </div>
                  <div className="travelersRHalf d-flex">
                    <button
          className="btn text-center  plus-minus-button"
          type="button"
          onclick="paxvalidation('adultm');"
        > {" "}
                    -{" "} </button>
                    <div className="psnrs_travel"> 1 </div>
                    <button
          className="btn text-center  plus-minus-button"
          type="button"
          onclick="paxvalidation('adultp');"
        > {" "}
                    + </button>
                  </div>
                </div>
                <div className="d-flex traveller_rows justify-content-between">
                  <div className="travelersLHalf">
                    <p> Children <span>2 - 11 Years</span> </p>
                  </div>
                  <div className="travelersRHalf d-flex">
                    <button
          className="btn text-center  plus-minus-button"
          type="button"
          onclick="paxvalidation('adultm');"
        > {" "}
                    -{" "} </button>
                    <div className="psnrs_travel"> 0 </div>
                    <button
          className="btn text-center  plus-minus-button"
          type="button"
          onclick="paxvalidation('adultp');"
        > {" "}
                    + </button>
                  </div>
                </div>
                <div className="d-flex traveller_rows justify-content-between">
                  <div className="travelersLHalf">
                    <p> Infants <span>0 - 2 Years</span> </p>
                  </div>
                  <div className="travelersRHalf d-flex">
                    <button
          className="btn text-center  plus-minus-button"
          type="button"
          onclick="paxvalidation('adultm');"
        > {" "}
                    -{" "} </button>
                    <div className="psnrs_travel"> 0 </div>
                    <button
          className="btn text-center  plus-minus-button"
          type="button"
          onclick="paxvalidation('adultp');"
        > {" "}
                    + </button>
                  </div>
                </div>
              </div>
              <div className="psngr_applyBox">
                <button className="btn btn-block">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='fieldbox_row'>
        <div className="field_clm_01 field-mr-15">
          <div className="fieldWrap icon_economyclass icon_downarrow">
            <select className="cabinSelect" name="SeatingClass" id="travelClass">
              <option value="Economy">Economy Class</option>
              <option value="PremiumEconomy">Premium Economy</option>
              <option value="BusinessClass">Business Class</option>
              <option value="FirstClass">First Class</option>
            </select>
          </div>
        </div>
        <div className="field_clm_01 field-mr-15">
          <div className="fieldWrap icon_preferd">
            <input type="text" id=" " placeholder="Preferred Airline" name="lname">
            </input>
          </div>
        </div>
        <div className="field_clm_01 field-mr-15">
          <div className="hm_nonstop pl-lg-0 pl-2 pb-lg-0 pb-2">
            <div className="hmcol_4 hmStops">
              <input type="checkbox" id="IsDirect2" name="IsDirect2" />
              <label htmlFor="IsDirect2">Non Stop</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='search_rht'>
      <button className="button_theme searchBtn"><i className="icon_search"></i> </button>
    </div>
  </div>
</form>




)
}
export default Oneway;