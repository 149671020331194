import React, { useEffect } from "react";
import Paths from "./Path";
import Popup from "react-popup";
import { useDispatch } from "react-redux";
import { isOpen } from "./redux/alertSlice";

function Site() {
  const dispatch = useDispatch();

  useEffect(() => {}, []);
  Popup.addShowListener(() => {
    dispatch(isOpen(true));
  });
  Popup.addCloseListener(() => {
       
    dispatch(isOpen(false));
  });
  
  if (process.env.REACT_APP_ENV == 'PRODUCTION') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }

  return (
    <>
      <Popup  closeBtn={false}/>
      <Paths />
    </>
  );
}

export default Site;
