import { getApiRequest } from "./getApiRequest";



export function GetFlightDeal() {
	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: '/Deals/flightDeal',
		method: 'post',
        data: {
            isElastic : false,
            currency : 'KWD'
        }
		
	}).then(function(response) {
        if (response.data.status == '200' && response.data.message == 'ok') {
            return response.data.data;
        } else {
            return false;
        }
    })
    .catch(function(error) {
        console.log(error, "flightDeal-error");
        return false;
    });
		
}