import moment from "moment";
import { setFlightSearchData } from "../../redux/flight/flightSearch";
import { getApiRequest } from "../getApiRequest";
import { getAirlineList, getAirportList, getBookingClassKey } from "./common";

export const setFlightSearchUrl = (params, type, culture) => {



  let fromDateString = moment(params.departDate).format("DDMMMYYYY");
  let toDateString = moment(params.returnDate).format("DDMMMYYYY");

  // let culture = "en";
  let url = process.env.REACT_APP_BASE_URL + culture + "/";

  if (type == 0) {
    url =
      url +
      "FlightResult/" +
      "OneWay" +
      "/" +
      params.deprtCode +
      "-" +
      params.arrivCode +
      "-" +
      fromDateString +
      "/A-" +
      params.adultCount +
      "-C-" +
      params.childCount +
      "-I-" +
      params.infantCount +
      "/" +
      params.travelClass +
      "/" +
      params.isNonStop +
      "/" +
      params.airline +
      "/" +
      params.deptAllAirport +
      "-" +
      params.returnAllAirport +
      "?SearchId=" +
      params.searchId;
  } else if (type == 1) {
    url =
      url +
      "FlightResult/" +
      "RoundTrip" +
      "/" +
      params.deprtCode +
      "-" +
      params.arrivCode +
      "-" +
      fromDateString +
      "_" +
      params.arrivCode +
      "-" +
      params.deprtCode +
      "-" +
      toDateString +
      "/A-" +
      params.adultCount +
      "-C-" +
      params.childCount +
      "-I-" +
      params.infantCount +
      "/" +
      params.travelClass +
      "/" +
      params.isNonStop +
      "/" +
      params.airline +
      "/" +
      params.deptAllAirport +
      "-" +
      params.returnAllAirport +
      "?SearchId=" +
      params.searchId;
  } else {
    let route = "";
    let allAirport = "";

    for (let i = 0; i < params?.noOfLegs?.length; i++) {
      if (i != 0) {
        route += "_";
        allAirport += "_";
      }
      route +=
        params.legParams[i].departCode +
        "-" +
        params.legParams[i].arrivCode +
        "-" +
        moment(params.legParams[i].departDate).format("DDMMMYYYY");
      allAirport +=
        params.legParams[i].departIsAllAirport +
        "-" +
        params.legParams[i].arrivIsAllAirport;
    }

    url = `${url}FlightResult/MultiCity/${route}/A-${params.adultCount}-C-${params.childCount}-I-${params.infantCount}/${params.travelClass}/${params.isNonStop}/${params.airline}/${allAirport}?SearchId=${params.searchId}`;

    /* url =
      url +
      "FlightResult/" +
      "MultiCity" +
      "/" +
      route +
       +
      "/A-" +
      params.adultCount +
      "-C-" +
      params.childCount +
      "-I-" +
      params.infantCount +
      "/" +
      params.travelClass +
      "/" +
      params.isNonStop +
      "/" +
      params.airline +
      "/" +
      allAirport +
      "?SearchId=" +
      params.searchId; */
  }

  console.log(url);
  return url;
};

export const setFlightSearchParams = (data) => {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Flight/setFlightSearchParams",
    method: "post",
    data: data,
  })
    .then(function (response) {
      console.log(response);
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
};

export function flightSearchRequest(reqObj, currency, currencyCountry = "KW") {
  //axios object with barrier token and search key

  const params = reqObj.data;

  let ApiRequest = getApiRequest();

  //for recent search
  if (!Array.isArray(params.preferedAirline)) {
    if (params.preferedAirline !== "") {
        params.preferedAirline = [params.preferedAirline];
    } else {
        params.preferedAirline = [];
    }
}

  let Journeys = [];


  if (params.searchType == 2) {

    params.itenary.map((itemSearch, key) => {
      if (itemSearch.arrivCode) {

        Journeys.push({
          Source: {
            Code: itemSearch.departCode,
            IsAll: itemSearch.departIsAllAirport == "Y"
          },
          Destination: {
            Code: itemSearch.arrivCode,
            IsAll: itemSearch.arrivIsAllAirport == "Y"
          },
          Date: moment(new Date(itemSearch.departDate)).format("DDMMMYYYY"),
          SeatClass: getBookingClassKey(params.travelClass),
          Airline: params.preferedAirline,
        });
      }
    });
  } else {
    Journeys.push({
      Source: {
        Code: params.from,
        IsAll: params.deptAllAirport == "Y",
      },
      Destination: {
        Code: params.to,
        IsAll: params.returnAllAirport == "Y",
      },
      Date: params.fromDate,
      SeatClass: getBookingClassKey(params.travelClass),
      Airline: params.preferedAirline,
    });
    if (params.searchType == 1) {
      Journeys.push({
        Source: {
          Code: params.to,
          IsAll: params.deptAllAirport == "Y",
        },
        Destination: {
          Code: params.from,
          IsAll: params.returnAllAirport == "Y",
        },
        Date: params.toDate,
        SeatClass: getBookingClassKey(params.travelClass),
        Airline: params.preferedAirline,
      });
    }
  }



  let searObj = {
    data: {
      Journeys: Journeys,
      Passengers: {
        Adults: params.adults,
        Children: params.childs,
        Infant: params.infants,
      },
      SearchConfig: {
        //incoming search type value as index (0,1,2) ,change to (1,2,3)
        SearchType: params.searchType + 1,
        IsDirect: params.nonStop,
        IsFlexibleDate: false,
        Currency: currency,
        APIId:
          params.selectedApiList != undefined
            ? params.selectedApiList.length > 0
              ? params.selectedApiList
              : null
            : null,
        Country: currencyCountry,
        SearchId: reqObj.searchId,
      },
      InstantCharge: {
        InstantMarkup: params.instantMarkup,
        InstantDiscount: params.instantDiscount,
      },
      Configuration: {
        IsBehalfMode: false,
        BehalfAgency: "",
        BehalfUser: "",
        NationalFare: false,
      },
      Utm: {
        Source: "",
        Medium: "",
        Campaign: "",
        Term: "",
        Content: "",
      },
    },
    searchId: reqObj.searchId,
  };

  return ApiRequest({
    url: "Flight/Search",
    method: "post",
    data: searObj,
  })
    .then(function (response) {
      console.log(response);

      if (response.status == "200") {
        return response;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}

export const newSearchReq = async (
  searchString,
  currency,
  dispatch,
  currentSearchID,
  currencyCountry
) => {
  const legNos = process.env.REACT_APP_MULTI_CITY_LEGS;
  const noOfLegs = [];

  let paramsObj = {
    departLabel: "",
    arrivLabel: "",
    departDate: new Date(),
    departCode: "",
    departIsAllAirport: "",
    arrivIsAllAirport: "",
    arrivCode: "",
    departCity: "",
    arrivalCity: "",
  };

  let legParams = [];

  let params = searchString.split("/");

  let trip = params[0];
  let itenary = params[1];
  let passenger = params[2].split("-");
  let travelClass = params[3];
  let isNonStop = params[4];
  let airline = params[5];
  let allAirport =
    trip == "OneWay" || trip == "RoundTrip"
      ? params[6].split("?")[0].split("-")
      : params[6].split("?")[0].split("_");

  //Setting up common params fopr new search req

  let tripType = trip == "OneWay" ? 0 : trip == "RoundTrip" ? 1 : 2;
  let adultCount = passenger[1];
  let childCount = passenger[3];
  let infantCount = passenger[5];
  let preffAirlineLabel = "";

  if (airline != "all_flight") {
    let airlineData = await getAirlineList();
    let prefAirlineItem = airlineData.Data.find((item) => item.Code == airline);
    preffAirlineLabel =
      prefAirlineItem.Name + " (" + prefAirlineItem.Code + ")";
  }

  //setting params for oneway and roundtrip

  let deprtCode,
    arrivCode,
    departDate,
    returnDate,
    arrivalLabel,
    departLabel,
    arrivalCity,
    departCity,
    returnAllAirport,
    deptAllAirport,
    departDateStr,
    returnDateStr;

  if (tripType == 1) {

    let departData = itenary.split("_")[0];
    let arrivData = itenary.split("_")[1];
    deprtCode = departData.split("-")[0];
    arrivCode = arrivData.split("-")[0];
    departDate = new Date(departData.split("-")[2]);
    returnDate = new Date(arrivData.split("-")[2]);

    deptAllAirport = allAirport[0];
    returnAllAirport = allAirport[1];
    departDateStr = departData.split("-")[2];
    returnDateStr = arrivData.split("-")[2];

    let deptAirportListData = await getAirportList(deprtCode);
    let arrivAirportListData = await getAirportList(arrivCode);

    let deptAirportData = deptAirportListData.hits.hits[0]._source;
    let arrivAirportData = arrivAirportListData.hits.hits[0]._source;
    departCity = deptAirportData.city;
    arrivalCity = arrivAirportData.city;
    departLabel =
      deptAirportData.city +
      "-" +
      deptAirportData.name +
      "-" +
      deptAirportData.countryname +
      "(" +
      deptAirportData.code +
      ")";
    arrivalLabel =
      arrivAirportData.city +
      "-" +
      arrivAirportData.name +
      "-" +
      arrivAirportData.countryname +
      "(" +
      arrivAirportData.code +
      ")";
  } else if (tripType == 0) {

    let departData = itenary;

    deprtCode = departData.split("-")[0];
    arrivCode = departData.split("-")[1];
    departDate = new Date(departData.split("-")[2]);


    deptAllAirport = allAirport[0];
    returnAllAirport = allAirport[1];
    departDateStr = departData.split("-")[2];


    let deptAirportListData = await getAirportList(deprtCode);
    let arrivAirportListData = await getAirportList(arrivCode);

    let deptAirportData = deptAirportListData.hits.hits[0]._source;
    let arrivAirportData = arrivAirportListData.hits.hits[0]._source;
    departCity = deptAirportData.city;
    arrivalCity = arrivAirportData.city;
    departLabel =
      deptAirportData.city +
      "-" +
      deptAirportData.name +
      "-" +
      deptAirportData.countryname +
      "(" +
      deptAirportData.code +
      ")";
    arrivalLabel =
      arrivAirportData.city +
      "-" +
      arrivAirportData.name +
      "-" +
      arrivAirportData.countryname +
      "(" +
      arrivAirportData.code +
      ")";
  }


  else {
    //setting params Multicity

    let legData = itenary.split("_");
    let legCount = legData.length - 1;

    for (let i = 0; i <= legCount; i++) {
      legParams.push(paramsObj);
      noOfLegs.push("leg" + (i + 1));
    }

    for (let i = 0; i <= legCount; i++) {
      let deptAirportCode = legData[i].split("-")[0];
      let arrivAirportCode = legData[i].split("-")[1];

      let deptDate = new Date(legData[i].split("-")[2]);
      let legAllAirport = allAirport[i].split("-");

      let deptAirportListData = await getAirportList(deptAirportCode);
      let arrivAirportListData = await getAirportList(arrivAirportCode);
      let deptAirportData = deptAirportListData.hits.hits[0]._source;
      let arrivAirportData = arrivAirportListData.hits.hits[0]._source;

      let deptLabel =
        deptAirportData.city +
        "-" +
        deptAirportData.name +
        "-" +
        deptAirportData.countryname +
        "(" +
        deptAirportData.code +
        ")";
      arrivalLabel =
        arrivAirportData.city +
        "-" +
        arrivAirportData.name +
        "-" +
        arrivAirportData.countryname +
        "(" +
        arrivAirportData.code +
        ")";

      let arrivLabel =
        arrivAirportData.city +
        "-" +
        arrivAirportData.name +
        "-" +
        arrivAirportData.countryname +
        "(" +
        arrivAirportData.code +
        ")";
      arrivalLabel =
        arrivAirportData.city +
        "-" +
        arrivAirportData.name +
        "-" +
        arrivAirportData.countryname +
        "(" +
        arrivAirportData.code +
        ")";
      console.log(legParams);

      /*  legParams[i].departCity = deptAirportData.city;
      legParams[i].arrivalCity = arrivAirportData.city;
      legParams[i].departCode = deptAirportCode;
      legParams[i].arrivCode = arrivAirportCode;
      legParams[i].departLabel = deptLabel;
      legParams[i].arrivLabel = arrivLabel;
      legParams[i].departDate = deptDate;
      legParams[i].departIsAllAirport = legAllAirport[0];
      legParams[i].arrivIsAllAirport = legAllAirport[1]; */

      let paramsObjTemp = {
        departLabel: deptLabel,
        arrivLabel: arrivLabel,
        departDate: deptDate,
        departCode: deptAirportCode,
        departIsAllAirport: legAllAirport[0],
        arrivIsAllAirport: legAllAirport[1],
        arrivCode: arrivAirportCode,
        departCity: deptAirportData.city,
        arrivalCity: arrivAirportData.city,
      };

      legParams[i] = paramsObjTemp;

      console.log(legParams);
    }
  }

  //setting flight search params for new request

  let searchParams = {};

  if (tripType == 0 || tripType == 1) {
    searchParams = {
      deprtCode,
      arrivCode,
      departDate,
      returnDate,
      adultCount,
      childCount,
      infantCount,
      preffAirlineLabel,
      isNonStop,
      tripType,
      travelClass,
      airline,
      deptAllAirport,
      returnAllAirport,
      departCity,
      arrivalCity,
      departLabel,
      arrivalLabel,
      newSearchId: currentSearchID,
    };
  } else {
    searchParams = {
      legParams,
      noOfLegs,
      adultCount,
      childCount,
      infantCount,
      preffAirlineLabel,
      isNonStop,
      tripType,
      travelClass,
      airline,
    };
  }

  let flightSearchData = await setFlightSearchParams(searchParams);

  let reqObj = {
    data: {
      from: deprtCode,
      to: arrivCode,
      fromDate: departDateStr,
      toDate: returnDateStr,
      adults: adultCount,
      childs: childCount,
      infants: infantCount,
      travelClass: travelClass,
      nonStop: isNonStop,
      preferedAirline: preffAirlineLabel,
      searchType: tripType,
      itenary: legParams,
    },
    searchId: flightSearchData.data.searchId,
  };

  let searchResponse = await flightSearchRequest(reqObj, currency, currencyCountry);

  if (searchResponse) {
    dispatch(setFlightSearchData(flightSearchData));
  }
};

export const cookieManagemntReq = (data, type) => {
  let ApiRequest = getApiRequest();

  if (type == "Add") {
    return ApiRequest({
      url: "Flight/cookieManagementAdd",
      method: "post",
      data: data,
    })
      .then(function (response) {
        console.log(response);
        if (response.status == "200") {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  } else if (type == "remove") {
    return ApiRequest({
      url: "Flight/cookieManagementRemove",
      method: "post",
    })
      .then(function (response) {
        console.log(response);
        if (response.status == "200") {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }
  else {
    return ApiRequest({
      url: "Flight/cookieManagementFindAll",
      method: "post",
      data: data,
    })
      .then(function (response) {
        if (response.status == "200" && response.data.data != "Not found") {
          return response.data;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }

};
export const cookieManagemntReqForRecentSearch = (data, url, tripType, dateTime = new Date(), reqObj, searchParms) => {
  let reqBody = { ...data, urlString: url, tripType: tripType, dateTime, reqObj, searchParms }
  let ApiRequest = getApiRequest();



  return ApiRequest({
    url: "Flight/cookieManagementAddForRecentSearch",
    method: "post",
    data: reqBody,

  })
    .then(function (response) {
      console.log(response);
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });



};
export const cookieManagementFindSpecific = (data) => {
  let ApiRequest = getApiRequest();


  return ApiRequest({
    url: "Flight/cookieManagementFindSpecific",
    method: "post",
    data: data,
  })
    .then(function (response) {

      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });


};


