import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getApiRequest } from '../../services/getApiRequest';

const getSportsSeatData = createAsyncThunk('api/getSportsSeatData', (navigate) => {
	let ApiRequest = getApiRequest();

	return ApiRequest({
		url: 'Sports/getSportsSeatData',
		method: 'post', 
		data: {
			searchId: window.location.search.split('?SearchId=')[1],
		},
	})
		.then(function(response) {
			if (response.data.status == '200' && response.data.message == 'ok') {
				return response.data.data;
			} else {
				navigate(process.env.REACT_APP_BASE_URL)
				return false;
			}
		})
		.catch(function(error) {
			console.log(error);
			navigate(process.env.REACT_APP_BASE_URL)
			return false;
		});
});

const sportsPayment = createSlice({
	name: 'sportsDetails',
	initialState: {
		sportsSeatData: [],
		ItemId: '',
		error: '',
		loading: true,
	},
	reducers: {
		setSportsSeatData: (state, action) => {
			state.sportsSeatData = action.payload;
			state.loading = false;
		},
		setInstantDiscountMarkup: (state, action) => {
			state.sportsSeatData = action.payload;
			state.loading = false;
		},
	},
	extraReducers: {
		[getSportsSeatData.fulfilled]: (state, action) => {
			
			state.loading = false;
			state.sportsSeatData = action.payload;
		},
		[getSportsSeatData.pending]: state => {
			state.loading = true;
		},
		[getSportsSeatData.rejected]: state => {
			state.loading = false;
			state.error = 'sportsDetails Error';
		},
	},
});
export const { setSportsSeatData,setInstantDiscountMarkup } = sportsPayment.actions;
export { getSportsSeatData };
export default sportsPayment.reducer;
 