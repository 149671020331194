import React, { useState, useEffect } from "react";
import "./footer.css";
import logo from "../../assets/images/logo.png";

import fb from "../../assets/images/fb.svg";
import tweet from "../../assets/images/tweet.svg";
import insta from "../../assets/images/insta.svg";
import linkedin from "../../assets/images/in.svg";
import { useSelector } from "react-redux";
import CheapFlightDeals from "../deals/CheapFlightDeals";

function Footer() {
  const lang = useSelector((state) => state.culture.language);
  const culture = useSelector((state) => state.culture.currentCulture);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  window.addEventListener("scroll", handleScroll, { passive: true });

  return (
    <footer>
      <div className="footer_clm_2">
        {scrollPosition && (
          <div
            id="topcontrol"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          ></div>
        )}

        <div className="trunk_one ">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="foot_logo">
                  <img src={logo} alt="" />
                </div>
                <p className="foot_brief">{lang.all_rights_reserved} </p>
                <div className="ftr_social">
                  <ul>
                    <li>
                      <a
                        href={process.env.REACT_APP_FACEBOOK_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={fb} width={30} height={30} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={process.env.REACT_APP_TWITTER_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={tweet} width={30} height={30} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={process.env.REACT_APP_INSTAGRAM_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={insta} width={30} height={30} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <CheapFlightDeals />
              <div className="col-12 col-md-6 col-lg-3">
                <div className="footLinkArea">
                  <h5>{lang.footer_have_a_question}</h5>
                  <ul>
                    <li className="phNum_bold">+965 22206222</li>
                    <li>info@tathkarah.com</li>
                    <li>{lang.footer_twenty_four_dedicated_support}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="trunk_two">
          <div className="container">
            <div className="row">
              <div className="w-100">
                <ul className="foot_quickLinks">
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${culture}/AboutUs`}
                    >
                      {lang.footer_about_us}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${culture}/SiteMap`}
                    >
                      {lang.footer_site_map}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${culture}/PrivacyPolicy`}
                    >
                      {lang.footer_privacy_policies}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${culture}/Terms`}
                    >
                      {lang.footer_terms_and_conditions}{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BASE_URL}${culture}/ContactUS`}
                    >
                      {lang.footer_contact_us}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
