import axios from 'axios';

export function getApiRequest() {
	let api = axios.create({
		baseURL: process.env.REACT_APP_API_URL + 'api/',
		headers: {
			'Referrer-Policy': 'no-referrer',
		},
	});

	return api;
}
