import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { addDays } from "date-fns";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import Popup from "react-popup";
import Oneway from "./oneway";
import RoundTrip from "./roundtrip";
import MultiCity from "./multicity";
// import "./flight_search_panel.css";
import {
  cookieManagemntReq,
  setFlightSearchParams,
  setFlightSearchUrl,
  cookieManagemntReqForRecentSearch
} from "../../../services/flight/searchServices";
import { useSelector, useDispatch } from "react-redux";

import { getAirlineList } from "../../../services/flight/common";
import produce from "immer";
import { setFlightSearchData } from "../../../redux/flight/flightSearch";
import { flightSearchRequest } from "../../../services/flight/searchServices";
import moment from "moment";
import { setFlightElkLog } from "../../../services/elk/elk";
import RecentSearch from "./RecentSearch";

function FlightSearchpanel() {
  const culture = useSelector((state) => state.culture.currentCulture);

  const lang = useSelector((state) => state.culture.language);

  const userData = useSelector((state) => state.loginDetail.userData);

  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const currency = useSelector(
    (state) => state.currencyDetails.currentCurrency
  );
  const currencyCountry = useSelector(
    (state) => state.currencyDetails.currencyCountry
  );
  const [isLoading, setIsLoading] = useState(false);
  ///search params states oneWay roundTrip
  const [instantMarkup, setInstantMarkup] = useState();
  const [instantDiscount, setInstantDiscount] = useState();
  const [tripType, setTripType] = useState(1);
  const [airlineList, setAirlineList] = useState([]);
  const [deprtCode, setDeprtCode] = useState("");
  const [departCity, setDepartCity] = useState("");
  const [departCityArabic, setDepartCityArabic] = useState("");
  const [departLabel, setDepartLabel] = useState("");
  const [arrivalLabel, setArrivalLabel] = useState("");
  const [departLabelArabic, setDepartLabelArabic] = useState("");
  const [arrivalLabelArabic, setArrivalLabelArabic] = useState("");
  const [arrivCode, setArrivCode] = useState("");
  const [arrivalCity, setArrivalCity] = useState("");
  const [arrivalCityArabic, setArrivalCityArabic] = useState("");
  const [departDate, setDepartDate] = useState(addDays(new Date(), 1));
  const [returnDate, setReturnDate] = useState(addDays(new Date(), 8));
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [preffAirlineLabel, setPreffAirlineLabel] = useState("");
  const [prefAirlineCode, setPrefAirlineCode] = useState("all_flight");
  const [isNonStop, setIsNonStop] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [travelClass, setTravelClass] = useState("Economy");
  const [deptAllAirport, setDeptAllAirport] = useState("");
  const [returnAllAirport, setReturnAllAirport] = useState("");
  const [selectedApiList, setSelectedApiList] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [recentSearchData, setRecentSearchData] = useState([])
  const [deptAirportDataForCookie, setDeptAirportDataForCookie] = useState()
  const [arrivAirportDataForCookie, setArrivAirportDataForCookie] = useState()
  const [airportDataForCookie, setAirportDataForCookie] = useState([])

  ///search params states Multicity
  const [legParams, setLegParams] = useState([]);
  const [noOfLegs, setNoOfLegs] = useState(["leg1", "leg2"]);

  useEffect(() => {
    const fetchAirlineList = async () => {
      let AirlineListResponse = await getAirlineList();
      if (AirlineListResponse) {
        let airlineListtemp = AirlineListResponse.Data.sort((a, b) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        });


        setAirlineList(airlineListtemp);
      }
    };
    fetchAirlineList();

    //setting up multicityParams

    const legNos = process.env.REACT_APP_MULTI_CITY_LEGS;

    let paramsObj = {
      departLabel: "",
      arrivLabel: "",
      departDate: addDays(new Date(), 1),
      departCode: "",
      departIsAllAirport: "",
      arrivIsAllAirport: "",
      arrivCode: "",
      departCity: "",
      departCityArabic: "",
      arrivalCity: "",
      arrivalCityArabic: "",
    };

    let legArr = [];

    for (let i = 0; i < legNos; i++) {
      legArr.push(paramsObj);
    }

    setLegParams(legArr);

    (async () => {
      let res = await cookieManagemntReq({ test: "test123" }, "FindAll")
      let RecentSearch = await cookieManagemntReq({ test: "test123" }, "autosearch")
      setRecentSearchData(res.data ? res.data : [])
    })();

  }, []);

  //props to pass down oneWay roundTrip
  const searchProps = {
    tripType,
    airlineList,
    deprtCode,
    setDeprtCode,
    arrivCode,
    setArrivCode,
    departDate,
    setDepartDate,
    returnDate,
    setReturnDate,
    adultCount,
    setAdultCount,
    childCount,
    setChildCount,
    infantCount,
    setInfantCount,
    preffAirlineLabel,
    setPreffAirlineLabel,
    prefAirlineCode,
    setPrefAirlineCode,
    isNonStop,
    setIsNonStop,
    travelClass,
    setTravelClass,
    deptAllAirport,
    setDeptAllAirport,
    returnAllAirport,
    setReturnAllAirport,
    submitHandler,
    departCity,
    setDepartCity,
    departCityArabic,
    setDepartCityArabic,
    arrivalCity,
    setArrivalCity,
    arrivalCityArabic,
    setArrivalCityArabic,
    departLabel,
    arrivalLabel,
    departLabelArabic,
    arrivalLabelArabic,
    setDepartLabel,
    setArrivalLabel,
    setDepartLabelArabic,
    setArrivalLabelArabic,
    setDepartLabelArabic,
    setArrivalLabelArabic,
    isLoading,
    setIsLoading,
    userData,
    setSelectedApiList,
    selectedApiList,
    selectedApiHandler,
    instantMarkup,
    setInstantMarkup,
    instantDiscount,
    setInstantDiscount,
    width,
    setDeptAirportDataForCookie,
    setArrivAirportDataForCookie,
    recentSearchData,
    setAirportDataForCookie,
    airportDataForCookie,
    setRecentSearchData
  };

  //props to pass down multicity

  const searchPropsMulti = {
    legParams,
    setLegParams,
    adultCount,
    setAdultCount,
    childCount,
    setChildCount,
    infantCount,
    setInfantCount,
    preffAirlineLabel,
    setPrefAirlineCode,
    setPreffAirlineLabel,
    airlineList,
    submitHandler,
    noOfLegs,
    setNoOfLegs,
    submitHandler,
    isNonStop,
    setIsNonStop,
    isLoading,
    setIsLoading,
    setSelectedApiList,
    selectedApiList,
    selectedApiHandler,
    userData,
    instantMarkup,
    setInstantMarkup,
    instantDiscount,
    setInstantDiscount,
    width,
    travelClass,
    setTravelClass,
    recentSearchData,
    setAirportDataForCookie,
    airportDataForCookie,
    setRecentSearchData,

  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  //get window size for responsive
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //Submit Handler
  async function submitHandler() {

    setFlightElkLog("Info", "Home page Search", "Search Button Clicked");

    if (tripType != 2) {
      if (deprtCode == "" || arrivCode == "") {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.mob_search_pannel_please_select_departure_and_arrival,
          buttons: {
            right: ["ok"],
          },
        });
        /* alert("Airport cannot be empty"); */
        return;
      } else if (deprtCode == arrivCode) {
        Popup.create({
          title: lang.tathkarah_title,
          content: lang.mob_search_pannel_departure_arrival_airport_cannot_be_same,
          buttons: {
            right: ["ok"],
          },
        });
        return;
      }
    } else {
      for (let i = 0; i < noOfLegs.length; i++) {
        if (legParams[i].arrivCode == "" || legParams[i].departCode == "") {
          Popup.create({
            title: lang.tathkarah_title,
            content: lang.mob_search_pannel_please_select_departure_and_arrival,
            buttons: {
              right: ["ok"],
            },
          });

          return;
        } else if (legParams[i].arrivCode == legParams[i].departCode) {
          Popup.create({
            title: lang.tathkarah_title,
            content: lang.mob_search_pannel_departure_arrival_airport_cannot_be_same,
            buttons: {
              right: ["ok"],
            },
          });

          return;
        }
      }
    }
    if (prefAirlineCode == "all_flight" && preffAirlineLabel != "") {
      Popup.create({
        title: lang.tathkarah_title,
        content: lang.pref_airline_validation,
        buttons: {
          right: ["ok"],
        },
      });
      return
    }
    setFlightElkLog("Success", "Home page Search", "Search validation passed");

    setIsLoading(true);

    let airline = prefAirlineCode ? prefAirlineCode : "all_flight";

    let searchParms = {};

    if (tripType == 0 || tripType == 1) {
      searchParms = {
        deprtCode,
        arrivCode,
        departDate,
        returnDate,
        adultCount,
        childCount,
        infantCount,
        preffAirlineLabel,
        isNonStop,
        tripType,
        travelClass,
        airline,
        deptAllAirport,
        returnAllAirport,
        departCity,
        arrivalCity,
        departCityArabic,
        arrivalCityArabic,
        departLabel,
        departLabelArabic,
        arrivalLabel,
        arrivalLabelArabic,
        selectedApiList,
        instantDiscount,
        instantMarkup,
      };
    } else {
      searchParms = {
        legParams,
        noOfLegs,
        adultCount,
        childCount,
        infantCount,
        preffAirlineLabel,
        isNonStop,
        tripType,
        travelClass,
        airline,
        selectedApiList,
        instantDiscount,
        instantMarkup,
      };
    }

    let departDateStr = moment(new Date(departDate)).format("DDMMMYYYY");
    let returnDateStr = moment(new Date(returnDate)).format("DDMMMYYYY");

    let flightSearchData = await setFlightSearchParams({ ...searchParms, url: setFlightSearchUrl(searchParms, tripType, culture) });

    let reqObj = {
      data: {
        from: deprtCode,
        to: arrivCode,
        fromDate: departDateStr,
        toDate: returnDateStr,
        adults: adultCount,
        childs: childCount,
        infants: infantCount,
        travelClass: travelClass,
        nonStop: isNonStop,
        preferedAirline: preffAirlineLabel,
        searchType: tripType,
        itenary: legParams,
        instantDiscount: instantDiscount,
        instantMarkup: instantMarkup,
        selectedApiList,
        deptAllAirport,
        returnAllAirport,
      },
      searchId: flightSearchData.data.searchId,
    };

    let searchResponse = await flightSearchRequest(reqObj, currency, currencyCountry);
    if (searchResponse) {
      setFlightElkLog("Success", "Home page Search", "flight Search request completed");


      let tempArr = recentSearchData ? recentSearchData : []
      airportDataForCookie.map((air) => {
        if (!(recentSearchData.some((rec) => rec.code == air.code))) {
          tempArr.push(air)
        }
      })


      const cookieResponse = await cookieManagemntReq(tempArr, "Add")

      dispatch(setFlightSearchData(flightSearchData));
      const currentTimeDate = new Date();
      let url = setFlightSearchUrl(flightSearchData.data, tripType, culture);
      let res = cookieManagemntReqForRecentSearch(flightSearchData, url, tripType, currentTimeDate, reqObj,searchParms)
      setRedirectUrl(url);
    }
  }

  function selectedApiHandler(apiId) {
    setSelectedApiList((prvState) => {
      if (selectedApiList.includes(apiId)) {
        let index = selectedApiList.indexOf(apiId);
        return produce(prvState, (draft) => {
          draft.splice(index, 1);
        });
      } else {
        return produce(prvState, (draft) => {
          draft.push(apiId);
        });
      }
    });
  }






  const isMobile = width <= 768;

  if (redirectUrl) {
    Navigate(redirectUrl);
  }

  return (
    <>
      <div className="flightsearchpanel">
        <Tabs selectedIndex={tripType} onSelect={(index) => setTripType(index)}>
          <TabList>
            <Tab>{lang.search_pannel_oneway}</Tab>
            <Tab>{lang.search_pannel_round_trip} </Tab>
            <Tab>{lang.search_pannel_multicity}</Tab>
          </TabList>

          <TabPanel>
            <RoundTrip {...searchProps}></RoundTrip>
          </TabPanel>
          <TabPanel>
            <RoundTrip {...searchProps}></RoundTrip>
          </TabPanel>
          <TabPanel>
            <MultiCity {...searchPropsMulti}></MultiCity>
          </TabPanel>
        </Tabs>
      </div>

      {
        !isMobile &&
        <RecentSearch />
      }



    </>

  );
}
export default FlightSearchpanel;
