import { getApiRequest } from "./getApiRequest";
import qs from "qs";

export function assembleCountrys(displayOnly = false) {
  let ApiRequest = getApiRequest();
  

  return ApiRequest({
    url: "/GetCountryList?isDisplayOnly=" + displayOnly,
    method: "get",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function getIpSettings() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/getIpSettings",
    method: "get",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
export function getCurrency() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/GetCurrency",
    method: "get",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
export function getIsMeta(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/GetIsMeta",
    method: "post",
    data: data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function UserLogin(info) {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Account/UserLogin",
    method: "post",
    data: info,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response;
      } else {
        return false;
      }
    })
    .catch(function (err) {
      console.log(err);
      return false;
    });
}
export function CheckGoogleAuthenticatorNeeded(info) {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Account/CheckGoogleAuthenticatorNeeded",
    method: "post",
    data: info,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response;
      } else {
        return false;
      }
    })
    .catch(function (err) {
      console.log(err);
      return false;
    });
}

export function GetTravellerInformations(UserInfo) {
  let ApiRequest = getApiRequest();
  // localStorage.setItem('UserInfo', JSON.stringify(UserInfo));
  return ApiRequest({
    url: "MyBooking/GetTravellerInformations",
    method: "get",
    data: UserInfo,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}
export function SetUserPermissions() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Account/GetUser",
    method: "get",
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });

  // let loginresponse = JSON.parse(localStorage.getItem('loginresponse'))
  // let token = loginresponse.access_token;
  // return axios({
  //     url: process.env.REACT_APP_JUMBO_API_URL + 'api/Account/GetUserPermissions',
  //     method: 'get',
  //     headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ` + token,
  //     }
  // }).then(function (response) {
  //     if (response.status == '200' && response.statusText == 'OK') {
  //         let UserPermissions = response.data;
  //         localStorage.setItem('GetUserPermissions', JSON.stringify(UserPermissions));
  //         //console.log(UserPermissions.Permissions);
  //         let Permissions = UserPermissions.Permissions;
  //         //console.log(Object.values(Permissions));
  //         if ((Object.values(Permissions).indexOf('DisablePaymentGatewaysB2C') > -1)) {
  //             localStorage.setItem('DisablePaymentGatewaysB2C', "true");
  //         }
  //         localStorage.setItem('UserPermissions', JSON.stringify(Permissions));
  //         if (UserPermissions.APIs.Flight) {
  //             let APISF = UserPermissions.APIs.Flight;
  //             localStorage.setItem('AdminFlightfullApi', JSON.stringify(APISF));
  //             localStorage.setItem('AdminFlightApis', Object.keys(APISF));
  //         }
  //         if (UserPermissions.APIs.Hotel) {
  //             let APISH = UserPermissions.APIs.Hotel;
  //             localStorage.setItem('AdminhotelfullApi', JSON.stringify(APISH));
  //             localStorage.setItem('AdminhotelApis', Object.keys(APISH));
  //         }
  //         if (UserPermissions.APIs.Transfers) {
  //             let APIST = UserPermissions.APIs.Transfers;
  //             localStorage.setItem('AdminTransfersfullApi', JSON.stringify(APIST));
  //             localStorage.setItem('AdminTransfersApis', Object.keys(APIST));
  //         }
  //         if (UserPermissions.APIs.Insurance) {
  //             let APISI = UserPermissions.APIs.Insurance;
  //             localStorage.setItem('AdmininsurancefullApi', JSON.stringify(APISI));
  //             localStorage.setItem('AdmininsuranceApis', Object.keys(APISI));
  //         }
  //         if (UserPermissions.APIs.Activities) {
  //             let APISA = UserPermissions.APIs.Activities;
  //             localStorage.setItem('AdminActivitiesfullApi', JSON.stringify(APISA));
  //             localStorage.setItem('AdminActivitiesApis', Object.keys(APISA));
  //         }
  //         let AgencyCurrency = UserPermissions.AgencyCurrency;
  //         let ConversionRate = (UserPermissions.ConvertionRatewithoutDecimal) ? UserPermissions.ConvertionRatewithoutDecimal : UserPermissions.ConversionRate;
  //         sessionStorage.setItem('converted_ratio', ConversionRate)
  //         sessionStorage.setItem('currency_code', AgencyCurrency)
  //         dispatch(setCurrencyCode(AgencyCurrency))
  //         dispatch(setConvertedRatio(ConversionRate))
  //         localStorage.setItem('agency_usertype', UserPermissions.UserType);
  //         if (UserPermissions.UserType == 3) {
  //             localStorage.setItem('isAgency', "true");
  //             let AgencyCreditBalance = UserPermissions.AgencyCreditBalance;
  //             let AgencyDepositBalance = UserPermissions.AgencyDepositBalance;
  //             if (UserPermissions.ActiveCredit == 1) {
  //                 localStorage.setItem('AgencyCreditBalance', AgencyCreditBalance);
  //                 if (AgencyCreditBalance > 0) {
  //                     localStorage.setItem('AgencyCreditBalance', AgencyCreditBalance);
  //                 }
  //             }
  //             if (UserPermissions.ActiveDebit == 1) {
  //                 localStorage.setItem('AgencyDepositBalance', AgencyDepositBalance);
  //                 if (AgencyDepositBalance > 0) {
  //                     localStorage.setItem('AgencyDepositBalance', AgencyDepositBalance);
  //                 }
  //             }
  //         }
  //         if (UserPermissions.ShowAllowedApi) {
  //             localStorage.setItem('ShowAllowedApi', UserPermissions.ShowAllowedApi);
  //         }
  //     } else {
  //         return false;
  //     }
  // }).catch(function (error) {
  //     console.log(error);
  //     return false;
  // })
}
export function CheckUserExists(info) {
  let ApiRequest = getApiRequest();
  let UserInfo = {
    username: info.username,
  };
  if (info.isMobile == true) {
    UserInfo = {
      username: info.username,
      EnablePhoneNumberCheck: true,
      MobileCode: info.mobileCode,
      MobileNo: info.phoneNumber,
    };
  }
  UserInfo = { UserInfo };

  return ApiRequest({
    url: "Account/CheckUserExists",
    method: "post",
    data: UserInfo,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}
export function doCreateUser(UserInfo) {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Account/DoCreateNewUser",
    method: "post",
    data: UserInfo,
    // headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ` + token.altToken.value,
    // }
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}

export function forgotPassword(data) {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Forgotpassword", 
    method: "post", 
    data: data,
  }).then((response) => {
    if (response) {
      return response.data;
    } else {
      console.log("error");
      return false;
    }
  });
}
export function setUserCurrency(currency , country, countryName ,page) {

  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "setUserCurrency",
    method: "post",
    data: { currency: currency, country, countryName },
  }).then((response) => {
    if (response) {
      return response;
    } else {
      console.log("error");
      return false;
    }
  });
}

export function checkUserExist(info) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Account/CheckUserExists",
    method: "post",
    data: info,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);

      return false;
    });

  // let token = store.getState()
  // console.log(token.tokens.value);
  // let UserInfo={
  //     "username" :info.username
  // }
  // if(info.isMobile ==true){
  //     UserInfo={
  //         "username" :info.username,
  //         "EnablePhoneNumberCheck" :true,
  //         'MobileCode':info.mobileCode,
  //         'MobileNo':info.phoneNumber,
  //     }

  // }
  // UserInfo ={UserInfo}
  // console.log(UserInfo)
  // return axios({
  //     url: process.env.REACT_APP_JUMBO_API_URL + 'api/Account/CheckUserExists',
  //     method: 'post',
  //     data: UserInfo,
  //     headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ` + token.tokens.value,
  //     }
  // }).then(function (response) {
  //     if (response.status == '200' && response.statusText == 'OK') {
  //         return response.data;
  //     } else {
  //         return false;
  //     }
  // }).catch(function (error) {
  //     console.log(error);
  //     return false;
  // })
}

// export function doCreateUser(info){
//     let token = store.getState()
//     return axios({
//         url: process.env.REACT_APP_JUMBO_API_URL +  'api/Account/DoCreateNewUser',
//         method: 'POST',
//         data : info,
//         headers :{
//             "Content-Type" : "application/json",
//             "Authorization" : `Bearer ` + token.tokens.value,
//         }
//     }).then((response)=>{
//         if(response.status == '200' && response.statusText){
//             return response.data
//         }else{
//             return false
//         }
//     }).catch((error)=>{
//         console.log(error)
//         return false
//     })
// }

export function getTravellerInfo(login) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Account/GetTravellerInformations",
    method: "POST",
    data: login,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });

  // let login = JSON.parse(sessionStorage.getItem('loginResp'))
  // console.log(login);
  //
  // if(login){
  //     let token = login.access_token
  //
  //     let UserInfo={
  //         "IsAuthorisedUser" :true,
  //         "IsAuthorized" : login.IsAuthorized,
  //         'IsLoggeddIn' : login.IsLoginUser,
  //         "Name" : login.Name,
  //         "UserName" : login.UserName,
  //     }

  //     UserInfo = {UserInfo}
  //     sessionStorage.setItem("UserInfo",JSON.stringify(UserInfo))
  //     return axios({
  //         url:process.env.REACT_APP_JUMBO_API_URL + 'api/MyBooking/GetTravellerInformations',
  //         data:UserInfo,
  //         method:"post",
  //         headers:{
  //             "Content-Type" : "application/json",
  //             "Authorization" : `Bearer ` + token

  //         }
  //     }).then((response)=>{
  //         if(response.status == '200' && response.statusText == 'OK'){
  //             sessionStorage.setItem('myprofile', JSON.stringify(response.data));
  //             return response.data
  //         }else{
  //             return false
  //         }
  //     }).catch((error)=>{
  //         console.log(error)
  //         return false
  //     })

  // }else{
  //     return false
  // }
}

export function userLogin(info) {
  let api = getApiRequest();
  let obj;
  if (info.EnablePhoneNumberCheck) {
    obj = {
      username:
        "xJLgZoZmurjtrLiy5piKYqunn6z7i6Sjop3yaFJkmK3EcHhOoZO8eXR4eZKoZq1msmigYWfC4piYYpeq6tv7VpuY7uT9p0al1uG0bmmLi5+trKe1st6ewQ==",
      password: "qtTprbo=",
      grand_type: "password",
      type: "login",
      user: info.email,
      pass: info.password,
      EnablePhoneNumberCheck: info.EnablePhoneNumberCheck,
      MobileCode: info.MobileCode,
      MobileNo: info.MobileNo,
    };
  } else {
    obj = {
      username:
        "xJLgZoZmurjtrLiy5piKYqunn6z7i6Sjop3yaFJkmK3EcHhOoZO8eXR4eZKoZq1msmigYWfC4piYYpeq6tv7VpuY7uT9p0al1uG0bmmLi5+trKe1st6ewQ==",
      password: "qtTprbo=",
      grant_type: "password",
      type: "login",
      user: info.Email,
      pass: info.Password,
    };
  }
  return api({
    url: "Account/UserLogin",
    method: "post",
    data: qs.stringify(obj),
  })
    .then((resp) => {
      if (resp.status == "200") {
        return resp.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
export function facebookLoginApiCall(userDetails) { 
  let api = getApiRequest();

  return api({
    url: "Account/FacebookLogin",
    method: "post",
    data: qs.stringify(userDetails),
  })
    .then((resp) => {
      if (resp.status == "200") {
        return resp.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
export function GoogleLoginAPICall(userDetails) {
  let api = getApiRequest();

  return api({
    url: "Account/GoogleLogin",
    method: "post",
    data: userDetails,
  })
    .then((resp) => {
      if (resp.status == "200") {
        return resp.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function GetLanguage() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/language",
    method: "get",
  })
    .then(function (response) {
      console.log("GET-LANGUAGE-RESPONS", response);
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}

export function GetSearchId() {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function loginDetailsFetch() {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Account/FetchLoginDetails",
    method: "get",
  }) 
    .then(function (response) {
      if (response.status == "200") {
        return response;
      } else {
        return false;
      }
    })
    .catch(function (err) {
      console.log(err);
      return false;
    });
}

export function loginDetailsRemove() {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Account/RemoveLoginDetails",
    method: "get",
  })
    .then(function (response) {
      console.log(response);
      if (response.status == "200") {
        return response;
      } else {
        return false;
      }
    })
    .catch(function (err) {
      console.log(err);
      return false;
    });
}

export function getCountryList(data) {
  let countryList = [];
  for (let i = 0; i < data.length; i++) {
    countryList.push(data[i].Name);
  }
  console.log(countryList);
  return countryList.sort();
}

export function getMobCode(data) {
  let mobileList = [];
  let tempList = [];
  for (let i = 0; i < data.length; i++) {
    let codeStr = data[i].MobileCode.split("+")[1];

    if (tempList.includes(codeStr) === false) {
      tempList.push(codeStr);
    }
  }

  tempList.forEach((item) => mobileList.push("+" + item));
  return mobileList.sort();
}

export function getMobCountryCode(data) {
  let mobileList = [];
  let tempList = [];

  let priority = [
    {
      Code: "KW",
      Name: {
          en: "KUWAIT",
          ar: "الكويت"
      },
      MobileCode: "+965",
      Currency: "KWD"
  },
  {
    Code: "SA",
    Name: {
        en: "SAUDI ARABIA",
        ar: "المملكة العربية السعودية"
    },
    MobileCode: "+966",
    Currency: "SAR"
},
{
    Code: "AE",
    Name: {
        en: "UNITED ARAB EMIRATES",
        ar: "الإمارات العربية المتحدة"
    },
    MobileCode: "+971",
    Currency: "AED"
},
{
    Code: "BH",
    Name: {
        en: "BAHRAIN",
        ar: "البحرين"
    },
    MobileCode: "+973",
    Currency: "BHD"
},
{
    Code: "OM",
    Name: {
        en: "OMAN",
        ar: "سلطنة عمان"
    },
    MobileCode: "+968",
    Currency: "OMR"
},
{
    Code: "EG",
    Name: {
        en: "EGYPT",
        ar: "مصر"
    },
    MobileCode: "+20",
    Currency: "KWD"
},
{
    Code: "IN",
    Name: {
        en: "INDIA",
        ar: "الهند"
    },
    MobileCode: "+91",
    Currency: "INR"
},
{
    Code: "PH",
    Name: {
        en: "PHILIPPINES",
        ar: "الفلبين"
    },
    MobileCode: "+63",
    Currency: "KWD"
},
  ];

  for (let i = 0; i < data.length; i++) {
    let countryName = data[i].Name.en;

    if (
      countryName !==
      ("BAHRAIN" ||
        "PHILIPPINES" ||
        "INDIA" ||
        "EGYPT" ||
        "UNITED ARAB EMIRATES" ||
        "SAUDI ARABIA" ||
        "KUWAIT")
    ) {
      priority.push(data[i]);
    }
  }
  return priority;
}
export function setCultureSession(culture) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/setCulture",
    method: "POST",
    data: culture,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}
export function getSessionExpiry(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "token/getTokenExpiry",
    method: "GET",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
export function setRefreshToken(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "token/setRefreshToken",
    method: "GET",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
export function getCulture() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/getCulture",
    method: "get",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function setResourceKey() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/ResourceKey",
    method: "get",
  })
    .then((response) => {
      if (response.status == "200") {
        return response;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function getSeoData() {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/SeoData?browser=true", 
    method: "get",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function getSeoDataById(code) { 
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/get_airline_details_by_airline_code/" + code,
    method: "get",
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function ForgotPassword(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Account/ForgotPassword",
    method: "POST",
    data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function getCMSData(pagename) {
  let ApiRequest = getApiRequest();
  // localStorage.setItem('UserInfo', JSON.stringify(UserInfo));
  return ApiRequest({
    url: `CMS/getCMSDataByPageName?PageName=${pagename}`,
    method: "get",
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data.Data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}

export function submitContactusFormData(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "CMS/contactUsFormSubmit",
    method: "POST",
    data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
export function getSpecialOffers(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Offers/getSpecialOffers",
    method: "POST",
    data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data.Data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function getSpecialOffersDetails(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Offers/getSpecialOfferDetails",
    method: "POST",
    data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data.Data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
export function submitOfferEnquiry(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Offers/submitOfferEnquiry",
    method: "POST",
    data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data.data.Data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}
export async function ticketDownload(data, loaderFn = null,type="sports") {

    fetch(process.env.REACT_APP_API_URL + "api/Ticket/Download", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        searchId: data.searchId,
        html: data.html,
      }),
    })
      .then((response) => {
        
        response.blob().then((blob) => download(blob, "voucher"));
  
        function download(blob, filename) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
  
        
  
        if (loaderFn != null) {
          loaderFn(false);
        }
      })
  
      .catch((err) => {
        console.log(err.message);
        return false;
      });
  
  
 
}
export async function ticketDownloadServer(culture, loaderFn = null,type,transId) {

    fetch(process.env.REACT_APP_API_URL + culture + "/" + (type == "sports"  ? "SportsServerTicket" : "FlightServerTicket") + "?TransactionId=" + transId + "&download=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        
        response.blob().then((blob) => download(blob, "voucher"));
  
        function download(blob, filename) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
  
        
  
        if (loaderFn != null) {
          loaderFn(false);
        }
      })
  
      .catch((err) => {
        console.log(err.message);
        return false;
      });
  
  
 
}

export function setSubcribe(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "setSubscribe",
    method: "post",
    data: data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function sendMail(data) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "Common/SendMail",
    method: "post",
    data: data,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function getUserPermissionData() {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Account/getUserData",
    method: "post",
  })
    .then(function (response) {
      if (response.status == "200") {
        return response;
      } else {
        return false;
      }
    })
    .catch(function (err) {
      console.log(err);
      return false;
    });
}

export function redirectToAdmin() {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "redirectToAdminPanel",
    method: "get",
  }).then((response) => {
    if (response) {
      return response;
    } else {
      console.log("error");
      return false;
    }
  });
}
export function getUserWalletDetails() { 
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Account/getUserWalletDetails",
    method: "get",
  }).then((response) => {
    if (response) {
      return response;
    } else {
      console.log("error");
      return false;
    }
  });
}
export function fetchSelectedAncillaries(searchId) {
  let ApiRequest = getApiRequest();
  return ApiRequest({
    url: "Flight/fetchSelectedAncillaries",
    method: "post",
    data: { searchId },
  }).then((response) => {
    if (response) {
      return response;
    } else {
      console.log("error");
      return false;
    }
  });
}

export function firstLoadUrlCheck(url) {
  let ApiRequest = getApiRequest();

  return ApiRequest({
    url: "/firstLoadCheck",
    method: "post",
    data: url,
  })
    .then((response) => {
      if (response.status == "200") {
        return response.data;
      } else {
        return false;
      }
    })
    .catch((er) => {
      console.log(er);
    });
}

export function getDirectPaymentInfo(info) {
  let ApiRequest = getApiRequest();
  
  return ApiRequest({
    url: "Common/getDirectPaymentInfo",
    method: "post",
    data: info,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data.Data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}

export function directPaymentRequest(info) {
  let ApiRequest = getApiRequest();


  return ApiRequest({
    url: "Common/directPaymentRequest",
    method: "post",
    data: info,
  })
    .then(function (response) {
      if (response.status == "200") {
        return response.data.data.Data;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
}
