export function getPaxTitle(key) {
  switch (key) {
    case 0:
      return ["Mr", "Mrs", "Ms", "Miss"];
      break;
    case 1:
      return ["Mstr", "Miss"];
      break;
    case 2:
      return ["Mstr", "Miss"];
      break;
    default:
      break;
  }
}

export function disableBodyScroll(value) {
  if (value) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "scroll";
  }
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
}


export function checkAgeCategory(dateOfBirth, lang) {


  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let ageInYears = today.getFullYear() - birthDate.getFullYear();
  let monthDiff = today.getMonth() - birthDate.getMonth();


  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    ageInYears--;
  }

  if (ageInYears >= 12) {
    return lang.Adult;
  } else if (ageInYears >= 2) {
    return lang.Child;
  } else {
    return lang.Infant;
  }
}

/* export function updateBaggageText(inputText) {

  let checkInBaggagePattern = /(Adult|Child|Infant)\s*:\s*([0-9]+)\s*kg\s*\(Check in baggage\)/gi;
  let cabinBaggagePattern = /(Adult|Child|Infant)\s*:\s*([0-9]+)\s*kg\s*\(Cabin Baggage\)/gi;
  let stringSplit = inputText.split(';')
  let checkInBaggage = stringSplit[0]
  let cabinBaggage = stringSplit[1]


  let adultCheckIn, childCheckIn, infantCheckIn, adultCabin, childCabin, infantCabin;

  let match;
  while ((match = checkInBaggagePattern.exec(checkInBaggage)) !== null) {
    let type = match[0].split(':')[0].trim();
    let allowance = match[1] + " PC (" + match[2] + ")";
    if (type === 'Adult') {
      if (!adultCheckIn) adultCheckIn = allowance;
    } else if (type === 'Child') {
      if (!childCheckIn) childCheckIn = allowance;
    } else if (type === 'Infant') {
      if (!infantCheckIn) infantCheckIn = allowance;
    }
  }

  // Extract cabin baggage information
  while ((match = cabinBaggagePattern.exec(cabinBaggage)) !== null) {
    let type = match[0].split(':')[0].trim();
    let allowance = match[1] + " PC (" + match[2] + ")";
    if (type === 'Adult') {
      if (!adultCabin) adultCabin = allowance;
    } else if (type === 'Child') {
      if (!childCabin) childCabin = allowance;
    } else if (type === 'Infant') {
      if (!infantCabin) infantCabin = allowance;
    }
  }


  let updatedText = "";


  if (adultCheckIn || childCheckIn || infantCheckIn) {

    updatedText += "Check-in Baggage: ";
    if (adultCheckIn) updatedText += " Adult: " + adultCheckIn;
    if (childCheckIn) updatedText += ", Child: " + childCheckIn;
    if (infantCheckIn) updatedText += ", Infant: " + infantCheckIn;
    // updatedText = updatedText.slice(0, -2); // Remove trailing comma and space
    updatedText += "; ";
  }


  if (adultCabin || childCabin || infantCabin) {
    updatedText += "Cabin Baggage: ";
    if (adultCabin) updatedText += "Adult: " + adultCabin;
    if (childCabin) updatedText += ", Child: " + childCabin + "";
    if (infantCabin) updatedText += ", Infant: " + infantCabin;
  }
  return updatedText;
}
 */

export function updateBaggageText(inputText) {
  const parts = inputText.split(';').map(part => part.trim()).filter(part => part);

  // Initialize variables for storing baggage details
  let checkInBaggage = '';
  let cabinBaggage = '';

  function isValidBaggageDetail(detail) {
    if (!detail) return false;
    const [adult, child] = detail.split(',').map(item => item.trim());
    return (
      (adult && adult.includes('Adult :') && adult.split(':')[1].trim()) ||
      (child && child.includes('Child :') && child.split(':')[1].trim())
    );
  }

  parts.forEach(part => {
    if (part.includes('Check in baggage')) {
      const cleanPart = part.replace('(Check in baggage)', '').trim();
      if (isValidBaggageDetail(cleanPart)) {
        checkInBaggage = cleanPart;
      }
    }
    if (part.includes('Cabin Baggage')) {
      const cleanPart = part.replace('(Cabin Baggage)', '').trim();
      if (isValidBaggageDetail(cleanPart)) {
        cabinBaggage = cleanPart;
      }
    }
  });


  let output = '';

  if (checkInBaggage) {
    output += `Check in baggage: ${checkInBaggage}`;
  }
  if(checkInBaggage &&  cabinBaggage){
    output += `; `;
  }
  if (cabinBaggage) {
    output += `Cabin Baggage: ${cabinBaggage}; `;
  }

  return output.trim().replace(/;$/, '');
}

